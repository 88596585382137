import React, { useState } from 'react';
import ScrollToTop from '../../../components/whatsapp connect';
import { backend } from "../../../../path";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
function PaymentSuccess() {

  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [password, setPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [otpError, setOTPError] = useState('');
  const [activeState, setActiveState] = useState(0);
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  const navigate = useNavigate()

  const handleCurrentChange = (event) => {
    setPassword(event.target.value);
  };
  const handleNewChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleSubmit = () => {

    if (!password.trim()) {
      toast.error("Old Password Cannot be Null", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }); return;
    }
    if (!newpassword.trim()) {
      toast.error("New Password Required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }); return;
    }
    fetch(backend + "updateuserpassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({ password: password, newpassword: newpassword })
    })
      .then((response) => {
        response.json().then((data) => {
        });
        if (response.ok) {
          setActiveState(1)
        }
        if (!response.ok) {
          console.error(
            "Failed to Login:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 401) {
            toast.error("Incorrect Password, Try Again", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Invalid Email", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
        toast.error("Invalid Email", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <ToastContainer />
      <main className="main" id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  Home
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Cart </div>
              </div>
            </div>
          </div>
        </div>
        <section className="pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 m-auto">
                <div className="login_wrap widget-taber-content background-white border-radius-10">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1 mb-20">
                      <a className="btn btn-lg mb-2 w-100"
                        style={{
                          fontSize: '24px',
                          backdropFilter: 'inherit',
                          backgroundBlendMode: 'color-burn',
                          backgroundColor: '#ADD899',
                          fontWeight: 'bolder',
                          color: '#fff',
                          padding: '20px',
                          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                        }}
                      >
                        Your Order Has Been Successfully Placed
                        <div className='d-flex justify-content-center mb-20 mt-20'>
                          <svg
                            height="200px"
                            width="200px"
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 508 508"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path
                                style={{ fill: '#13A289' }}
                                d="M373.4,188l-11.6-11.6c-4.4-4.4-12-4.4-16.4,0L231.8,290l-69.6-69.6c-4.4-4.4-12-4.4-16.4,0
              L134.2,232c-4.4,4.4-4.4,12,0,16.4l89.2,89.2c4.4,4.4,12,4.4,16.4,0l0,0l0,0l10.4-10.4l0.8-0.8l121.6-121.6
              C377.8,200,377.8,192.8,373.4,188z"
                              />
                              <path
                                style={{ fill: '#13A289' }}
                                d="M497,304.8c-4.4-4.4-8.8-6.8-15.6-6.8H342.6c-13.2,0-24,10.4-24,23.6v23.6c0,13.2,10.8,24.8,24,24.8
              h49.2c-33.6,40-83.2,63.2-136.4,63.2C157,433.2,77,352.8,77,254.4S157,75.6,255.4,75.6c73.6,0,140.8,46,166.8,115.2
              c7.2,18.4,27.6,27.6,46,20.8c18.4-7.2,27.6-27.6,20.8-46C452.2,69.2,358.6,4.4,255.4,4.4c-137.6,0-250,112-250,250
              s112.4,250,250,250c68.4,0,131.2-28,179.2-75.6v32c0,13.2,10.4,25.6,23.6,25.6h23.6c13.2,0,21.2-12.8,21.2-25.6V347.2v-24.8
              C502.6,315.2,501.4,309.2,497,304.8z"
                              />
                            </g>
                            <path
                              style={{ fill: '#54B265' }}
                              d="M255.4,24c54.4,0,106.4,19.6,146.8,53.2c0.4,0.4,1.2,0.8,1.6,1.6c0,0,20.8,20.4,28.4,28.4
            c17.2,18,28.8,41.2,38,65.2c1.6,4,1.2,8.4-0.4,12c-1.6,4-4.8,6.8-8.8,8.4c-2,0.8-3.6,1.2-5.6,1.2c-6.4,0-12.4-4-14.8-10.4
            C411.4,107.2,337,55.6,255,55.6C145.8,55.6,56.6,144.4,56.6,254c0,109.2,88.8,198.4,198.4,198.4c58.4,0,113.6-25.6,151.6-70.4
            c4.8-6,6-14,2.8-21.2s-10.4-11.6-18-11.6h-49.2c-2,0-4-2-4-4v-23.6c0-1.2,0.8-2,1.2-2.8c0.4-0.4,1.6-1.2,2.8-1.2H481
            c1.2,0,2.8,0.4,3.6,1.2c0.4,0.4,1.6,1.2,1.6,2.8v24.8V460c0,2-2.8,5.6-5.2,5.6h-23.6c-2,0-3.6-3.6-3.6-5.6v-32
            c0-8-5.2-15.2-12.8-18.4c-2.4-0.8-5.2-1.6-7.6-1.6c-5.2,0-10.4,2-14.4,6c-43.2,44.4-103.2,69.6-164.8,69.6
            c-126.8,0-230-103.2-230-230C25.4,127.2,128.6,24,255.4,24"
                            />
                            <path
                              d="M231.8,345.2c-4.4,0-8-1.6-11.2-4.4l-89.2-89.2c-6-6-6-16,0-22.4l11.6-11.6c6-6,16.4-6,22.4,0l66.8,66.8L342.6,174
            c6-6,16.4-6,22.4,0l11.6,11.6l0,0c6,6,6,16,0,22.4L243.4,341.2C240.2,343.6,236.2,345.2,231.8,345.2L231.8,345.2z M153.8,221.2
            c-2,0-4,0.8-5.6,2.4l-11.6,11.6c-3.2,3.2-3.2,8,0,10.8l89.2,89.2c1.6,1.6,3.2,2.4,5.6,2.4l0,0c2,0,4-0.8,5.6-2.4L370.6,202
            c3.2-3.2,2.8-8,0-10.8L359,179.6c-2.8-2.8-8-2.8-10.8,0L234.6,293.2c-1.6,1.6-4,1.6-5.6,0l-69.6-69.6
            C157.8,222,155.8,221.2,153.8,221.2z"
                            />
                            <path
                              d="M255.4,508c-140,0-254-114-254-254s114-254,254-254c104.8,0,200,66,237.2,163.6c7.6,20.4-2.4,43.6-23.2,51.2
            c-10,3.6-20.8,3.6-30.4-0.8c-9.6-4.4-17.2-12-20.8-22C392.6,124.8,327,79.6,255,79.6c-96.4,0-174.4,78.4-174.4,175.2
            c0,96.8,78.4,175.6,174.4,175.6c50,0,96-19.2,127.6-53.2h-40c-15.6,0-28.4-12.8-28.4-28.4v-23.6c0-15.6,12.8-28.4,28.4-28.4h138.8
            c11.6,0,19.6,2.8,27.6,10.8c7.6,8,10.8,16.8,10.8,27.6v88.4c0,18.4-15.6,33.6-33.6,33.6h-23.6c-18.4,0-33.6-15.6-33.6-33.6v-27.2
            c-47.6,49.2-112.4,77.6-180,77.6L255.4,508z M255.4,8.4c-134.8,0-244,109.2-244,244s109.2,244,244,244c66.8,0,129.6-27.2,176.8-75.6
            c2.8-2.8,6.4-4,10.4-4c4.4,0,8.4,2,11.2,5.2v36.8c0,13.2,10.8,23.6,23.6,23.6h23.6c13.2,0,23.6-10.8,23.6-23.6v-88.4
            c0-8.8-2.4-16-7.6-21.2c-5.2-5.6-11.6-7.6-21.2-7.6H342.6c-10,0-18,8-18,18v23.6c0,10,8,18,18,18h66.8c2.8,0,5.2,1.6,6.4,4
            c1.2,2.4,0.8,5.2-0.8,7.2c-34.4,42-86,67.2-141.2,67.2C152.6,497.6,43,388.4,43,254S152.6,10.4,255.4,10.4
            c76.4,0,146.4,36.8,191.2,98c6.4,9.2,19.2,11.6,28.8,6.4c9.2-3.6,13.6-14,10-23.6C440.2,39.2,351.4,4.4,255.4,4.4L255.4,8.4z"
                            />
                          </svg>
                        </div>
                        Thanks for choosing "keralaspecial.in"
                        <div className='mt-20 mb-20 d-block d-md-flex justify-content-center gap-4'>
                          <a href='/' >

                            <button className="btn" type="submit"

                              style={{
                                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                              }}
                            >
                              Continue Shopping
                            </button></a>
                          <a href='/myorders'>

                            <button className="btn d-none d-md-block" type="submit"
                              style={{
                                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                              }}
                            >
                              Go To My Orders
                            </button>
                            <button className="btn d-block d-md-none my-3 mx-auto" type="submit"
                              style={{
                                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                              }}
                            >Go To My Orders</button>
                          </a>
                        </div>


                      </a>
                    </div>

                    <div className="pr_switch_wrap hover-up d-flex justify-content-center">
                      <div className="product-attributes">
                        <div
                          className="text-swatches-wrapper attribute-swatches-wrapper attribute-swatches-wrapper form-group product__attribute product__color"
                          data-type="text"
                        >
                          <div className="attribute-values">
                            <ul className="text-swatch attribute-swatch color-swatch">
                              <li
                                data-slug="1kg"
                                data-id="1"
                                className="attribute-swatch-item"
                              >
                                <div>
                                  <label>
                                    <input
                                      className="product-filter-item"
                                      type="radio"
                                      checked
                                    />
                                    <span>NB : If you wish to cancel your order, please do so within 12 hours of placing it.
                                      Orders can be cancelled within 12 hours of placement.
                                      Cancellations are only allowed within 12 hours of placing your order </span>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <ScrollToTop />
          </div>
        </section>
      </main>
    </>
  );
}

export default PaymentSuccess;
