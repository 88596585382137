import { useEffect } from "react";
import ScrollToTop from "../../../components/whatsapp connect";
import styled from "styled-components";

function ReturnRefund() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main class="main" id="main-section">
        <ScrollToTop />
        <div class="page-header breadcrumb-wrap">
          <div class="container">
            <div class="breadcrumb">
              <div class="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div class="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Return & Refund </div>
              </div>
            </div>
          </div>
        </div>
        <MainContainer className="newsletter py-5 wow animate__animated animate__fadeIn">
          <div className="wrapper">
            <div className="">
              <div className="">
                <div className="">
                  <div className="newsletter-content">
                    <h2 className="mb-20">Return and refund policy</h2>
                    <p className="mb-15">
                      To ensure a smooth return process, please contact our
                      customer service team by phone at (+91) - 7356629493 or by
                      email at info@keralaspecial.in. We will be happy to guide
                      you through the return process and confirm eligibility for
                      specific products or seasonal items.
                    </p>
                    <p className="mb-20">Here's a quick overview:</p>

                    <ul className="mb-20">
                      <li>
                        ◉ Eligible Items: Most handicrafts, handlooms, spices,
                        and Ayurvedic products purchased within 15 days are
                        eligible for return
                      </li>
                      <li>
                        ◉ Condition: Items must be returned in their original
                        packaging, unopened, unused, and with tags attached (if
                        applicable).
                      </li>
                      <li>
                        ◉ Non-Returnable Items: Perishable goods, downloadable
                        products, and intimate/sanitary items cannot be
                        returned.
                      </li>
                    </ul>

                    <h2 className="mb-20">Refunds and Exchanges</h2>

                    <p className="mb-20">
                      Once we receive and inspect your returned item, we'll
                      notify you of the approval or rejection of your refund. If
                      approved, you'll receive a full refund to your original
                      payment method within 10 days of receipt of the product.
                    </p>
                    <p className="mb-20">
                      Exchanges: We're happy to exchange your item for a
                      different size or color, subject to availability. Just
                      contact customer service to initiate an exchange.{" "}
                      <p className="mb-20">
                        Buyer is any person who is a user of keralaspecial.in
                        and who buys a merchandise from the Site by accepting
                        the Terms and Conditions, and the Policies of the
                        Concern.
                      </p>
                    </p>
                    <h2 className="mb-20">Need Help ?</h2>

                    <p className="mb-20">
                      Our friendly customer service team is here to answer any
                      questions you may have about returns. Don't hesitate to
                      reach out by phone at (91) - 7356629493 or by email at
                      info@keralaspecial.in.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainContainer>
      </main>
    </>
  );
}
export default ReturnRefund;
const MainContainer = styled.div`
  h2 {
    font-weight: 600;
  }
  li {
    list-style-type: none;
    padding: 10px 0;
    font-size: 16px;
  }
`;
