import styled, { keyframes } from "styled-components";
const ScrollToTop = () => {
  return (
    <Container>
      <a
        href="https://wa.me/918891196910"
        target="_blank"
        rel="noopener noreferrer"
        title="Chat with us on Whatsup"
      >
        <ImgContainer>
          <img src="/storage/general/whatsapp.png" />
        </ImgContainer>
      </a>
    </Container>
  );
};
export default ScrollToTop;
const moveUpDown = keyframes`
0%{
    transform: translateY(0);
}
50%{
    transform: translateY(-20px);
}
100%{
    transform: translateY(0);
    }
`;
const Container = styled.div`
  position: fixed;
  bottom: 70px;
  right: 70px;
  /* z-index: 99; */
`;
const ImgContainer = styled.div`
  height: 45px;
  width: 45px;
  animation: ${moveUpDown} 3s ease infinite;
`;
