import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { backend, producturl } from "../../../../path";
import { useNavigate } from "react-router-dom";
import Loader from "../../../layout/header/loader";
import { ToastContainer, toast } from "react-toastify";
import LoaderMain from "../../../layout/header/loader_sub";
import ScrollToTop from "../../../components/whatsapp connect";
import { Rating } from "@mui/material";
import styled from "styled-components";
function WishList(props) {
  const navigate = useNavigate();
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;

  const handleAddToWishList = async (id) => {
    try {
      const response = await fetch(
        backend + `deletewishlistbyiduserside/${id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authenticated?.access_token}`,
          },
        }
      );

      if (response.status === 200) {
        fetchData();
        toast.success("Item removed from wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setWishChange(!props.wishChange);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [currentPage]);

  const fetchData = () => {
    setLoading(true);
    setError(null);

    const startIndex = (currentPage - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;

    fetch(backend + `getwishlistbyuseriduserside`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setFullData(data);
        // Assuming data is an array of products
        const products = data.slice(startIndex, endIndex);
        setData(products);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilter = () => {
    setIsFilter((prevIsFilter) => !prevIsFilter);
  };
  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
  };
  const [priceRange, setPriceRange] = useState([0, 10000]);

  const handlePriceChange = (value) => {
    setPriceRange(value);
  };

  const handleAddCart = async (id) => {
    if (authenticated === null) {
      navigate(`/login`);
      return;
    }
    const formData = {
      status: 1,
      quantity: 1,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtocartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setCartChange(!props.cartChange);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <main>
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Wishlist </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <Header>
            <H2>Your Wishlist</H2>
          </Header>
          <div className="mt-4">
            <div className="products-listing position-relative">
              <div className="list-content-loading">
                <div className="half-circle-spinner">
                  <div className="circle circle-1"></div>
                  <div className="circle circle-2"></div>
                </div>
              </div>
              <div className="shop-product-filter">
                <div className="total-product">
                  <p>
                    You have{" "}
                    <strong className="text-brand">{fullData?.length}</strong>{" "}
                    items in your favourites!
                  </p>
                </div>
              </div>

              <div className="row product-grid">
                {loading && (
                  <div>
                    {/* Loading...  */}
                    <LoaderMain />
                  </div>
                )}

                {!loading &&
                  data.map((item, i) => (
                    <div
                      key={i}
                      className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 col-sm-6"
                    >
                      <div
                        className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                        data-wow-delay="0.1s"
                      >
                        <div className="product-img-action-wrap">
                          <div className="product-img product-img-zoom">
                            <a
                              onClick={() =>
                                handleProductRoute(item.product_slug)
                              }
                            >
                              <img
                                className=""
                                style={{ height: "320px" }}
                                src={
                                  item.thumbnail2
                                    ? producturl + item.thumbnail2
                                    : producturl + item.thumbnail
                                }
                                // alt="Nestle Original Coffee-Mate Coffee Creamer"
                              />
                            </a>
                          </div>
                          <div className="product-action-1">
                            <a
                              aria-label="Quick View"
                              onClick={() =>
                                handleProductRoute(item.product_slug)
                              }
                              className="action-btn hover-up js-quick-view-button"
                            >
                              <i className="fi-rs-eye"></i>
                            </a>
                            <a
                              aria-label="Remove From Wishlist"
                              onClick={() =>
                                handleAddToWishList(item.wishlist_id)
                              }
                              className="action-btn hover-up js-add-to-wishlist-button"
                            >
                              <i className="fi-rs-cross-circle"></i>
                            </a>
                            {/* <a
                          aria-label="Add To Compare"
                          onClick={() => handleProductRoute(item.product_id)}
                          className="action-btn hover-up js-add-to-compare-button"
                        >
                          <i className="fi-rs-shuffle"></i>
                        </a> */}
                          </div>
                          <div className="product-badges product-badges-position product-badges-mrg"></div>
                        </div>
                        <ProductBottom>
                          <div className="product-category">
                            <a
                              onClick={() =>
                                handleProductRoute(item.product_slug)
                              }
                            >
                              {item?.category_name}
                            </a>
                          </div>
                          <h2 className="text-truncate">
                            <a
                              onClick={() =>
                                handleProductRoute(item.product_slug)
                              }
                              title="Nestle Original Coffee-Mate Coffee Creamer"
                            >
                              {item?.product_name}
                            </a>
                          </h2>
                          <div className="product-rate-cover">
                            <div className=" d-inline-block">
                              <Rating
                                name="read-only"
                                value={item?.average_stars}
                                readOnly
                                size="small"
                              />
                            </div>
                            <span className="font-small ml-5 text-muted">
                              ({item?.average_stars ?? "Not Yet Rated"})
                            </span>
                          </div>
                          <div className="text-truncate">
                            <span className="font-small text-muted">
                              Sold By{" "}
                              <a
                                onClick={() =>
                                  handleProductRoute(item.product_slug)
                                }
                              >
                                {item?.manufacturer_name ?? "KeralaSpecial"}
                              </a>
                            </span>
                          </div>
                          <div className="product-card-bottom">
                            <div className="product-price">
                              <span>₹ {item.mrp}</span>
                            </div>
                            <div className="add-cart">
                              <a
                                aria-label="Add To Cart"
                                className="action-btn add-to-cart-button add"
                                data-id="11"
                                onClick={() => handleAddCart(item.product_id)}
                              >
                                <i className="fi-rs-shopping-cart mr-5"></i>
                                <span className="d-inline-block">Add</span>
                              </a>
                            </div>
                          </div>
                        </ProductBottom>
                      </div>
                    </div>
                  ))}

                {/* Pagination controls */}
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-fill-out btn-secondary btn-block hover-up"
                    onClick={() =>
                      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                    }
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span style={{ margin: "0 10px" }}>Page {currentPage}</span>
                  <button
                    type="button"
                    className="btn btn-fill-out btn-block hover-up"
                    onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                    disabled={data.length < productsPerPage} // Assuming data.length indicates total number of products fetched
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <ScrollToTop />
          </div>
        </div>
      </main>
    </>
  );
}
export default WishList;
const Header = styled.header``;
const H2 = styled.h2`
  font-weight: 500;
  padding: 20px 0;

  @media screen and (max-width: 640px) {
    font-size: 25px;
  }
`;
const ProductBottom = styled.div`
  padding: 20px 10px;
  background-color: #f8f8f8;
  a {
    color: #000;
  }
  h2 {
    text-wrap: auto;  
    margin: 10px 0;
    min-height: 50px;
    a {
      font-weight: 500;
      font-size: 20px;
    }
  }
`;