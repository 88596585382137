import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardSidebarMain from "../dashsidebar";
import "../../../App.css";
import HeaderDashMain from "../../layout/header/dash header";

function DashboardMain() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            {/* <div className="dash-main"> */}
            <DashboardSidebarMain />
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default DashboardMain;
