import { useEffect, useState } from "react";
import { backend, producturl } from "../../../../path";
import useRazorpay from "react-razorpay";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Button, Collapse } from "react-bootstrap";
import ScrollToTop from "../../../components/whatsapp connect";
import LoaderMain from "../../../layout/header/loader_sub";
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MdOutlineLocalOffer } from "react-icons/md";
import { HiOutlinePlus } from "react-icons/hi2";
import { LuMinus } from "react-icons/lu";

function Cart(props) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [couponSuccess, setCouponSuccess] = useState("");
  const [Razorpay] = useRazorpay();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    country: "102",
  });
  const [address, setAddress] = useState([]);
  const [delAd, setDelAd] = useState(null);
  const [newAdd, setNewAdd] = useState({
    country: "102",
  });
  const [count, setCountry] = useState([]);
  const [open, setOpen] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [newDelivery, setNewDelivery] = useState(false);
  const [coupon, setCoupon] = useState(0);
  const [couponId, setCouponId] = useState("");
  const navigate = useNavigate();
  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    // window.location.reload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const fetchData = () => {
    setLoading(true);

    fetch(backend + `getcartbyuseriduserside`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCountryData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcountry", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCountry(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchAddress = () => {
    setLoading(true);

    fetch(backend + "getaddress", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Modify the object to include is_primary: 0
        // const updatedData = {
        //   ...data,
        //   is_primary: 0
        // };
        // Set the form data with the updated object
        setAddress(data);
        setFormData(data[0]);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const calculateTotal = () => {
    let total = 0;
    data.forEach((item) => {
      total += item?.total;
    });
    return total;
  };
  const calculateTotalMRP = () => {
    let total = 0;
    data.forEach((item) => {
      total += item?.total;
    });
    return total;
  };
  const calculateTotalSHIPPING = () => {
    let total = 0;
    data.forEach((item) => {
      total += item?.shipping_charge;
    });
    return total;
  };

  const calculateTotalMRPCoupon = () => {
    let total = 0;
    data.forEach((item) => {
      total += item?.total;
    });
    return total - couponSuccess?.amount;
  };

  useEffect(() => {
    fetchData();
    fetchCountryData();
    fetchAddress();
  }, []);

  // const handleQuantityChange = async (id, qty) => {
  //   console.log("Handling quantity change for ID:", id);
  //   console.log("New quantity:", qty);

  // };

  const handleDecrement = async (id, qty) => {
    if (qty === 1) {
      return;
    }
    const formData = {
      quantity: qty - 1,
      cart_id: id,
    };

    try {
      const response = await fetch(backend + "updatecartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        fetchData();
      } else if (response.status === 409) {
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleIncrement = async (id, qty) => {
    const formData = {
      quantity: qty + 1,
      cart_id: id,
    };

    try {
      const response = await fetch(backend + "updatecartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        fetchData();
      } else if (response.status === 409) {
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteCart = async (id) => {
    try {
      const response = await fetch(backend + `deletecartbyiduserside/${id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(),
      });

      if (response.status === 200) {
        fetchData();
        props.setCartChange(!props.cartChange);
      } else if (response.status === 409) {
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //payment

  const handlePayment = async (id, total, details) => {
    const options = {
      key: "rzp_live_VmDGOVv2NGo2Yi",
      amount: total * 100,
      currency: "INR",
      name: "Kerala Special",
      description: "Order Payment",
      image: "https://keralaspecial.in/storage/general/ks-logo32.png",
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        handleSuccessOrder(id, response.razorpay_payment_id);
      },
      prefill: {
        name: `${details?.first_name} ${details?.last_name}`,
        email: details?.email,
        contact: details?.contact,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.on("payment.success", function (response) {});
    rzp1.open();
  };

  const handleSubmit = () => {
    console.log(formData);
    // List of required fields and their corresponding display names
    if (!formData) {
      toast.error(`Please enter the fields`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const requiredFields = [
        { key: "first_name", displayName: "First Name" },
        {
          key: "house_name",
          displayName: "House Name / House Number / Apartment",
        },
        { key: "country", displayName: "Country" },
        { key: "state", displayName: "State" },
        { key: "city", displayName: "City" },
        { key: "pincode", displayName: "Pincode" },
        { key: "contact", displayName: "Contact" },
      ];
      // Check if any required field is missing or blank
      const invalidField = requiredFields.find((field) => {
        const value = formData[field.key];
        return typeof value !== "string" || value.trim() === ""; // Check if value is not a string or is empty or consists of only whitespace
      });

      if (invalidField) {
        toast.error(`${invalidField.displayName} cannot be blank`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return; // Stop further execution
      }

      fetch(backend + "addaddress", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (!response.ok) {
            console.error(
              "Failed to register:",
              response.status,
              response.statusText
            );
            if (response.status === 409) {
              toast.error("Something Went Wrong", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
            }

            return;
          }

          response.json().then((data) => {
            handleOrderAddress(data);
            fetchAddress();
            setOpen(false);
            // Additional success handling code here
            toast.success("Primary Address Updated", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
          // navigate("/admin-users");
        })
        .catch((error) => {});
    }
  };
  const handleSubmitNewAddress = () => {
    // List of required fields and their corresponding display names
    const requiredFields = [
      { key: "first_name", displayName: "First Name" },
      {
        key: "house_name",
        displayName: "House Name / House Number / Apartment",
      },
      { key: "country", displayName: "Country" },
      { key: "state", displayName: "State" },
      { key: "city", displayName: "City" },
      { key: "pincode", displayName: "Pincode" },
      { key: "contact", displayName: "Contact" },
    ];

    // Check if any required field is missing or blank
    const invalidField = requiredFields.find((field) => {
      const value = newAdd[field.key];
      return typeof value !== "string" || value.trim() === ""; // Check if value is not a string or is empty or consists of only whitespace
    });

    if (invalidField) {
      toast.error(`${invalidField.displayName} cannot be blank`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    newAdd.is_primary = 1;
    fetch(backend + "addaddress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
      body: JSON.stringify(newAdd),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {
          handleOrderAddress(data);
          fetchAddress();
          setOpen(false);
          setNewDelivery(false);
          // Additional success handling code here
          toast.success("Address Added", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {});
  };

  const handleOrder = () => {
    if (delAd === null) {
      toast.error("Please choose a delivery address", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let payload = {};
    payload.delivery_id = delAd;
    payload.cart_id = data.map((item) => item.cart_id);
    payload.coupon_id = couponSuccess?.coupon_id;

    fetch(backend + "createorder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {
          handlePayment(data.order_no, data.grandTotalPrice, data.user_details);
          // Additional success handling code here
          // toast.success("Order Generated", {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {});
  };
  const handleOrderAddress = (item) => {
    let payload = {};
    payload.delivery_id = item.delivery_address_id;
    payload.cart_id = data.map((item) => item.cart_id);

    fetch(backend + "createorder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {
          handlePayment(data.order_no, data.grandTotalPrice, data.user_details);
          // Additional success handling code here
          // toast.success("Order Generated", {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {});
  };

  const handleSuccessOrder = (order_id, pay_id) => {
    setLoading(true);

    let payload = {};
    payload.razorpay_payment_id = pay_id;
    payload.order_no = order_id;
    payload.cart_id = data.map((item) => item.cart_id);

    fetch(backend + "successfullpayment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {
          setLoading(false);
          toast.success("Order Placed Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        navigate("/success");
      })
      .catch((error) => {});
  };
  const handleCoupon = () => {
    let payload = {};
    payload.amount = calculateTotal();
    payload.code = couponId;

    fetch(backend + "checkcoupon", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 401) {
            setCouponId("");
            toast.error("Invalid Coupon", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (response.status === 402) {
            toast.error("Not Applicable For this Amount", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (response.status === 403) {
            toast.error("Coupon Already Used", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          return;
        }
        response.json().then((data) => {
          setCouponId("");
          setCoupon(2);
          setCouponSuccess(data);
          toast.success("Coupon Applied", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {});
  };

  const handleDeleteAddress = async (id) => {
    try {
      const response = await fetch(backend + `deleteaddress/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(),
      });

      if (response.status === 200) {
        fetchAddress();
        toast.success("Address Deleted", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 409) {
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  console.log("coupon : ", coupon);
  console.log("coupon status : ", couponSuccess);
  console.log(formData);

  return (
    <>
      <main className="main " id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Shopping Cart </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <Header>
            <H2>Your Cart</H2>
          </Header>
          <div>
            <div className="">
              <div className="">
                <div className="d-flex justify-content-end">
                  {authenticated && data.length > 0 && (
                    <div>
                      {coupon === 1 ? (
                        <div className="d-flex gap-3">
                          <input
                            type="text"
                            value={couponId ?? ""}
                            onChange={(event) =>
                              setCouponId(event.target.value)
                            }
                            className=""
                          />
                          <button
                            type="button"
                            className="btn hover-up btn-light"
                            style={{ backgroundColor: "#FFA62F" }}
                            onClick={handleCoupon}
                          >
                            Apply
                          </button>
                        </div>
                      ) : coupon === 0 ? (
                        <CouponBtn type="button" onClick={() => setCoupon(1)}>
                          <MdOutlineLocalOffer />
                          Apply Coupon
                        </CouponBtn>
                      ) : (
                        <button
                          type="button"
                          className="btn hover-up btn-light"
                          style={{ backgroundColor: "#ACD793" }}
                          disabled
                        >
                          Coupon Applied
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="">
                <div className="product-grid mx-1">
                  {loading && (
                    <div>
                      <LoaderMain />
                    </div>
                  )}
                  <CardBoxContainer>
                    {!loading &&
                      (data.length > 0 ? (
                        <>
                          <CartBoxTop>
                            <p>Item Name</p>
                            <p>Price</p>
                            <p>Quantity</p>
                            <p>Total Amount</p>
                            <p>Remove</p>
                          </CartBoxTop>
                          {data.map((item, i) => (
                            <CartBox
                              className=" wow animate__animated animate__fadeIn"
                              data-wow-delay="0.1s"
                            >
                              <Section className="one">
                                <div>
                                  <a
                                    onClick={() =>
                                      handleProductRoute(item.product_slug)
                                    }
                                  >
                                    <ImageContainer>
                                      <img
                                        // src={mediaurl + item.images}
                                        src={producturl + item?.thumbnail}
                                        // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                      />
                                      {/* <img
                                      className="hover-img"
                                      style={{
                                        height: "200px",
                                        width: "100%",
                                        objectFit: "contain",
                                        objectPosition: "center",
                                      }}
                                      src={producturl + item?.thumbnail2}
                                      // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                    /> */}
                                    </ImageContainer>
                                  </a>
                                </div>
                                <div>
                                  <Link
                                    className="smallLink"
                                    onClick={() =>
                                      handleProductRoute(item.product_slug)
                                    }
                                  >
                                    {item?.category_name}
                                  </Link>
                                  <Link
                                    href={`/product/${item.product_slug}`}
                                    onClick={() =>
                                      handleProductRoute(item.product_slug)
                                    } // title="Nestle Original Coffee-Mate Coffee Creamer"
                                  >
                                    {item?.product_name}
                                  </Link>
                                  <div className="product-rate-cover">
                                    <div className="d-inline-block">
                                      <Rating
                                        name="read-only"
                                        value={item?.average_stars}
                                        readOnly
                                        size="small"
                                      />
                                    </div>
                                    <span
                                      className="font-small ml-5 text-muted"
                                      style={{ verticalAlign: "top" }}
                                    >
                                      ({item?.average_stars ?? "Not Yet Rated"})
                                    </span>
                                  </div>
                                  <span className="font-small text-muted">
                                    Sold By{" "}
                                    <b style={{ fontWeight: "700" }}>
                                      <a
                                      // onClick={() => handleProductRoute(item.product_slug)}
                                      >
                                        {item?.manufacturer_name ??
                                          "KeralaSpecial"}
                                      </a>
                                    </b>
                                  </span>
                                </div>
                              </Section>
                              <Section className="two">
                                {" "}
                                <div className="product-content-wrap">
                                  <span>₹ {item?.mrp} </span>
                                </div>
                                <QtyBtn className="mobile">
                                  <Btn
                                    onClick={() =>
                                      handleDecrement(
                                        item?.cart_id,
                                        item?.quantity
                                      )
                                    }
                                  >
                                    <LuMinus />
                                  </Btn>
                                  <div>
                                    <input
                                      type="text"
                                      min="1"
                                      value={item?.quantity}

                                      // onChange={(event) =>
                                      //   handleQuantityChange(
                                      //     item.cart_id,
                                      //     event.target.value
                                      //   )
                                      // }
                                    />
                                  </div>

                                  <Btn
                                    onClick={() =>
                                      handleIncrement(
                                        item?.cart_id,
                                        item?.quantity
                                      )
                                    }
                                  >
                                    <HiOutlinePlus />
                                  </Btn>
                                </QtyBtn>
                              </Section>
                              <Section className="three">
                                <QtyBtn>
                                  <Btn
                                    onClick={() =>
                                      handleDecrement(
                                        item?.cart_id,
                                        item?.quantity
                                      )
                                    }
                                  >
                                    <LuMinus />
                                  </Btn>
                                  <div>
                                    <input
                                      type="text"
                                      min="1"
                                      value={item?.quantity}

                                      // onChange={(event) =>
                                      //   handleQuantityChange(
                                      //     item.cart_id,
                                      //     event.target.value
                                      //   )
                                      // }
                                    />
                                  </div>

                                  <Btn
                                    onClick={() =>
                                      handleIncrement(
                                        item?.cart_id,
                                        item?.quantity
                                      )
                                    }
                                  >
                                    <HiOutlinePlus />
                                  </Btn>
                                </QtyBtn>
                              </Section>
                              <Section className="four">
                                <div>
                                  <h3>₹ {item?.total}</h3>
                                </div>
                              </Section>
                              <Section>
                                <div className="pt-5 mt-5">
                                  <Icon
                                    icon="openmoji:delete"
                                    color="black"
                                    className="hover-up"
                                    fontSize={46}
                                    onClick={() =>
                                      handleDeleteCart(item?.cart_id)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </Section>
                            </CartBox>
                          ))}
                        </>
                      ) : (
                        <p>Loading...</p>
                      ))}
                  </CardBoxContainer>

                  <StyledCollapse in={delivery}>
                    <div className="mt-3" id="delivery-full">
                      <H2 className="heading-2">Delivery Address</H2>
                      <div className="py-3">
                        <div>
                          {" "}
                          <>
                            {address[0]?.city ? (
                              <Button
                                onClick={() => {
                                  setNewDelivery(!newDelivery);
                                  setOpen(false);
                                }}
                                aria-controls="example-new-text"
                                aria-expanded={newDelivery}
                              >
                                {" "}
                                Add a New Delivery Address
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  setNewDelivery(false);
                                  setOpen(!open);
                                }}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                              >
                                Edit Primary Address
                              </Button>
                            )}

                            {/* <Button
                              onClick={() => {
                                setNewDelivery(false); setOpen(!open);
                              }}
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                            >Edit Primary Address
                            </Button> */}
                          </>
                          <div
                            className=""
                            style={{
                              textAlign: "start",
                              color: "#3bb77e",
                            }}
                          >
                            {address?.map((item, i) => (
                              <div
                                className="my-3 d-flex justify-content-between align-items-center"
                                key={i}
                              >
                                <div>
                                  <h3 className="heading-4 product-price capitalize">
                                    <input
                                      className="form-check-input mt-2"
                                      type="radio"
                                      name="delivery_address"
                                      id={`delivery-address-${i}`}
                                      onChange={() =>
                                        setDelAd(item?.delivery_address_id)
                                      }
                                    />{" "}
                                    {item?.first_name} {item?.last_name}
                                  </h3>
                                  {item?.house_name &&
                                  item?.countryname &&
                                  item?.city &&
                                  item?.pincode ? (
                                    <h5 className="heading-4 product-price capitalize">
                                      {item?.house_name}, <br />{" "}
                                      {item?.street_name}, {item?.city}, <br />
                                      {item?.state}, {item?.countryname}, <br />
                                      {item?.pincode}, <br /> PH NO :{" "}
                                      {item?.contact}
                                    </h5>
                                  ) : (
                                    <>No more details</>
                                  )}
                                </div>
                                <button
                                  type="button"
                                  className="hover-up"
                                  style={{
                                    color: "#fff",
                                    borderRadius: "5px",
                                    border: "none",
                                    padding: "10px 20px",
                                    background: "#094C27",
                                  }}
                                  onClick={() =>
                                    handleDeleteAddress(
                                      item?.delivery_address_id
                                    )
                                  }
                                >
                                  Delete
                                </button>{" "}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <StyledCollapse in={open}>
                        <div
                          className="pt-4"
                          data-wow-delay="0.1s"
                          id="example-collapse-text"
                        >
                          <div className="form__content">
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="name">First Name</label>{" "}
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    name="product_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.first_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        first_name: e.target.value,
                                      })
                                    }
                                    placeholder="First Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="name">Last Name</label>{" "}
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    name="last_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.last_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        last_name: e.target.value,
                                      })
                                    }
                                    placeholder="Last Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12">
                                <div className="">
                                  <label htmlFor="name">
                                    House Name / House Number / Apartment
                                  </label>{" "}
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    name="house_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.house_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        house_name: e.target.value,
                                      })
                                    }
                                    placeholder="House Name / House Number / Apartment"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="name">Street Name</label>{" "}
                                  {/* <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup> */}
                                  <input
                                    className=""
                                    name="street_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.street_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        street_name: e.target.value,
                                      })
                                    }
                                    placeholder="Street Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="city">City</label>
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    type="text"
                                    name="city"
                                    required
                                    value={formData?.city || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        city: e.target.value,
                                      })
                                    }
                                    placeholder="City"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="state">State</label>
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    type="text"
                                    name="state"
                                    required
                                    value={formData?.state || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        state: e.target.value,
                                      })
                                    }
                                    placeholder="State"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="country">Country</label>
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <select
                                    name="category_name"
                                    id="txt-name"
                                    required
                                    value={formData?.country || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        country: e.target.value,
                                      })
                                    }
                                  >
                                    <option>- select -</option>
                                    {count.map((val) => {
                                      return (
                                        <option value={val.country_id}>
                                          {val.countryname}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="pincode">Pin Code</label>
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    type="text"
                                    name="pincode"
                                    required
                                    value={formData?.pincode || ""}
                                    maxLength={6}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        pincode: e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        ),
                                      })
                                    }
                                    placeholder="Pincode"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="street_name">
                                    Contact Number
                                  </label>
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    type="text"
                                    name="phone"
                                    minLength={6}
                                    maxLength={12}
                                    required
                                    value={formData?.contact || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        contact: e.target.value.replace(
                                          /[^0-9+]/g,
                                          ""
                                        ),
                                      })
                                    }
                                    placeholder="Primary contact number"
                                  />
                                </div>
                              </div>
                              <div className="login_footer form-group">
                                <div className="chek-form">
                                  <div className="custome-checkbox">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="agree_terms_and_policy"
                                      id="agree-terms-and-policy"
                                      checked={formData?.is_primary === 0} // Check if is_primary is 1
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          is_primary: e.target.checked ? 0 : 1, // Set to 1 if checked, 0 otherwise
                                        })
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      for="agree-terms-and-policy"
                                    >
                                      <span>
                                        <a>Set this as my primary address.</a>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <SubTotalContainer>
                                <SubTotal>
                                  <h3>Cart Total </h3>
                                  {/*  */}
                                  <Text>
                                    <p>Coupons</p>
                                    <p>:</p>
                                    {couponSuccess ? (
                                      <>
                                        <p>
                                          ₹{" "}
                                          {calculateTotalMRP() +
                                            calculateTotalSHIPPING()}
                                        </p>{" "}
                                        <p>
                                          ₹{" "}
                                          {calculateTotalMRPCoupon() +
                                            calculateTotalSHIPPING()}
                                        </p>{" "}
                                      </>
                                    ) : (
                                      <p>No Coupons Applied</p>
                                    )}
                                  </Text>
                                  <Text>
                                    <p>Product Total</p>
                                    <p>:</p>

                                    {couponSuccess ? (
                                      <>
                                        <p style={{ color: "#615EFC" }}>
                                          {" "}
                                          {calculateTotalMRP()}
                                        </p>{" "}
                                        <p> {calculateTotalMRPCoupon()}</p>{" "}
                                      </>
                                    ) : (
                                      <p> ₹{calculateTotalMRP()}</p>
                                    )}
                                  </Text>
                                  <Text>
                                    <p>Shipping Charges</p>
                                    <p>:</p>
                                    <p>₹{calculateTotalSHIPPING()}</p>
                                  </Text>
                                  <Text className="total">
                                    <p>Sub Total</p>
                                    <p>:</p>
                                    <p>
                                      ₹
                                      {calculateTotalMRP() +
                                        calculateTotalSHIPPING()}
                                    </p>
                                  </Text>
                                  <h5 className="heading-4 product-price text-secondary"></h5>
                                  <button
                                    type="button"
                                    className="btn hover-up"
                                    style={{ backgroundColor: "red" }}
                                    onClick={() => handleSubmit()}
                                  >
                                    Submit
                                  </button>
                                </SubTotal>
                              </SubTotalContainer>
                              {/* <div className="d-flex justify-content-end gap-5 ">
                                <div className="d-flex justify-content-between gap-5">
                                  <h3 className="heading-4 product-price text-white">
                                    SUBTOTAL{" "}
                                  </h3>
                                  <h3 className="heading-4 product-price text-white">
                                    {couponSuccess ? (
                                      <>
                                        <s  >
                                          ₹{" "}
                                          {calculateTotalMRP() +
                                            calculateTotalSHIPPING()}
                                        </s>{" "}
                                        <div>
                                          ₹{" "}
                                          {calculateTotalMRPCoupon() +
                                            calculateTotalSHIPPING()}
                                        </div>{" "}
                                      </>
                                    ) : (
                                      <div>
                                        {" "}
                                        ₹{" "}
                                        {calculateTotalMRP() +
                                          calculateTotalSHIPPING()}
                                      </div>
                                    )}
                                  </h3>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-end ">
                                  <h5 className="heading-4 product-price text-secondary">
                                    (PRODUCT TOTAL ₹{" "}
                                  </h5>
                                  <h5 className="heading-4 product-price text-secondary">
                                    {couponSuccess ? (
                                      <>
                                        <s style={{ color: "#615EFC" }}>
                                          {" "}
                                          {calculateTotalMRP()}
                                        </s>{" "}
                                        <div> {calculateTotalMRPCoupon()})</div>{" "}
                                      </>
                                    ) : (
                                      <div> {calculateTotalMRP()})</div>
                                    )}
                                  </h5>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-start ">
                                  <h5 className="heading-4 product-price text-secondary">
                                    (SHIPPING CHARGE ₹{" "}
                                  </h5>
                                  <h5 className="heading-4 product-price text-secondary">
                                    {calculateTotalSHIPPING()})
                                  </h5>
                                </div>
                              </div> */}
                              {/* <div className="">
                                <button
                                  type="button"
                                  className="btn hover-up"
                                  style={{ backgroundColor: "red" }}
                                  onClick={() => handleSubmit()}
                                >
                                  Submit
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </StyledCollapse>
                      <StyledCollapse in={newDelivery}>
                        <div
                          className="pt-4"
                          data-wow-delay="0.1s"
                          id="example-new-text"
                        >
                          <div className="form__content">
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="name">First Name</label>{" "}
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    name="product_name"
                                    id="txt-name"
                                    type="text"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      border: "1px solid #000",
                                    }}
                                    // required
                                    value={newAdd?.first_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        first_name: e.target.value,
                                      })
                                    }
                                    placeholder="First Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="name">Last Name</label>{" "}
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    name="last_name"
                                    id="txt-name"
                                    type="text"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      border: "1px solid #000",
                                    }}
                                    // required
                                    value={newAdd?.last_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        last_name: e.target.value,
                                      })
                                    }
                                    placeholder="Last Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12">
                                <div className="">
                                  <label htmlFor="name">
                                    House Name / House Number / Apartment
                                  </label>{" "}
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    name="house_name"
                                    id="txt-name"
                                    type="text"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      border: "1px solid #000",
                                    }}
                                    // required
                                    value={newAdd.house_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        house_name: e.target.value,
                                      })
                                    }
                                    placeholder="House Name / House Number / Apartment"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="name">Street Name</label>{" "}
                                  <input
                                    className=""
                                    name="street_name"
                                    id="txt-name"
                                    type="text"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      border: "1px solid #000",
                                    }}
                                    // required
                                    value={newAdd.street_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        street_name: e.target.value,
                                      })
                                    }
                                    placeholder="Street Name"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="city">City</label>
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    type="text"
                                    name="city"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      border: "1px solid #000",
                                    }}
                                    required
                                    value={newAdd.city || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        city: e.target.value,
                                      })
                                    }
                                    placeholder="City"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="state">State</label>
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    type="text"
                                    name="state"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      border: "1px solid #000",
                                    }}
                                    required
                                    value={newAdd.state || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        state: e.target.value,
                                      })
                                    }
                                    placeholder="State"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="country">Country</label>
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <select
                                    className=""
                                    name="category_name"
                                    id="txt-name"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      border: "1px solid #000",
                                    }}
                                    required
                                    value={newAdd.country || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        country: e.target.value,
                                      })
                                    }
                                  >
                                    <option>- select -</option>
                                    {count.map((val) => {
                                      return (
                                        <option value={val.country_id}>
                                          {val.countryname}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="pincode">Pin Code</label>
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    type="text"
                                    name="pincode"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      border: "1px solid #000",
                                    }}
                                    required
                                    value={newAdd.pincode || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        pincode: e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        ),
                                      })
                                    }
                                    placeholder="Pincode"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="">
                                  <label htmlFor="street_name">
                                    Contact Number
                                  </label>
                                  <sup
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    *
                                  </sup>
                                  <input
                                    className=""
                                    type="text"
                                    name="phone"
                                    minLength={6}
                                    maxLength={12}
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      border: "1px solid #000",
                                    }}
                                    required
                                    value={newAdd.contact || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        contact: e.target.value.replace(
                                          /[^0-9+]/g,
                                          ""
                                        ),
                                      })
                                    }
                                    placeholder="Primary contact number"
                                  />
                                </div>
                              </div>
                              {/* <div className="login_footer form-group">
                              <div className="chek-form">
                                <div className="custome-checkbox">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="agree_terms_and_policy"
                                    id="agree-terms-and-policy"
                                    checked={newAdd.is_primary === 0}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        is_primary: e.target.checked ? 0 : 1,                                      })
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for="agree-terms-and-policy"
                                  >
                                    <span>
                                      <a>
                                        Set this as my primary address.</a>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div> */}
                              <SubTotalContainer>
                                {!newDelivery && !open && (
                                  <SubTotal>
                                    <h3>Cart Total </h3>
                                    {/*  */}
                                    <Text>
                                      <p>Coupons</p>
                                      <p>:</p>
                                      {couponSuccess ? (
                                        <>
                                          <p>
                                            ₹{" "}
                                            {calculateTotalMRP() +
                                              calculateTotalSHIPPING()}
                                          </p>{" "}
                                          <p>
                                            ₹{" "}
                                            {calculateTotalMRPCoupon() +
                                              calculateTotalSHIPPING()}
                                          </p>{" "}
                                        </>
                                      ) : (
                                        <p>No Coupons Applied</p>
                                      )}
                                    </Text>
                                    <Text>
                                      <p>Product Total</p>
                                      <p>:</p>

                                      {couponSuccess ? (
                                        <>
                                          <p style={{ color: "#615EFC" }}>
                                            {" "}
                                            {calculateTotalMRP()}
                                          </p>{" "}
                                          <p> {calculateTotalMRPCoupon()}</p>{" "}
                                        </>
                                      ) : (
                                        <p> ₹{calculateTotalMRP()}</p>
                                      )}
                                    </Text>
                                    <Text>
                                      <p>Shipping Charges</p>
                                      <p>:</p>
                                      <p>₹{calculateTotalSHIPPING()}</p>
                                    </Text>
                                    <Text className="total">
                                      <p>Sub Total</p>
                                      <p>:</p>
                                      <p>
                                        ₹
                                        {calculateTotalMRP() +
                                          calculateTotalSHIPPING()}
                                      </p>
                                    </Text>
                                    <h5 className="heading-4 product-price text-secondary"></h5>
                                    <button
                                      type="button"
                                      className="btn btn-fill-out btn-block hover-up btn-secondary"
                                      onClick={() => handleSubmitNewAddress()}
                                    >
                                      Submit
                                    </button>
                                  </SubTotal>
                                )}
                              </SubTotalContainer>
                              {/* <div className="d-flex justify-content-end gap-5 ">
                                <div className="d-flex justify-content-between gap-5">
                                  <h3 className="heading-4 product-price text-white">
                                    SUBTOTAL x
                                  </h3>
                                  <h3 className="heading-4 product-price text-white">
                                    {couponSuccess ? (
                                      <>
                                        <s style={{ color: "#B06161" }}>
                                          ₹{" "}
                                          {calculateTotalMRP() +
                                            calculateTotalSHIPPING()}
                                        </s>{" "}
                                        <div>
                                          ₹{" "}
                                          {calculateTotalMRPCoupon() +
                                            calculateTotalSHIPPING()}
                                        </div>{" "}
                                      </>
                                    ) : (
                                      <div>
                                        {" "}
                                        ₹{" "}
                                        {calculateTotalMRP() +
                                          calculateTotalSHIPPING()}
                                      </div>
                                    )}
                                  </h3>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-end ">
                                  <h5 className="heading-4 product-price text-secondary">
                                    (PRODUCT TOTAL ₹{" "}
                                  </h5>
                                  <h5 className="heading-4 product-price text-secondary">
                                    {couponSuccess ? (
                                      <>
                                        <s style={{ color: "#B06161" }}>
                                          {" "}
                                          {calculateTotalMRP()}
                                        </s>{" "}
                                        <div>
                                          {" "}
                                          ({calculateTotalMRPCoupon()})
                                        </div>{" "}
                                      </>
                                    ) : (
                                      <div> ({calculateTotalMRP()})</div>
                                    )}
                                  </h5>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-start ">
                                  <h5 className="heading-4 product-price text-secondary">
                                    (SHIPPING CHARGE ₹ )
                                  </h5>
                                  <h5 className="heading-4 product-price text-secondary">
                                    ({calculateTotalSHIPPING()})
                                  </h5>
                                </div>
                              </div>

                              <div className="">
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up btn-secondary"
                                  onClick={() => handleSubmitNewAddress()}
                                >
                                  Submit
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </StyledCollapse>
                      <SubTotalContainer>
                        {!newDelivery && !open && (
                          <SubTotal>
                            <h3>Cart Total </h3>
                            {/*  */}
                            <Text>
                              <p>Coupons</p>
                              <p>:</p>
                              {couponSuccess ? (
                                <>
                                  <p>
                                    ₹{" "}
                                    {calculateTotalMRP() +
                                      calculateTotalSHIPPING()}
                                  </p>{" "}
                                  <p>
                                    ₹{" "}
                                    {calculateTotalMRPCoupon() +
                                      calculateTotalSHIPPING()}
                                  </p>{" "}
                                </>
                              ) : (
                                <p>No Coupons Applied</p>
                              )}
                            </Text>
                            <Text>
                              <p>Product Total</p>
                              <p>:</p>

                              {couponSuccess ? (
                                <>
                                  <p style={{ color: "#615EFC" }}>
                                    {" "}
                                    {calculateTotalMRP()}
                                  </p>{" "}
                                  <p> {calculateTotalMRPCoupon()}</p>{" "}
                                </>
                              ) : (
                                <p> ₹{calculateTotalMRP()}</p>
                              )}
                            </Text>
                            <Text>
                              <p>Shipping Charges</p>
                              <p>:</p>
                              <p>₹{calculateTotalSHIPPING()}</p>
                            </Text>
                            <Text className="total">
                              <p>Sub Total</p>
                              <p>:</p>
                              <p>
                                ₹
                                {calculateTotalMRP() + calculateTotalSHIPPING()}
                              </p>
                            </Text>
                            <h5 className="heading-4 product-price text-secondary"></h5>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block btn-secondary hover-up"
                              // onClick={handlePayment}
                              onClick={handleOrder}
                            >
                              Check out
                            </button>{" "}
                          </SubTotal>
                        )}
                      </SubTotalContainer>
                    </div>
                  </StyledCollapse>
                  {!loading && data.length > 0 && (
                    <SubTotalContainer>
                      {!delivery ? (
                        <SubTotal>
                          <h3>Cart Total </h3>
                          {/*  */}
                          <Text>
                            <p>Coupons</p>
                            <p>:</p>
                            {couponSuccess ? (
                              <>
                                <p>
                                  ₹{" "}
                                  {calculateTotalMRP() +
                                    calculateTotalSHIPPING()}
                                </p>{" "}
                                <p>
                                  ₹{" "}
                                  {calculateTotalMRPCoupon() +
                                    calculateTotalSHIPPING()}
                                </p>{" "}
                              </>
                            ) : (
                              <p>No Coupons Applied</p>
                            )}
                          </Text>
                          <Text>
                            <p>Product Total</p>
                            <p>:</p>

                            {couponSuccess ? (
                              <>
                                <p style={{ color: "#615EFC" }}>
                                  {" "}
                                  {calculateTotalMRP()}
                                </p>{" "}
                                <p> {calculateTotalMRPCoupon()}</p>{" "}
                              </>
                            ) : (
                              <p> ₹{calculateTotalMRP()}</p>
                            )}
                          </Text>
                          <Text>
                            <p>Shipping Charges</p>
                            <p>:</p>
                            <p>₹{calculateTotalSHIPPING()}</p>
                          </Text>
                          <Text className="total">
                            <p>Sub Total</p>
                            <p>:</p>
                            <p>
                              ₹{calculateTotalMRP() + calculateTotalSHIPPING()}
                            </p>
                          </Text>
                          <h5 className="heading-4 product-price text-secondary"></h5>
                          <button
                            type="button"
                            className="btn btn-fill-out btn-block hover-up"
                            onClick={() => setDelivery(!delivery)}
                            aria-controls="delivery-full"
                            aria-expanded={delivery}
                          >
                            Buy Now
                          </button>{" "}
                        </SubTotal>
                      ) : (
                        <BackBtn
                          type="button"
                          onClick={() => setDelivery(!delivery)}
                          aria-controls="delivery-full"
                          aria-expanded={delivery}
                        >
                          Back
                        </BackBtn>
                      )}
                    </SubTotalContainer>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          <ScrollToTop />
        </div>
      </main>
    </>
  );
}
export default Cart;
const Header = styled.div``;
const H2 = styled.h2`
  font-weight: 500;
  padding: 20px 0;

  @media screen and (max-width: 640px) {
    font-size: 25px;
  }
`;
const CouponBtn = styled.div`
  border: 1px solid #ff8585;
  background-color: #ff8585;
  color: #fff;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 6px;
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0;
  svg {
    font-size: 18px;
  }
`;
const CardBoxContainer = styled.div`
  display: grid;
  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
    column-gap: 20px;
  }
`;
const CartBox = styled.div`
  box-shadow: 0px 0px 14px #00000034;
  border-radius: 10px;
  margin: 20px 0;

  display: grid;
  grid-template-columns: 2fr 0.8fr 0.8fr 0.8fr 0.5fr;

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`;
const CartBoxTop = styled.div`
  display: grid;
  grid-template-columns: 2fr 0.8fr 0.8fr 0.8fr 0.5fr;
  margin-bottom: 0;
  box-shadow: unset;
  border-radius: 0;
  margin-top: 40px;
  p {
    text-align: center;
    &:first-child {
      text-align: left;
    }
  }

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const Section = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  &.one {
    justify-self: start;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    align-items: center;
    img {
      border-radius: 10px;
    }
    @media screen and (max-width: 1080px) {
      grid-template-columns: 1fr;
      place-items: center;
      width: 100%;
    }
  }
  &.two {
    text-align: center;
    span {
      font-size: 28px;
      font-weight: 600;
      color: #253d4e;
    }
    @media screen and (max-width: 1080px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-items: center;
      width: 100%;
      margin-bottom: 20px;
    }
  }
  &.three {
    @media screen and (max-width: 1080px) {
      display: none;
    }
  }
  &.four {
    h3 {
      text-align: center;
    }
    span {
      font-size: 13px;
    }
  }
`;
const ImageContainer = styled.div`
  height: 200px;
  width: 200px;

  img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
  }
`;
const Link = styled.a`
  display: block;
  color: #363739;
  font-weight: 600;
  font-size: 25px;
  line-height: 1;
  text-transform: capitalize;
  &.smallLink {
    font-size: 16px;
    font-weight: 400;
  }
`;
const QtyBtn = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  input {
    border: none;
    padding: 0;
    display: inline-block;
    max-width: 30px;
    text-align: center;
    -moz-appearance: textfield; /* Firefox */
    -webkit-appearance: none; /* Safari/Chrome */
    appearance: none;
    font-size: 20px;
    font-weight: 600;
    color: #253d4e;
  }
  svg {
    font-size: 20px;
    color: #253d4e;
  }

  &.mobile {
    display: none;
    @media screen and (max-width: 1080px) {
      display: flex;
    }
  }
`;
const Btn = styled.div`
  border: 1px solid #6b6b6b;
  display: inline-block;
  padding: 3px;
  border-radius: 50px;
`;
const SubTotalContainer = styled.div`
  display: grid;
  width: 100%;
  justify-items: end;
  margin: 30px 0;
`;
const SubTotal = styled.div`
  box-shadow: 0px 0px 14px #00000034;
  h3 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  button {
    margin-top: 20px;
  }

  display: inline-block;
  padding: 20px;
  border-radius: 10px;
`;
const Text = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr;
  &.total {
    p {
      font-size: 23px;
      font-weight: 600;
    }
  }
`;
const BackBtn = styled.div`
  margin: 0;
  background-color: #094c27;
  padding: 10px 25px;
  font-weight: 600;
  color: #fff;
  border-radius: 6px;
  font-size: 17px;
`;
const StyledCollapse = styled(Collapse)`
  input,
  select {
    border: 1px solid #6b6b6b;
    border-radius: 10px;
    padding: 10px 0;
    margin-bottom: 20px;
    font-family: "Montserrat";
    padding-left: 20px;
    &::placeholder {
      font-family: "Montserrat";
    }
  }
`;
