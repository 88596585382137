import { useEffect } from "react";
import ScrollToTop from "../../../components/whatsapp connect";
import styled from "styled-components";

function Policy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main class="main" id="main-section">
        <ScrollToTop />
        <div class="page-header breadcrumb-wrap">
          <div class="container">
            <div class="breadcrumb">
              <div class="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div class="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Privacy Policy </div>
              </div>
            </div>
          </div>
        </div>
        <MainContainer className="newsletter py-5 wow animate__animated animate__fadeIn">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="">
                  <div className="newsletter-content">
                    <h2 className="mb-20">Terms & Conditions</h2>
                    <p className="mb-45">
                      This website is operated by Kitsor Pvt Ltd in the name of
                      Keralaspecial.in (henceforth referred to as “the
                      Concern”). The Terms and Conditions and all applicable
                      Policies (as defined below), which are herein incorporated
                      by way of reference, govern your rights and obligations as
                      a User of the keralaspecial.in (henceforth referred to as
                      “the Website”) while accessing and using the platform
                      and/or availing the Services.
                    </p>
                    <p className="mb-20">
                      Please read these Terms and Conditions, and Policies
                      carefully before accessing or using our website. By
                      accessing or using any part of the site, you agree to be
                      bound by these Terms and Conditions. If you do not agree
                      to all the terms and conditions of this agreement, then
                      you may not access the website or use any of our services.
                    </p>
                    <h2 className="mb-20">User Agreement</h2>

                    <p className="mb-20">
                      This Agreement sets forth the terms and conditions that
                      apply to the use of the Website by the User. By using this
                      Site, the User agrees to comply with all of the terms and
                      conditions hereof. This User Agreement contains policies,
                      terms and conditions, agreement, and rules and regulations
                      applicable to any user who may access or use
                      keralaspecial.in, including any sub domains, webpages or
                      extension of the website and you hereby agree and
                      acknowledge to be bound by the terms of this User
                      Agreement.
                    </p>
                    <p className="mb-20">
                      For the purposes of this User Agreement, ‘User’ and
                      wherever the context may require ‘You’ (or any variation
                      thereof), shall mean any natural or legal person who has
                      agreed to become a user of the Website by accessing or
                      browsing the Website and/or has registered as a member of
                      the Website by submitting identification information/
                      registration details using the computer system of this
                      Website and Accepting the electronic record of the User
                      Agreement and has obtained a unique username and a secret
                      alpha-numerical key (password) for using the Website.
                    </p>
                    <p className="mb-45">
                      Right to Amend: Kitsor Pvt Ltd (Keralaspecial) reserves
                      the right to change this Website and/or alter the terms
                      and conditions of this User Agreement from time to time
                      and retains the right to deny access to anyone who the
                      Concern believes has violated the provisions of this User
                      Agreement. You are advised that any amendment to the User
                      Agreement or rules and policies incorporated herein by
                      reference will only be notified on the Website on publicly
                      accessible links and you agree by accessing, browsing or
                      using this website that this constitutes sufficient notice
                      for all purposes against you. The revised version/terms
                      shall be effective from the time that the Concern posts
                      the same on the Website. By impliedly or expressly
                      accepting this User Agreement, You also accept and agree
                      to be bound by Kitsor Pvt Ltd (Keralaspecial) Terms and
                      Conditions, Kitsor Pvt Ltd (Keralaspecial) Policies for
                      Sellers, Kitsor Pvt Ltd (Keralaspecial) Privacy Policies,
                      and Kitsor Pvt Ltd (Keralaspecial) Shipment and Refund
                      Policies.
                    </p>

                    <h2 className="mb-20">DEFINITIONS</h2>

                    <h2 className="mb-20">User Eligibility</h2>

                    <p className="mb-20">
                      The services from the Kitsor Pvt Ltd (Keralaspecial) site
                      may be availed only by persons who are competent to enter
                      into legally binding contracts under the Indian Contract
                      Act, 1872. Any person who shall be considered as
                      incompetent to contract as per the provisions of the
                      Indian Contract Act, 1872, including minors, un-discharged
                      insolvents, etc., are not eligible to use the
                      keralaspecial.in Platform. A minor is not allowed to
                      access or register as a User or sell or purchase any items
                      on the Website.
                    </p>
                    <p className="mb-20">
                      User is any person using the Services and/or the Indirect
                      Services on behalf of a proprietor/partnership/
                      firm/Concern. The user represents and warrants that he/she
                      is duly authorized to accept the Terms and Conditions and
                      the Policies for the User.
                    </p>
                    <p className="mb-20">
                      Buyer is any person who is a user of keralaspecial.in and
                      who buys a merchandise from the Site by accepting the
                      Terms and Conditions, and the Policies of the Concern.
                    </p>
                    <h2 className="mb-20">Payments</h2>

                    <p className="mb-20">
                      Payment on Billing shall mean a Transaction where the
                      payment for the items purchased is paid by the buyer
                      through the Payment Gateway system. Kitsor Pvt Ltd
                      (Keralaspecial) utilizes the Payment Gateway Facility. The
                      customers can choose either net banking or credit/debit
                      cards for making payments.
                    </p>
                    <p className="mb-20">
                      Despatch/Despatched shall mean that the items, for which a
                      Transaction Price has been paid by the buyer or the buyer
                      has opted for Payment on Delivery, or such other mode of
                      payment approved by the Concern, have been freighted by
                      the seller through a reputed courier service or by the
                      Logistics Partner at the address communicated by the buyer
                      on the Website.
                    </p>
                    <p className="mb-20">
                      Despatch Details means the true, accurate and valid data,
                      information, details or documents as specified by the
                      Concern from time to time, which the seller/Logistics
                      Partner is obligated to provide to the Concern [on the
                      Website] as proof that the item has been Despatched and/or
                      Delivered, as the case maybe.
                    </p>
                    <p className="mb-20">
                      Delivery/Delivered means physical delivery of the items to
                      the buyer (for which a Transaction Price has been paid by
                      the buyer by Payment on Billing or the buyer has opted for
                      Payment on Delivery, or such other mode of payment
                      approved by the Concern) by the Kitsor Pvt Ltd
                      (Keralaspecial)/seller through a reputed courier service
                      or by the Logistics Partner at the address communicated by
                      the buyer on the Website.
                    </p>
                    <p className="mb-20">
                      Information means and shall include any confidential
                      and/or personally identifiable information or other
                      information provided to the Concern or other Users of the
                      Website at the time of registration with the Website,
                      buying or listing process or through any email feature and
                      shall include without limitation your name, email address,
                      billing/shipping address, phone number and
                      banking/financial information.
                    </p>
                    <p className="mb-20">
                      Issuing Bank in respect of a buyer, means any bank that
                      has issued a Valid Card (credit/debit/cash card) to the
                      buyer or the branch of a bank which maintains a Valid Bank
                      Account in the name of buyer; with which the buyer makes
                      payment of the Transaction Price.
                    </p>
                    <p className="mb-20">
                      Logistics Partner means logistics and courier Concern
                      appointed by the Concern that will provide various
                      services of collection and delivery of items, collection
                      of the Transaction Price from the buyer in case of Payment
                      on Delivery Transactions or such other services that the
                      Concern may require.
                    </p>
                    <p className="mb-20">
                      Transaction means every electronically generated valid
                      purchase order placed by the Buyer for purchasing the
                      items listed on the Website.
                    </p>
                    <p className="mb-20">
                      Transaction Price means the price to be paid by the buyer
                      for the items to be purchased for every Transaction and
                      which price shall include, if applicable, the shipping
                      charges, insurance charges and all other taxes, duties,
                      costs, charges and expenses in respect thereof as charged
                      by the seller.
                    </p>
                    <p className="mb-20">
                      Valid Card means any valid credit card/valid debit
                      card/valid cash card or any other card of whatsoever
                      nature issued by Visa or MasterCard or Maestro and/or by
                      any Issuing Bank or any institution designated to issue
                      such cards and lawfully owned by the User of the card at
                      the time of the Transaction as well as at the time of
                      Refund, if any.
                    </p>
                    <p className="mb-20">
                      Valid Bank Account shall mean a valid and operational bank
                      account in the name of the User of which the User is the
                      rightful and legal owner having the ability to issue
                      cheques/demand drafts and make deposits, which in respect
                      of the buyer shall be referred to as the Buyer Bank
                      Account and in respect of the Seller be referred to as the
                      Seller Bank Account. [Both the Buyer Bank Account and
                      Seller Bank Account are required to be with a branch of a
                      nationalized bank as per applicable Indian laws or other
                      banks which are registered with or approved by Reserve
                      Bank of India and such branch and/or account of the Buyer
                      and Seller supports Reserve Bank of India’s
                      electronic/online banking system including, without
                      limitation, National Electronic Funds Transfer (NEFT)
                      and/or Real Time Gross Settlement (RTGS)].
                    </p>
                    <p className="mb-20">
                      A payment Gateway Facility means the automated electronic
                      payment or collection and remittance facility provided by
                      the Concern to Buyers and Sellers to facilitate purchase
                      and sale of items and making payments there for on the
                      Website directly through banks or financial institution
                      infrastructure or indirectly through payment gateway
                      facility providers or through any such facility authorized
                      by the Reserve Bank of India to provide enabling support
                      facility for collection and remittance of payment.
                    </p>
                    <p className="mb-20">
                      Registration: You are solely responsible for maintaining
                      secrecy and confidentiality of your username and password.
                      You hereby acknowledge and accept that the website will
                      grant access to any person who has obtained your username
                      and password in the same manner as it would have granted
                      access to you and you are responsible for all activities
                      conducted under your username and password. The Concern,
                      its employees or associates will not be responsible in any
                      manner for losses occurring from such breach of secrecy of
                      your username and password.
                    </p>
                    <p className="mb-20">
                      You agree that your sole purpose of registering or using
                      the Website is to buy or sell merchandise or products as
                      may be specifically notified by the Concern on the Website
                      from time to time (that are permitted to be bought and
                      sold under applicable law) and you shall not use this
                      Website for any other purpose including for selling or
                      buying products other than as mentioned above or products
                      that are not allowed under applicable law to be sold or
                      bought by you in any manner.
                    </p>
                    <p className="mb-20">
                      You agree to provide true, accurate and complete
                      information while registering or for any other purpose
                      when prompted to do so on the Website. You are prohibited
                      from misrepresenting your identity and agree not to
                      represent yourself as another User or login/register using
                      the identity of any other person. You are responsible to
                      maintain and promptly update the information provided
                      while registering or for any other purpose on the Website
                      to ensure that the information provided by you is true,
                      accurate, current and complete at all times. If you
                      provide any information that is untrue, inaccurate, not
                      current or incomplete or the Concern has reasonable
                      grounds to deduce that such information is untrue,
                      inaccurate, not current or incomplete, or not in
                      accordance with this User Agreement, the Concern reserves
                      the right to indefinitely suspend or terminate or block
                      your use or access to the Website in any manner
                      whatsoever.
                    </p>
                    <p className="mb-20">
                      <u>Seller Registration:</u>To be eligible to sell items on
                      the Website, the User is required to separately register
                      with the Website of the Concern and a Payment Gateway
                      Facility. The seller shall provide complete details of the
                      Seller Bank Account as a part of the Payment Gateway
                      Seller Registration or at a later date in lieu of or in
                      addition to the original Seller’s Bank Account.
                    </p>
                    <p className="mb-20">
                      <u>Buyer Registration:</u>Onetime registration as User by
                      providing the email ID, is required for purchase of any
                      merchandise from our site.
                    </p>
                    <p className="mb-20">
                      <u>Electronic Communication:</u>The Concern communicates
                      with the User through electronic means. You agree to keep
                      yourself updated with all data, information and
                      communication pertaining to you made available on the
                      Website by the Concern. You further agree that your use of
                      the Website or provision of any data or information
                      including any correspondence (by email or otherwise) to or
                      by the Concern is through electronic records and you
                      consent to receive communication from the Concern via
                      electronic records, which will be deemed adequate service
                      of notice/electronic record.
                    </p>

                    <h2 className="mb-20">Terms of Use</h2>

                    <p className="mb-20">
                      The Users understand and agree that the Concern and the
                      Website merely provide hosting services to its Users who
                      access the Website for purchase and sale of the products
                      that are notified in the Website and that are permitted to
                      be bought and sold under applicable law. You also give
                      permission to the Website and the Concern to store details
                      and records of your usage of the Website indefinitely.
                      However, this does not constitute any obligation on the
                      part of the Concern or the Website to do so.
                    </p>
                    <p className="mb-20">
                      The membership on the Website is free and the Concern does
                      not levy any charges/fees for browsing or buying on the
                      Website. However, for the purposes of listing any item on
                      the Website to be sold or availing other services provided
                      by the Concern through the Website such as packing,
                      warehousing, courier, etc., the Seller will be charged
                      Facility Charges incorporated herein by reference. The
                      Concern reserves the right to introduce new services or
                      modify the existing services provided on the Website.
                      Additionally, the Concern at its sole discretion may
                      introduce fees for the new services provided or amend/
                      introduce fees for the existing services, as the case may
                      be. Changes to the User Agreement or any of the rules and
                      policies of the Concern shall be posted on the Website and
                      such changes shall automatically become effective
                      immediately after they are posted on the Website.
                    </p>
                    <p className="mb-20">
                      You are responsible for paying all charges/fees associated
                      with the use of the Website and shall be liable to pay any
                      and all applicable taxes, charges, cesses etc. which may
                      be levied.All fees/charges shall be quoted in Indian
                      Rupees and shall be payable to Keralaspecial within such
                      time as specified in the invoice. In case of any
                      non-payment, the Concern reserves the right to issue a
                      warning or temporarily/indefinitely suspend or terminate
                      your membership with the Website and disallow access to
                      the Website. The Concern also reserves the right to take
                      any legal action against you in case of any non-payment of
                      charges/fees to the Concern.
                    </p>
                    <p className="mb-20">
                      If you buy anything on the Website, you agree to also
                      comply with the terms and conditions set out in the Policy
                      for Users incorporated herein by reference.
                    </p>
                    <p className="mb-20">
                      If you propose to sell any product on the Website, you
                      agree to also comply with the terms and conditions set out
                      in the Policy for Sellers incorporated herein by
                      reference.
                    </p>
                    <p className="mb-20">
                      The Concern will have the right to appoint any third party
                      service provider, including but not restricted to one or
                      more of the Concern’s affiliate to provide backend
                      operations and support as instructed by the Concern from
                      time to time including but not limited to collection,
                      processing and remittance of the Transaction Price using
                      the existing authorized banking infrastructure to provide
                      enabling support facility for collection and remittance of
                      payment including but not limited to the Logistics
                      Partner.
                    </p>
                    <p className="mb-20">
                      The Concern neither originates nor transmits any
                      communication/information on behalf of any User nor does
                      it modify the contents of any communication transmitted.
                      The Concern has no control over third parties and contents
                      generated by the Users on the Website.
                    </p>
                    <p className="mb-20">
                      The Concern is not responsible for information provided by
                      you to the Concern or submitted on the Website or provided
                      or displayed to other Users of the Website in the
                      registration, buying or listing process, in the feedback
                      area or through any e-mail communication. For all such
                      acts you shall be solely responsible and the Concern or
                      the Website merely is a platform where such information is
                      distributed, published, displayed or used by Users. The
                      Concern or the Website is not liable for accuracy,
                      appropriateness or legality of such information.
                    </p>
                    <p className="mb-20">
                      Kitsor Pvt Ltd (Keralaspecial) has made every effort to
                      display as accurately as possible the colours and images
                      of our products that appear at the store. We cannot
                      guarantee that your computer monitor’s display of any
                      colour will be accurate.
                    </p>

                    <h2 className="mb-20">User Obligations</h2>
                    <p className="mb-20">
                      The Users will treat the Website as a mere platform by
                      Users to create listings or provide information for the
                      purpose of selling their items so that such information
                      can be discovered and read by other Users of the Website
                      who may wish to purchase such items from other Users or
                      provide feedback on items they have purchased or sellers
                      who have sold any item to them.
                    </p>
                    <p className="mb-20">
                      The Users agree and undertake not to host, display,
                      upload, modify, publish, transmit, update or share any
                      information or list any information or item that:
                    </p>
                    <p className="mb-20">
                      Belongs to another person and to which You do not have any
                      right to; is grossly harmful, harassing, blasphemous,
                      defamatory, obscene, pornographic, pedophilic, libelous,
                      invasive of another’s privacy, hateful, or racially,
                      ethnically objectionable, disparaging, relating or
                      encouraging money laundering or gambling, or otherwise
                      unlawful in any manner whatever; or unlawfully threatening
                      or unlawfully harassing including but not limited to
                      “indecent representation of women” within the meaning of
                      the Indecent Representation of Women (Prohibition) Act,
                      1986; Impersonate another person or use an anonymous
                      proxy; Harm minors in any way; infringes any patent,
                      trademark, copyright or other proprietary rights or third
                      party’s trade secrets or rights of publicity or privacy or
                      shall not be fraudulent or involve the sale of counterfeit
                      or stolen items; violates any law for the time being in
                      force; deceives or misleads the addressee/users about the
                      origin of such messages or communicates any information
                      which is grossly offensive or menacing in nature; Contains
                      software viruses or any other computer code, files or
                      programs designed to interrupt, destroy or limit the
                      functionality of any computer resource; or involve in any
                      malicious activity that come within the purview of cyber
                      laws that exist from time to time in India and abroad.
                      Threatens the unity, integrity, defence, security or
                      sovereignty of India, friendly relations with foreign
                      states, or public order or causes incitement to the
                      commission of any cognizable offence or prevents
                      investigation of any offence or is insulting any other
                      nation; Shall not be false, inaccurate or misleading;
                      Shall not, directly or indirectly, offer, attempt to
                      offer, trade or attempt to trade in any item, the dealing
                      of which is prohibited or restricted in any manner under
                      the provisions of any applicable law, rule, regulation or
                      guideline for the time being in force; May give rise to
                      liability on part of the Website or the Concern or cause
                      any hindrance (in whole or in part) with respect to the
                      services of ISPs or other suppliers of the Website or the
                      Concern; and Link directly or indirectly to or include
                      descriptions of items that are (i) prohibited under the
                      User Agreement or any other applicable law for the time
                      being in force including but not limited to the Drugs and
                      Cosmetics Act, 1940, the Drugs And Magic Remedies
                      (Objectionable Advertisements) Act, 1954, the Indian Penal
                      Code, 1860, Information Technology Act 2000 as amended in
                      2008 and from time to time and rules there under or (ii)
                      are at the same time listed for sale on a web site other
                      than the Website. You shall not promote any website or
                      webpage or link on the Website. In case you choose to
                      provide feedback on the Website, which is visible to other
                      users, you shall exercise due care while making comments
                      and not make any comments that are not factual in nature
                      and shall not post defamatory or illegal or
                      offensive/obscene contents. If there is any violation of
                      the above provisions, the Concern has the right to
                      immediately terminate the access or usage rights of the
                      user to the Website without any notice and any such
                      violative information that is displayed or submitted on
                      the Website can be removed immediately. You shall be
                      responsible for keeping backup versions of the information
                      and data provided by you. You hereby agree that you will
                      not expect the Website to restore or keep back up of your
                      information and data and not hold the Website or the
                      Concern accountable for any loss of data in any
                      circumstances. You shall not, either alone or in
                      conjunction with other users, manipulate or attempt to
                      manipulate the prices of any item being sold or purchased
                      on the Website. You will also refrain from accessing
                      information or databases in an unauthorized manner from
                      the Website or servers where information or databases are
                      kept. You shall not attempt to or circumvent or manipulate
                      any of the obligations conferred on you by this User
                      Agreement. If such attempt is discovered, it will
                      constitute sufficient ground for termination of access to
                      the Website and also for taking appropriate legal action.
                      In case of any transaction or attempted transaction
                      pertaining to any item listed on the Website, which is
                      violative of this User Agreement or applicable laws comes
                      to your knowledge, you shall forthwith take all steps to
                      inform the Concern of such violation at
                      info@keralaspecial.in You undertake not to disclose or
                      distribute any other User’s Information to a third party,
                      or use the Information for any unauthorized purpose
                      including for the purposes of marketing unless you have
                      obtained the User’s express consent to do so. You shall
                      not place any advertisements on the Website in any manner
                      without the knowledge of and consent from Kitsor Pvt Ltd (
                      Keralaspecial). Further, you shall not use the Website to
                      promote your own or any other person’s business or
                      interests on the Website except for providing description
                      on a listing for a specific item, unless permitted by the
                      Concern in writing. You shall not attempt to ‘double dip’
                      during the course of a dispute by receiving or attempting
                      to receive funds from the Concern and/or its service
                      providers and/or the buyer or seller as the case maybe.
                    </p>

                    <h2 className="mb-20">Disclaimers</h2>
                    <p className="mb-20">
                      The Website is only a platform where users may meet and
                      interact with one another for their transactions. The
                      Website or the Concern is not and cannot be a party to or
                      control in any manner any transaction between two users of
                      the Website.
                    </p>
                    <p className="mb-20">
                      All commercial/contractual terms are offered by and agreed
                      to between buyers and sellers alone as per principal to
                      principal bipartite contractual obligations. The
                      commercial/contractual terms include without limitation
                      price, shipping costs, payment methods, payment terms,
                      date, period and mode of delivery, warranties related to
                      items listed for sale.
                    </p>
                    <p className="mb-20">
                      The Concern is neither involved in the buying and selling
                      of items on the Website nor liable or responsible for any
                      non- performance or breach of any contract entered into
                      between the Users (i.e. buyer and seller) including but
                      not limited to non-delivery or non-receipt, non-payment,
                      damage, breach of representations and warranties provided
                      by the seller or any fraud as regards the items listed on
                      the Website. The Users acknowledge that the Concern will
                      not be liable for any damages, interests or claims, etc.
                      resulting from not processing or any delay in processing a
                      Transaction/Transaction Price which is beyond the control
                      of the Concern. The Concern shall not and is not required
                      to mediate or resolve any dispute or disagreement between
                      buyers and sellers.
                    </p>
                    <p className="mb-20">
                      The Concern does not make any representation or warranty
                      as to the attributes (such as quality, worth,
                      marketability, merchantability, usefulness) of the items
                      proposed to be sold or offered to be sold or purchased on
                      the Website. In particular, the Concern does not
                      implicitly or explicitly support or endorse the sale or
                      purchase of any items on the Website. The Concern shall
                      not be liable for any errors or omissions, whether on
                      behalf of itself or third parties.
                    </p>
                    <p className="mb-20">
                      The Concern does not make any representation or warranty
                      as to the attributes (such as legal title,
                      creditworthiness, identity, etc.) of any of its Products .
                      You are advised to use your best judgment and
                      independently verify the bona fides of any particular User
                      that you choose to deal with on the Website.
                    </p>
                    <p className="mb-20">
                      The Website is only a venue through which Users can reach
                      a larger base to buy and sell unique products. At no time
                      shall any right, title or interest over the items vest
                      with the Concern nor shall the Concern have any
                      obligations or liabilities in respect of such contract.
                      The Concern is not responsible for unsatisfactory or
                      delayed performance of sellers or damages or delays as a
                      result of items being out of stock, back ordered or
                      otherwise unavailable. All items offered by sellers are
                      only for a restricted time and only for the available
                      supply as offered by sellers.
                    </p>
                    <p className="mb-20">
                      The Concern and its suppliers, affiliates and service
                      providers make available the Website and services
                      including the Kitsor Pvt Ltd (Keralaspecial)
                      Logistics,Payment Gateway facility for electronic payment
                      on an “as is” basis and without any warranty or condition,
                      express, implied or statutory and specifically disclaim
                      any implied warranties of title, merchantability, fitness
                      for a particular purpose and non-infringement. The Concern
                      specifically disclaims any such warranty. You expressly
                      agree that the use of the Website and the Payment Gateway
                      electronic payment facility is at your own risk.
                    </p>
                    <p className="mb-20">
                      Please note that Ayurvedic medicines should only be
                      purchased based on a doctor's prescription or
                      recommendation. For guidance on dosage and frequency of
                      use, please consult with an Ayurvedic doctor or healthcare
                      professional.
                    </p>

                    <h2 className="mb-20">Indemnification</h2>
                    <p className="mb-20">
                      You agree to indemnify, defend and hold harmless Kitsor
                      Pvt Ltd (Keralaspecial) subsidiaries, affiliates,
                      partners, officers, directors, agents, contractors,
                      licensors, service providers, subcontractors, suppliers,
                      interns and employees, from any claim or demand, including
                      reasonable attorneys’ fees, made by any third-party due to
                      or arising out of your breach of these Terms and
                      Conditions or the documents they incorporate by reference,
                      or your violation of any law or the rights of a
                      third-party.
                    </p>
                    <p className="mb-20">
                      You release and indemnify the Concern and/or any of its
                      officers and representatives from any cost, damage,
                      liability or other consequence of any of the actions of
                      the users of the Website and specifically waive any claims
                      that you may have in this behalf under any applicable law.
                      The Concern cannot control the information provided by
                      other Users, which is made available on the Website
                      notwithstanding the Concern’s reasonable efforts in that
                      behalf. You may find other User’s information to be
                      offensive, harmful, inaccurate, or deceptive. Please use
                      caution and practice safe trading when using the Website.
                      Please note that there may be risks in dealing with
                      foreign nationals, underage persons or people acting under
                      false pretense.
                    </p>

                    <h2 className="mb-20">Limited liability of the Concern</h2>
                    <p className="mb-20">
                      In no event shall the Concern or its suppliers, affiliates
                      and service providers be liable for any direct, indirect,
                      incidental, special, consequential or exemplary damages,
                      including but not limited to, damages for loss of profits,
                      goodwill, use, data or other intangible losses arising (in
                      any manner whatsoever, including but not limited to
                      negligence) out of or in connection with the Website, the
                      Payment Gateway Facility, services provided by the
                      Logistics Partner or any other services or this User
                      Agreement.
                    </p>
                    <p className="mb-20">
                      The concerned liability in any circumstance is limited to
                      the amount of charges/fees, if any, paid by you to the
                      Concern. The Concern, its associates, affiliates and
                      service providers and technology partners make no
                      representations or warranties about the accuracy,
                      reliability, completeness, and/or timeliness of any
                      content, information, software, text, graphics, links or
                      communications provided on or through the use of the
                      Website or that the operation of the Website or the
                      Logistics Partner or Payment Gateway Facility will be
                      error free and/or uninterrupted. The Concern provides no
                      guarantee to its Users in respect of the products sold on
                      the Website. Consequently, the Concern assumes no
                      liability whatsoever for any monetary or other damage
                      suffered by you on account of:
                    </p>
                    <p className="mb-20">
                      The delay, failure, interruption, or corruption of any
                      data or other information transmitted in connection with
                      use of the Website or Payment Gateway Facility.
                    </p>
                    <p className="mb-20">
                      Any delay, failure, interruption or errors in the
                      operation of the Website or the Logistics Partner or
                      Payment Gateway Facility.
                    </p>

                    <h2 className="mb-20">Payment</h2>
                    <p className="mb-20">
                      Payments for the items on the Website, at the option of
                      the relevant seller, can be made by way of (i) Payment on
                      Billing which can be made through the payment Gateway
                      Facility or (ii) Cash on Delivery wherein payment shall be
                      made only by cash on delivery, or such other method of
                      payment as may be permitted by the Concern on the Website
                      in its sole discretion.
                    </p>
                    <p className="mb-20">
                      For the purposes of buying and/or selling any item listed
                      on the Website, you agree and undertake not to make
                      payments in any manner other than as provided, without the
                      prior consent of the Concern.
                    </p>
                    <p className="mb-20">
                      You acknowledge and accept that you have specifically
                      authorized the Concern to collect, process, facilitate and
                      remit payments and/or the Transaction Price by any of the
                      prescribed methods of payment through Payment on Billing
                      or Payment on Delivery to and from other Users in respect
                      of Transactions.
                    </p>
                    <p className="mb-20">
                      In order to enable Users to carry out transactions on the
                      Website, the Concern will in addition to other methods of
                      payment provide an electronic payment facility, i.e.
                      payment Gateway Facility. The payment Gateway Facility
                      enables automated collection and remittance services using
                      the facilities of various Indian banks, financial
                      institutions, credit/debit/cash card brands and through
                      such other infrastructure and facilities as may be
                      authorized by the Reserve Bank of India for collection,
                      refund and remittance, as the case may be. The payment
                      Gateway Facility shall be availed in accordance with the
                      terms of this User Agreement and the rules and policies
                      prescribed hereunder.
                    </p>
                    <p className="mb-20">
                      The Concern shall make reasonable efforts to ensure that
                      requests for electronic debits and credits involving
                      Issuing Bank are informed to the Nodal Bank (defined
                      below) in a timely manner. However, a number of factors
                      that are outside of the Concern’s control (including
                      without limitation actions of Issuing Bank or Nodal Bank
                      or indirectly through payment gateway facility providers
                      or through any such facility authorized by the Reserve
                      Bank of India to provide enabling support facility for
                      collection and remittance of payment where the Transaction
                      Price is remitted and/or refunded) may delay the time
                      within which the Transaction Price are collected/remitted
                      by the Concern through the payment Gateway Facility. The
                      Concern neither makes any representations nor makes any
                      warranties regarding the amount of time needed to complete
                      processing, including delays in the banking system and nor
                      shall the Concern be liable for any actual or
                      consequential damages arising from any claim of delay.
                    </p>
                    <p className="mb-20">
                      The Concern has appointed , Union Bank, Thycaud Branch,
                      Thiruvananthapuram, Kerala, India and operating in India
                      and abroad, as the Nodal Bank. The Concern reserves the
                      right to, at its sole discretion, replace or remove any
                      nodal bank (Nodal Bank) without providing any intimation.
                    </p>

                    <h2 className="mb-20">Breach</h2>
                    <p className="mb-20">
                      Without limiting other remedies that the Concern may
                      pursue, the Concern may at its sole discretion take such
                      action as it deems fit including but not limited to
                      cancellation of the Transaction or payments made, limit
                      your activity, immediately remove your information or
                      listings, or end your listing, warn other Users of your
                      actions, forthwith temporarily/indefinitely suspend or
                      terminate or block your membership, and/or refuse to
                      provide you with access to the Website or initiate any
                      legal action it may deem fit, particularly in the event:
                    </p>
                    <p className="mb-20">
                      You breach any of the provisions of this User Agreement
                      including any of the rules and policies, documents, terms
                      and conditions made thereunder which are incorporated
                      therein by reference;
                    </p>
                    <p className="mb-20">
                      Any misuse of the payment Gateway Facility
                    </p>
                    <p className="mb-20">
                      The Concern is unable to verify or authenticate any
                      information provided by you;
                    </p>
                    <p className="mb-20">
                      The Concern believes that your actions may cause legal
                      liability to the Concern, other Users or yourself.
                    </p>
                    <p className="mb-20">
                      No actions, omissions, or decisions taken by the Concern
                      shall waive any rights or claims that the Concern may have
                      against the User.
                    </p>
                    <p className="mb-20">
                      Any User that may have been suspended or blocked may not
                      register or attempt to register with the Website or use
                      the Website in any manner whatsoever until such time that
                      such User is reinstated by the Concern. Notwithstanding
                      the above, if you breach the User Agreement or the rules
                      and policies and other documents incorporated therein by
                      reference, the Concern reserves the right to recover any
                      amounts due and owed by you to the Concern and to take
                      strict legal action including but not limited to referral
                      to the appropriate police or other authorities for
                      initiating criminal or other proceedings against you.
                    </p>

                    <h2 className="mb-20">Grievance Redressal Mechanism</h2>
                    <p className="mb-20">
                      In case of any grievance, objection, or complaint on your
                      part with respect to the Website, other Users, payment
                      Gateway Facility, or the Concern, including any complaints
                      or enquiry about suspension, termination, or blocking of
                      your membership or right to use the Website, you should
                      promptly raise such grievance or complaint with the
                      designated Grievance Officer at info@keralaspecial.in and
                      provide him with all necessary information and/or
                      documents to enable the Concern/Grievance Officer to
                      resolve the issue.
                    </p>
                    <p className="mb-20">
                      The name and contact details of the Grievance Officer is
                      published on the Website as required under the provisions
                      of the Information Technology Act, 2000, and The
                      Information Technology (Intermediaries Guidelines) Rules,
                      2011.
                    </p>

                    <h2 className="mb-20">General</h2>
                    <p className="mb-20">
                      The User shall be responsible for obtaining and
                      maintaining computer hardware, telephone and other
                      equipment needed for access to and use of the Site and all
                      charges related thereto. The Company shall not be liable
                      for any damages to the User’s equipment resulting from the
                      use of the Site.
                    </p>
                    <p className="mb-20">
                      None of the provisions of this User Agreement shall be
                      deemed to constitute a partnership or agency between you
                      and the Concern and you shall have no authority to bind
                      the Concern in any manner whatsoever.
                    </p>
                    <p className="mb-20">
                      Except as explicitly stated otherwise, any notices
                      directed to the Concern shall be given by email to
                      info@keralaspecial.in and any notices to you by the
                      Concern shall be provided to the email address provided by
                      you during the registration process. Notice shall be
                      deemed given 24 hours after the email is sent, unless the
                      sending party is notified that the email address is
                      invalid. Alternatively, we may give you notice by
                      registered mail, postage prepaid and return receipt
                      requested, to the address provided to us during the
                      registration process. In such case, notice shall be deemed
                      given 3 days after the date of mailing.
                    </p>
                    <p className="mb-20">
                      In case any clause of this User Agreement or the
                      application thereof to any User or circumstance is deemed
                      invalid, void or for any reason unenforceable to any
                      extent, the remainder of this User Agreement and the
                      application of such unenforceable provision to Users or
                      circumstances other than those as to which it is held
                      invalid or unenforceable shall not be affected thereby,
                      and each provision of this User Agreement shall be valid
                      and enforceable to the fullest extent permitted by law.
                    </p>
                    <p className="mb-20">
                      This User Agreement together with the rules and policies
                      incorporated herein by reference constitutes the entire
                      understanding and agreement between You and the Concern
                      with respect to the subject matter herein.
                    </p>
                    <p className="mb-20">
                      The Concern at its sole discretion shall be entitled to
                      assign or transfer its rights and obligations under this
                      User Agreement hereunder to any other person without your
                      prior consent provided that the Concern assigns this User
                      Agreement on the same terms or such terms that are no less
                      satisfying to you.
                    </p>
                    <p className="mb-20">
                      All remedies of the Concern under this User Agreement
                      whether provided herein or conferred by statute, civil
                      law, common law, custom or trade usage, are cumulative and
                      not alternative and may be enforced successively or
                      concurrently.
                    </p>
                    <h2 className="mb-20">Arbitration</h2>
                    <p className="mb-20">
                      If any dispute arises between you and the Concern during
                      your use of the Website or the Payment Gateway Facility or
                      other Keralaspecial Services or any service incidental to
                      the Website or thereafter, in connection with the
                      validity, interpretation, implementation or alleged breach
                      of any provision of the User Agreement and/or Payment
                      Gateway Agreement, or the rules, policies and documents
                      incorporated therein by reference, the dispute shall be
                      referred to a sole arbitrator who shall be an independent
                      and neutral third party identified by the Concern whose
                      decision shall be final. The place of arbitration shall be
                      Thiruvananthapuram, Kerala, India. The Arbitration &
                      Conciliation Act, 1996, shall govern the arbitration
                      proceedings. The arbitration proceedings shall be in the
                      English language.
                    </p>

                    <h2 className="mb-20">Governing Law</h2>
                    <p className="mb-20">
                      These Terms and Conditions and, all rules, policies and
                      documents incorporated by reference shall be governed and
                      construed in accordance with the laws of India and the
                      Courts in Thiruvananthapuram, Kerala, India shall have
                      exclusive jurisdiction.
                    </p>
                    <p className="mb-20">
                      This document is an electronic record published in
                      accordance with provisions of Information Technology Act,
                      2000, The Information Technology (Intermediaries
                      Guidelines) Rules, 2011 and the amended provisions
                      pertaining to electronic records in various statutes. This
                      electronic record is generated by a computer system and
                      does not require any physical or digital signatures.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainContainer>
      </main>
    </>
  );
}
export default Policy;
const MainContainer = styled.div`
  h2 {
    font-weight: 600;
  }
  li {
    list-style-type: none;
    padding: 10px 0;
    font-size: 16px;
  }
`;
