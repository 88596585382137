//function to trim description to 10 words
function trimDescriptionToTen(description, wordCount = 10) {
  const words = description.split(" ");
  if (words.length > wordCount) {
    return words.slice(0, wordCount).join(" ") + "...";
  }
  return description;
}
//function to trim description to 20 words
function trimDescriptionToTwenty(description, wordCount = 20) {
  const words = description.split(" ");
  if (words.length > wordCount) {
    return words.slice(0, wordCount).join(" ") + "...";
  }
  return description;
}
//export
module.exports = {
  trimDescriptionToTen,
  trimDescriptionToTwenty,
};
