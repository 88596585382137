import React, { useEffect, useState } from 'react';
import ScrollToTop from '../../../components/whatsapp connect';
import { backend } from "../../../../path";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import LoaderMain from '../../../layout/header/loader_sub';
function PasswordReset() {
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [emailError, setEmailError] = useState('');
  const [otpError, setOTPError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [activeState, setActiveState] = useState(0);
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate()

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError('');
  };
  const handleOTP = (event) => {
    setOTP(event.target.value);
    setOTPError('');
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPassword = (event) => {
    setPasswordConfirm(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Email validation
    if (!email.trim()) {
      setEmailError('Email address is required');
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Invalid email address');
      return;
    }

    setIsLoading(true); // Set loading to true when the request starts

    fetch(backend + "resetpassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({ email: email })
    })
      .then((response) => response.json().then((data) => {
        setIsLoading(false); // Set loading to false when the request finishes

        if (response.ok) {
          toast.success("OTP has been sent to registered mail", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setActiveState(1);
        } else {
          console.error("Failed to Login:", response.status, response.statusText);

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            toast.error("Invalid Email", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Invalid Email", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      }))
      .catch((error) => {
        setIsLoading(false); // Set loading to false if an error occurs
        console.error("Error during login:", error);
        toast.error("Invalid Email", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSubmitOTP = (event) => {
    event.preventDefault();

    // Email validation
    if (!otp.trim()) {
      setEmailError('OTP is required');
      return;
    }
    setIsLoading(true);
    fetch(backend + "checkotp", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({ otp: otp, email: email })
    })
      .then((response) => {
        setIsLoading(false);
        response.json().then((data) => {
        });
        if (response.ok) {
          setActiveState(2)
        }
        if (!response.ok) {
          console.error(
            "Failed to Login:",
            response.status,
            response.statusText
          );


          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            toast.error("Invalid OTP", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Invalid OTP", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
        toast.error("Invalid OTP", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleSubmitPassword = (event) => {
    event.preventDefault();

    // Email validation
    if (!password.trim()) {
      setEmailError('Password is required');
      return;
    }
    if (password !== passwordConfirm) {
      toast.error("Password Unmatched, Try Again", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    fetch(backend + "updatepassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({ password: password, email: email })
    })
      .then((response) => {
        response.json().then((data) => {
        });
        if (response.ok) {
          toast.success("Your Password Has Been Reset Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate('/login')
        }
        if (!response.ok) {
          console.error(
            "Failed to Login:",
            response.status,
            response.statusText
          );


          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            toast.error("Error Occured", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Error Occured", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
        toast.error("Error Occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <ToastContainer />
      {isLoading &&
        <LoaderMain />
      }
      <main className="main" id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  Home
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Login </div>
              </div>
            </div>
          </div>
        </div>
        <section className="pt-100 pb-100">
          <div className="container">
            {activeState === 0 ?
              <div className="row">
                <div className="col-lg-6 m-auto">
                  <div className="login_wrap widget-taber-content p-30 background-white border-radius-10">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1 mb-20">
                        <h3 className="mb-30">Forgot Password</h3>
                      </div>
                      <form className="form--auth form--login" onSubmit={handleSubmit}>
                        <div className="form__content">
                          <div className="form-group">
                            <label htmlFor="txt-email" className="required">
                              Email Address
                            </label>
                            <input
                              className="form-control"
                              name="email"
                              id="txt-email"
                              type="email"
                              value={email}
                              onChange={handleEmailChange}
                              placeholder="Please enter your email address"
                            />
                            {emailError && <div className="text-danger">{emailError}</div>}
                          </div>
                        </div>
                        <div className="form-group">
                          <button type="submit" className="btn btn-fill-out btn-block hover-up">
                            Send Password Reset OTP
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              : activeState === 1 ?
                <div className="row">
                  <div className="col-lg-6 m-auto">
                    <div className="login_wrap widget-taber-content p-30 background-white border-radius-10">
                      <div className="padding_eight_all bg-white">
                        <div className="heading_s1 mb-20">
                          <h3 className="mb-30">Enter OTP</h3>
                        </div>
                        <form className="form--auth form--login" onSubmit={handleSubmitOTP}>
                          <div className="form__content">
                            <div className="form-group">
                              <input
                                className="form-control"
                                name="email"
                                id="txt-email"
                                type="text"
                                value={otp}
                                onChange={handleOTP}
                                placeholder="Please enter your OTP here"
                              />
                              {otpError && <div className="text-danger">{otpError}</div>}
                            </div>
                          </div>
                          <div className="form-group">
                            <button type="submit" className="btn btn-fill-out btn-block hover-up">
                              Verify OTP
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="row">
                  <div className="col-lg-6 m-auto">
                    <div className="login_wrap widget-taber-content p-30 background-white border-radius-10">
                      <div className="padding_eight_all bg-white">
                        <div className="heading_s1 mb-20">
                          <h3 className="mb-30">Set Password</h3>
                        </div>
                        <form className="form--auth form--login" onSubmit={handleSubmitPassword}>
                          <div className="form__content">
                            <div className="form-group">
                              <input
                                className="form-control"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                onChange={handlePassword}
                                placeholder="Enter your new password"
                                value={password}
                              />
                              <div className="position-relative">
                                <div
                                  className=" position-absolute password-toggle-icon"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={32}
                                      height={32}
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="#3BB77E"
                                        d="m63.998 86.005l21.998-21.998L447.999 426.01l-21.998 21.998zM259.34 192.09l60.57 60.57a64.07 64.07 0 0 0-60.57-60.57m-6.68 127.82l-60.57-60.57a64.07 64.07 0 0 0 60.57 60.57"
                                      ></path>
                                      <path
                                        fill="#45B755"
                                        d="M256 352a96 96 0 0 1-92.6-121.34l-69.07-69.08C66.12 187.42 39.24 221.14 16 256c26.42 44 62.56 89.24 100.2 115.18C159.38 400.92 206.33 416 255.76 416A233.47 233.47 0 0 0 335 402.2l-53.61-53.6A95.84 95.84 0 0 1 256 352m0-192a96 96 0 0 1 92.6 121.34L419.26 352c29.15-26.25 56.07-61.56 76.74-96c-26.38-43.43-62.9-88.56-101.18-114.82C351.1 111.2 304.31 96 255.76 96a222.92 222.92 0 0 0-78.21 14.29l53.11 53.11A95.84 95.84 0 0 1 256 160"
                                      ></path>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={32}
                                      height={32}
                                      viewBox="0 0 1024 1024"
                                    >
                                      <path
                                        fill="#3BB77E"
                                        d="M396 512a112 112 0 1 0 224 0a112 112 0 1 0-224 0m546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3c7.7-16.2 7.7-35 0-51.5M508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176s176 78.8 176 176s-78.8 176-176 176"
                                      ></path>
                                    </svg>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <input
                                className="form-control"
                                type={showPasswordConfirm ? "text" : "password"}
                                name="password_confirmation"
                                id="txt-password-confirmation"
                                placeholder="Re-enter your new password"
                                onChange={handleConfirmPassword}
                                value={passwordConfirm}
                              />
                              <div className="position-relative btn-block hover-up">
                                <div
                                  className=" position-absolute password-toggle-icon"
                                  onClick={() =>
                                    setShowPasswordConfirm(!showPasswordConfirm)
                                  }
                                >
                                  {showPasswordConfirm ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={32}
                                      height={32}
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="#3BB77E"
                                        d="m63.998 86.005l21.998-21.998L447.999 426.01l-21.998 21.998zM259.34 192.09l60.57 60.57a64.07 64.07 0 0 0-60.57-60.57m-6.68 127.82l-60.57-60.57a64.07 64.07 0 0 0 60.57 60.57"
                                      ></path>
                                      <path
                                        fill="#45B755"
                                        d="M256 352a96 96 0 0 1-92.6-121.34l-69.07-69.08C66.12 187.42 39.24 221.14 16 256c26.42 44 62.56 89.24 100.2 115.18C159.38 400.92 206.33 416 255.76 416A233.47 233.47 0 0 0 335 402.2l-53.61-53.6A95.84 95.84 0 0 1 256 352m0-192a96 96 0 0 1 92.6 121.34L419.26 352c29.15-26.25 56.07-61.56 76.74-96c-26.38-43.43-62.9-88.56-101.18-114.82C351.1 111.2 304.31 96 255.76 96a222.92 222.92 0 0 0-78.21 14.29l53.11 53.11A95.84 95.84 0 0 1 256 160"
                                      ></path>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={32}
                                      height={32}
                                      viewBox="0 0 1024 1024"
                                    >
                                      <path
                                        fill="#3BB77E"
                                        d="M396 512a112 112 0 1 0 224 0a112 112 0 1 0-224 0m546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3c7.7-16.2 7.7-35 0-51.5M508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176s176 78.8 176 176s-78.8 176-176 176"
                                      ></path>
                                    </svg>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <button type="submit" className="btn btn-fill-out btn-block hover-up">
                              Reset Password
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
            }

            <ScrollToTop />
          </div>
        </section>
      </main>
    </>
  );
}

export default PasswordReset;
