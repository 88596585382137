// export const backend = "http://127.0.0.1:8000/api/";


export const categoryurl =
  "https://keralaspecial.in/server/public/upload/category/";
export const producturl ="https://keralaspecial.in/server/public/upload/products/";
export const billurl ="https://keralaspecial.in/server/public/upload/bill/";
export const vendorurl ="https://keralaspecial.in/server/public/upload/vendor/";
export const blogurl ="https://keralaspecial.in/server/public/upload/blog/";
export const carouselurl ="https://keralaspecial.in/server/public/upload/carousel/";
export const backend = "https://keralaspecial.in/server/public/api/";
export const mediaurl = "https://keralaspecial.in/upload/vendor/";