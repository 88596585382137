import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend, mediaurl } from "../../../path";
import DashboardSidebarMain from "../dashsidebar";
import Pagination from "../../components/pagination";
function BulkMail() {
  const [formData, setFormData] = useState({  });

  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [view, setView] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(40);

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  
  const fetchData = () => {
    setLoading(true);

    fetch(backend + "getuser?page_number=" + pageNumber + "&page_size=" + pageSize, {
      method: "POST",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, pageSize]);


  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUserIds([]);
      setSelectAll(false);
    } else {
      const allUserIds = data?.data?.map((item) => item.user_id);
      setSelectedUserIds(allUserIds);
      setSelectAll(true);
    }
  };

  const handleCheckboxChange = (userId) => {
    if (selectedUserIds.includes(userId)) {
      setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
    } else {
      setSelectedUserIds([...selectedUserIds, userId]);
    }
  };

  const handleSubmit = () => {
  // Extract the emails of the selected users
  const selectedEmails = data?.data
    ?.filter(user => selectedUserIds.includes(user.user_id))
    ?.map(user => user.email);

    formData.emails = JSON.stringify(selectedEmails);

    fetch(backend + "sendoffermail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");

          // Additional success handling code here
          toast.success(" Mail Send", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        setView(0);
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handlePageClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };



  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            <DashboardSidebarMain />
          </div>
          <div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="padding_eight_all bg-white">
                  <div className="d-flex justify-content-between mb-20 mt-20">
                    <h3 className="">Customers Mail Promotion</h3>
                    <div className="d-flex justify-content-end gap-3">
                      <div>
                        <Icon
                          icon="fluent:mail-add-24-filled"
                          color="green"
                          className="hover-up"
                          fontSize={64}
                          style={{ cursor: "pointer" }}
                          onClick={handleSubmit}
                        />

                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>

                <div className="table-responsive">
                  <table className="table table-secondary table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th scope="col">#</th>
                        <th scope="col">User Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedUserIds.includes(item.user_id)}
                              onChange={() => handleCheckboxChange(item.user_id)}
                            />
                          </td>
                          <td>{i + 1}</td>
                          <td>{item.first_name} {item.last_name}</td>
                          <td>{item.email}</td>
                          <td>
                            {item.status === 0 ? "Available" : "Not Available"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <br></br>
              <Pagination
                currentPage={pageNumber}
                pageSize={pageSize}
                totalItems={data.total}
                onPageChange={handlePageClick}
              />
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
export default BulkMail;
