import React, { useEffect, useState } from "react";
import { backend } from "../../../../path";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { CiLocationOn } from "react-icons/ci";
import { PiDeviceMobileThin } from "react-icons/pi";
import { CiMail } from "react-icons/ci";
import { GrFacebookOption } from "react-icons/gr";
import { LiaInstagram } from "react-icons/lia";
import { AiFillYoutube } from "react-icons/ai";
import { RiTwitterXLine } from "react-icons/ri";

function FooterMainNew() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  const fetchData = () => {
    setLoading(true);
    fetch(backend + "getcategorywithsubcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCategoryMain = (cat) => {
    // setSubcategory(false)
    navigate(`/category/${cat}`);
    // window.location.reload();
  };

  useEffect(() => {
    console.log("footer called");
  }, []);

  return (
    <>
      <div>
        {authenticated?.usertype === 1 ? (
          <Footer>
            <Wrapper>
              <Container>
                <Section>
                  <ImgContainer>
                    <img src="/storage/general/ks-logo32.png" alt="" />
                  </ImgContainer>
                  <P>
                    Shop now to Experience <br /> the Authenticity of Kerala!
                  </P>
                  <Links>
                    <Li>
                      <CiLocationOn />

                      <a>
                        38, Thycaud Elankom Nagar, <br /> Thycaud , Trivandrum
                        695014
                      </a>
                    </Li>
                    <Li>
                      <PiDeviceMobileThin />

                      <a>+91 - 7356629493 , 8891196910</a>
                    </Li>
                    <Li>
                      <CiMail />

                      <a>info@keralaspecial.in</a>
                    </Li>
                  </Links>
                </Section>
                <Section className="links">
                  <List>
                    <Head>Company</Head>
                    <Links>
                      <Li className="list">
                        {" "}
                        <a href="/about-us">About us</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="/contact-us">Contact us</a>
                      </Li>
                    </Links>
                  </List>
                  <List>
                    <Head>Categories</Head>
                    <Links>
                      <Li className="list">
                        {" "}
                        <a href="">Art & crafts</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Handlooms</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Ayurveda</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Snacks</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Spices</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Gift boxes</a>
                      </Li>
                    </Links>
                  </List>
                  <List>
                    <Head>Company</Head>
                    <Links>
                      <Li className="list">
                        {" "}
                        <Link to="/reset-password">Reset Password</Link>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="/contact-us">Contact us</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="/about-us">About us</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="/policy">Terms & Conditions</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="/return-refund">Returns & Refund</a>
                      </Li>
                    </Links>
                  </List>
                </Section>
                <Section className="last">
                  <List>
                    <P>Secured Payment Gateways</P>
                    <ImgContainer className="payment">
                      <img src="/storage/general/payment-methods.png" alt="" />
                    </ImgContainer>
                    <Socials>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/myKeralaspecial?mibextid=qi2Omg&rdid=kccxW9NpVX3BFzrU&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FXM1oRDDkvagcQKW9%2F%3Fmibextid%3Dqi2Omg"
                      >
                        <GrFacebookOption />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/keralaspecial/"
                      >
                        <LiaInstagram />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@keralaspecial5256"
                      >
                        <AiFillYoutube />
                      </a>
                      <a
                        target="_blank"
                        href="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g"
                      >
                        <RiTwitterXLine />
                      </a>
                    </Socials>
                  </List>
                </Section>
              </Container>
            </Wrapper>
          </Footer>
        ) : authenticated?.usertype === 0 ? (
          <Footer>
            <Wrapper>
              <Container>
                <Section>
                  <ImgContainer>
                    <img src="/storage/general/ks-logo32.png" alt="" />
                  </ImgContainer>
                  <P>
                    Shop now to Experience <br /> the Authenticity of Kerala!
                  </P>
                  <Links>
                    <Li>
                      <CiLocationOn />

                      <a
                        href="https://maps.app.goo.gl/KyhXLS1za6zPnXXN9"
                        target="_blank"
                      >
                        38, Thycaud Elankom Nagar, <br /> Thycaud , Trivandrum
                        695014
                      </a>
                    </Li>
                    <Li>
                      <PiDeviceMobileThin />
                      <span>
                        <a href="tel:7356629493">+91 73566 29493 </a>,{" "}
                        <a href="tel:8891196910">+91 88911 96910</a>
                      </span>
                    </Li>
                    <Li>
                      <CiMail />

                      <a href="mailto:info@keralaspecial.in">
                        info@keralaspecial.in
                      </a>
                    </Li>
                  </Links>
                </Section>
                <Section className="links">
                  <List>
                    <Head>Company</Head>
                    <Links>
                      <Li className="list">
                        {" "}
                        <Link to="/about-us">About us</Link>
                      </Li>
                      <Li className="list">
                        {" "}
                        <Link to="/contact-us">Contact us</Link>
                      </Li>
                    </Links>
                  </List>
                  <List>
                    <Head>Categories</Head>
                    <Links>
                      <Li className="list">
                        {" "}
                        <a href="">Art & crafts</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Handlooms</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Ayurveda</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Snacks</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Spices</a>
                      </Li>
                      {/* <Li className="list">
                      {" "}
                      <a href="">Gift boxes</a>
                    </Li> */}
                    </Links>
                  </List>
                  <List>
                    <Head>Company</Head>
                    <Links>
                      <Li className="list">
                        {" "}
                        <Link to="/reset-password">Reset Password</Link>
                      </Li>
                      <Li className="list">
                        {" "}
                        <Link to="/contact-us">Contact us</Link>
                      </Li>
                      <Li className="list">
                        {" "}
                        <Link to="/about-us">About us</Link>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="/policy">Terms & Conditions</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="/return-refund">Returns & Refund</a>
                      </Li>
                    </Links>
                  </List>
                </Section>
                <Section className="last">
                  <List>
                    <P>Secured Payment Gateways</P>
                    <ImgContainer className="payment">
                      <img src="/storage/general/payment-methods.png" alt="" />
                    </ImgContainer>
                    <Socials>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/myKeralaspecial?mibextid=qi2Omg&rdid=kccxW9NpVX3BFzrU&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FXM1oRDDkvagcQKW9%2F%3Fmibextid%3Dqi2Omg"
                      >
                        <GrFacebookOption />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/keralaspecial/"
                      >
                        <LiaInstagram />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@keralaspecial5256"
                      >
                        <AiFillYoutube />
                      </a>
                      <a
                        target="_blank"
                        href="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g"
                      >
                        <RiTwitterXLine />
                      </a>
                    </Socials>
                  </List>
                </Section>
              </Container>
            </Wrapper>
          </Footer>
        ) : (
          <Footer>
            <Wrapper>
              <Container>
                <Section>
                  <ImgContainer>
                    <img src="/storage/general/ks-logo32.png" alt="" />
                  </ImgContainer>
                  <P>
                    Shop now to Experience <br /> the Authenticity of Kerala!
                  </P>
                  <Links>
                    <Li>
                      <CiLocationOn />

                      <a
                        href="https://maps.app.goo.gl/KyhXLS1za6zPnXXN9"
                        target="_blank"
                      >
                        38, Thycaud Elankom Nagar, <br /> Thycaud , Trivandrum
                        695014
                      </a>
                    </Li>
                    <Li>
                      <PiDeviceMobileThin />
                      <span>
                        <a href="tel:7356629493">+91 73566 29493 </a>,{" "}
                        <a href="tel:8891196910">+91 88911 96910</a>
                      </span>
                    </Li>
                    <Li>
                      <CiMail />

                      <a href="mailto:info@keralaspecial.in">
                        info@keralaspecial.in
                      </a>
                    </Li>
                  </Links>
                </Section>
                <Section className="links">
                  <List>
                    <Head>Company</Head>
                    <Links>
                      <Li className="list">
                        {" "}
                        <Link to="/about-us">About us</Link>
                      </Li>
                      <Li className="list">
                        {" "}
                        <Link to="/contact-us">Contact us</Link>
                      </Li>
                    </Links>
                  </List>
                  <List>
                    <Head>Categories</Head>
                    <Links>
                      <Li className="list">
                        {" "}
                        <a href="">Art & crafts</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Handlooms</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Ayurveda</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Snacks</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="">Spices</a>
                      </Li>
                      {/* <Li className="list">
                        {" "}
                        <a href="">Gift boxes</a>
                      </Li> */}
                    </Links>
                  </List>
                  <List>
                    <Head>Company</Head>
                    <Links>
                      <Li className="list">
                        {" "}
                        <Link to="/registration-vendor">
                          Register as vendor
                        </Link>
                      </Li>
                      <Li className="list">
                        {" "}
                        <Link to="/contact-us">Contact us</Link>
                      </Li>
                      <Li className="list">
                        {" "}
                        <Link to="/about-us">About us</Link>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="/policy">Terms & Conditions</a>
                      </Li>
                      <Li className="list">
                        {" "}
                        <a href="/return-refund">Returns & Refund</a>
                      </Li>
                    </Links>
                  </List>
                </Section>
                <Section className="last">
                  <List>
                    <P>Secured Payment Gateways</P>
                    <ImgContainer className="payment">
                      <img src="/storage/general/payment-methods.png" alt="" />
                    </ImgContainer>
                    <Socials>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/myKeralaspecial?mibextid=qi2Omg&rdid=kccxW9NpVX3BFzrU&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FXM1oRDDkvagcQKW9%2F%3Fmibextid%3Dqi2Omg"
                      >
                        <GrFacebookOption />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/keralaspecial/"
                      >
                        <LiaInstagram />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@keralaspecial5256"
                      >
                        <AiFillYoutube />
                      </a>
                      <a
                        target="_blank"
                        href="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g"
                      >
                        <RiTwitterXLine />
                      </a>
                    </Socials>
                  </List>
                </Section>
              </Container>
            </Wrapper>
          </Footer>
        )}
      </div>
      <ToastContainer />
    </>
  );
}

export default FooterMainNew;
const Footer = styled.footer`
  padding: 50px 0;
  color: #000 !important;
`;
const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr 1fr;
  gap: 30px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;
const ImgContainer = styled.div`
  height: 90px;
  width: 160px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
  }

  &.payment {
    height: 30px;
    width: 210px;
    margin: 20px 0;
  }
`;
const Section = styled.div`
  display: grid;
  gap: 20px;
  justify-items: start;

  &.links {
    grid-template-columns: 1fr 1.2fr 1fr;
    gap: 20px;
    @media screen and (max-width: 430px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`;
const P = styled.p`
  padding: 0;
  margin: 0;
  font-family: "Kantumruy Pro", serif;
`;
const Links = styled.ul`
  padding: 0;
`;
const Li = styled.li`
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  gap: 8px;
  align-items: center;
  padding: 10px 0;
  font-family: "Kantumruy Pro", serif;

  a {
    font-family: "Kantumruy Pro", serif;
    color: #000;
    font-size: 15px;

    &:hover {
      transition: all 0.3s ease;
      font-size: 17px;
      font-weight: 500;
    }
  }
  svg {
    font-size: 20px;
    color: #000;
  }

  &.list {
    grid-template-columns: 1fr;
    padding: 5px 0;
  }
`;
const Head = styled.h4`
  font-family: "Kantumruy Pro", serif;
  font-weight: 500;
  margin-bottom: 20px;
`;
const List = styled.div`
  margin-top: 50px;
`;
const Socials = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 20px 0;
  a {
    flex-shrink: 0;

    background-color: #006d00;
    border-radius: 50%;
    padding: 4px;
  }
  svg {
    color: #fff;
    font-size: 20px;
  }
`;
