import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend, mediaurl } from "../../../path";
import DashboardMain from "../main";
import DashboardSidebarMain from "../dashsidebar";
import HeaderDashMain from "../../layout/header/dash header";
import Pagination from "../../components/pagination";
function DashboardSubCategoryMain() {
  const [formData, setFormData] = useState([]);
  const [data, setData] = useState([]);
  const [datacat, setCat] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [dummyData, setDummyData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [view, setView] = useState(0);
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getsubcategory?page_number=" + pageNumber + "&page_size=" + pageSize, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber,pageSize]);

  const fetchCat = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCat(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCat();
  }, []);


  const handlefileupload = async (e) => {
    try {
      console.log("File selected:", e.target.files[0]);
      let formdata = new FormData();
      formdata.append("csvFile", e.target.files[0]);

      const response = await fetch(backend + "csvhandlesubcategory", {
        method: "POST",
        body: formdata,
        "Content-Type": "multipart/form-data",
      });

      if (!response.ok) {
        throw new Error(`File upload failed with status ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };


  const handleStatusOff = (id) => {
    // formData.status = 1;

    dummyData.sub_category_id = id;
    dummyData.status = 1;

    fetch(backend + "updatesubcategorystatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          fetchData()
          // setView(1);
          // Additional success handling code here
          toast.success("Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleStatus = (id) => {
    // formData.status = 1;

    dummyData.sub_category_id = id;
    dummyData.status = 0;

    fetch(backend + "updatesubcategorystatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          fetchData()
          // setView(1);
          // Additional success handling code here
          toast.success("Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };


  const handlePageClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleView = (id) => {
    const edit = data?.find((item) => item.sub_category_id === id);
    setView(1);
    setFormData(edit);
  };

  const handleSubmit = () => {

    fetch(backend + "editsubcategory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(formData),
    }).then((response) => {
      if (!response.ok) {
        console.error(
          "Failed to register:",
          response.status,
          response.statusText
        );

        // Check for conflict status (HTTP 409)
        if (response.status === 409) {
          console.error("Email Already Exists");
          toast.error("Fill all mandatory fields", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.error("Registration failed");
          toast.error("Fill all mandatory fields", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        return;
      }

      response.json().then((data) => {
        console.log("Success:", data);
        // Additional success handling code here
        toast.success("Successfully Registered", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
      setView(0);
      fetchData();
    });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            <DashboardSidebarMain />
          </div>
          {view === 0 ?
          <div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="padding_eight_all bg-white">
                  <div className="d-flex justify-content-between mb-20 mt-20">
                    <h3 className="">Sub Category</h3>
                    <div className="d-flex justify-content-end gap-3">
                      <a href="/dash-sub-category-add">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up"
                          //   onClick={handleSubmit}
                        >
                          Add New
                        </button>
                      </a>
                      <div>
                        {/* Icon component */}
                        <Icon
                          icon="fa6-solid:file-csv"
                          color="green"
                          className="hover-up"
                          fontSize={46}
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                          style={{ cursor: "pointer" }}
                        />

                        {/* File input */}
                        <input
                          type="file"
                          id="fileInput"
                          accept=".csv"
                          onChange={handlefileupload}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>

                <div className="table-responsive">
                  <table className="table table-secondary table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Sub Category Name</th>
                        <th scope="col">Category Name</th>
                        <th scope="col">Keywords</th>
                        {/* <th scope="col">Logo</th> */}
                        <th scope="col">Description</th>
                        <th scope="col">Date</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.sub_category_name}</td>
                          <td>{item.category_name}</td>
                          <td>{item.sub_category_keywords}</td>
                          {/* <td className="truncate">
                            {item.logo === "" ? "" : (<img
                              src={mediaurl + item.logo}
                              style={{ height: "50px", width: "100px" }}
                            />)}
                          </td> */}
                          <td>{item.description}</td>
                          <td>{item.created_at}</td>
                          <td>
                            {item.updated_at === null ? (
                              "Not Updated"
                            ) : (
                              <>{item.updated_at}</>
                            )}
                          </td>
                          <td>
                            {item.sub_category_status === 0 ? "Available" : "Not Available"}
                          </td>
                          <td>
                              {item.sub_category_status === 0 ? (
                                <Icon
                                  icon="carbon:view-filled"
                                  color="green"
                                  className="cursor-pointer ml-2"
                                  fontSize={20}
                                  onClick={() => handleStatusOff(item.sub_category_id)}
                                />
                              ) : item.sub_category_status === 1 ? (
                                <Icon
                                  icon="carbon:view-off-filled"
                                  color="#b83e0c"
                                  className="cursor-pointer ml-2"
                                  fontSize={20}
                                  onClick={() => handleStatus(item.sub_category_id)}
                                />
                              ) : (
                                ""
                              )}{" "}
                              <Icon
                                icon="basil:edit-solid"
                                color="#1a42cc"
                                className="cursor-pointer ml-2"
                                fontSize={20}
                                onClick={() => handleView(item.sub_category_id)}
                              />
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* <div className="form-group">
                <button
                  type="button"
                  className="btn btn-fill-out btn-block hover-up"
                  onClick={handleSubmit}
                >
                  Register
                </button>
              </div> */}
              <br></br>
              <Pagination
        currentPage={pageNumber}
        pageSize={pageSize}
        totalItems={data.length}
        onPageChange={handlePageClick}
      />
            </div>
          </div>
          :
          <div className="col-lg-10">
          <div className="login_wrap widget-taber-content background-white">
            <div className="padding_eight_all bg-white">
              <div className="padding_eight_all bg-white">
                <div className="d-flex justify-content-between mb-20 mt-20">
                  <h3 className="">Edit Sub-Category</h3>
                    <button
                      type="button"
                      className="btn btn-fill-out btn-block hover-up"
                      onClick={()=>setView(0)}
                    >
                      Back
                    </button>
                </div>
                <br></br>
              </div>
              <div className="form__content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Sub Category Name</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="sub_category_name"
                            id="txt-name"
                            type="text"
                            required
                            value={formData?.sub_category_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                sub_category_name: e.target.value,
                              })
                            }
                            placeholder="Sub Category Name"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">Category Name</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <select
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            required
                            value={formData?.category_id || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                category_id: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                            {datacat.map((val) => {
                              return (
                                <option value={val.category_id}>
                                  {val.category_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Description</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="sub_category_name"
                            id="txt-name"
                            type="text"
                            required
                            value={formData?.description || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                description: e.target.value,
                              })
                            }
                            placeholder="Description"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Sub Category Keywords</label>{" "}
                          <input
                            className="form-control"
                            name="sub_category_name"
                            id="txt-name"
                            type="text"
                            required
                            value={formData?.sub_category_keywords || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                sub_category_keywords: e.target.value,
                              })
                            }
                            placeholder="Sub Category Keywords"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Title</label>{" "}
                          <input
                            className="form-control"
                            name="seo"
                            id="txt-name"
                            type="text"
                            value={formData.seo || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                seo: e.target.value,
                              })
                            }
                            placeholder="Seo Title"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta keywords</label>{" "}
                          <input
                            className="form-control"
                            name="meta_keyword"
                            id="txt-name"
                            type="text"
                            value={formData.meta_keyword || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_keyword: e.target.value,
                              })
                            }
                            placeholder="Seo Meta keywords"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta Description</label>{" "}
                          <input
                            className="form-control"
                            name="meta_description"
                            id="txt-name"
                            type="text"
                            value={formData.meta_description || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_description: e.target.value,
                              })
                            }
                            placeholder="Seo Meta Description"
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="logo">Logo</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="logo"
                            id="txt-name"
                            type="file"
                            required
                            onChange={(event) => {
                              handlefileupload(event);
                            }}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-fill-out btn-block hover-up"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                <br></br>
              {/* </form> */}
            </div>
          </div>
        </div>

          }
          
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default DashboardSubCategoryMain;
