import React, { useEffect, useState } from "react";
import { backend, blogurl, producturl } from "../../../../path";
import LoaderMain from "../../../layout/header/loader_sub";
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function Page3(props) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );


  useEffect(() => {
    // Scroll to the top of the page whenever a new page is rendered
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);

    fetch(backend + `getproductgroup1`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleAddCart = async (id) => {
    if (authenticated === null) {
      navigate(`/login`);
      return;
    }

    const formData = {
      status: 1,
      quantity: 1,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtocartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setCartChange(!props.cartChange)
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {

          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
      }
    } catch (error) {
    }
  };

  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    // window.location.reload();
  };
  const handleAddToWishList = async (id) => {
    if (authenticated === null) {
      navigate(`/login`);
      return;
    }

    const formData = {
      status: 0,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtowishlistuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setWishChange(!props.wishChange)
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the wishlist", {

          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
      }
    } catch (error) {

    }
  };
  return (
    <>
      <main className="main " id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item">Unveiling Kerala's Gems</div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mb-80 mt-50 section--shopping-cart">
            <a className="btn btn-lg mb-2 w-100"
              style={{
                fontSize: '24px',
                backdropFilter: 'inherit',
                backgroundBlendMode: 'color-burn',
                backgroundColor: '#FFC700',
                fontWeight: 'bolder',
                color: '#fff',
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
              }}
            >
              Unveiling Kerala's Gems
              <p>
              Immerse yourself in the vibrant culture and rich heritage of Kerala with our collection of authentic treasures. From exquisite handicrafts to traditional delicacies, uncover the soul of God's Own Country              </p>


              <div className="row mt-40">
                <div className="col-lg-12 d-none d-lg-block">
                  {loading ? (
                    <LoaderMain />
                  ) :
                    data.map((item, i) => (
                      <div
                        className="d-flex justify-content-between product-cart-wrap mb-30 wow animate__animated animate__fadeIn "
                        data-wow-delay="0.1s"
                        key={i}
                        style={{
                          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px', paddingTop: '20px', height: '240px'
                        }}
                      >
                        <div>
                          {" "}
                          <div className="product-img-action-wrap">
                            <div className="product-img product-img-zoom">
                              <a
                              onClick={() => handleProductRoute(item.product_slug)}
                              >
                                <img
                                  className="default-img"
                                  style={{ height: "200px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                  // src={mediaurl + item.images}
                                  src={producturl + item?.thumbnail}
                                // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                />
                                <img
                                  className="hover-img"
                                  style={{ height: "200px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                  src={producturl + item?.thumbnail2}
                                // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div>
                          {" "}
                          <div className="product-content-wrap pt-4">
                            <div className="product-category">
                              <a
                              onClick={() => handleProductRoute(item.product_slug)}
                              >
                                {item?.category_name}
                              </a>
                            </div>
                            <h2 className="text-truncate">
                              <a
                              href={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)}                                // title="Nestle Original Coffee-Mate Coffee Creamer"
                              >
                                {item?.product_name}
                              </a>
                            </h2>
                            <div className="product-rate-cover">
                              <div className="d-inline-block">
                                <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                              </div>
                              <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'top', fontSize: '12px' }}>
                                ({item?.average_stars ?? "Not Yet Rated"})
                              </span>
                            </div>

                            <div className="product-card-bottom  flex">
                            {item?.brand &&

                              <div className="product-price">
                                <span>Brand :
                                  {item?.brand}
                                </span>
                              </div>
}
                              <div className="product-price">
                                <span>Sold By :
                                  {item?.manufacturer_name ?? "KeralaSpecial"}
                                </span>
                              </div>
                            </div>
                            <div className="product-extra-link2 has-buy-now-button pt-20" >
                                  <button
                                    type="button"
                                    className="button button-add-to-cart hover-up"
                                    onClick={() => handleAddCart(item?.product_id)}
                                  >
                                    <i className="fi-rs-shopping-cart"></i>
                                    Add to cart
                                  </button>
                                  <button
                                    className="button button-buy-now ms-2 hover-up"
                                    type="button"
                                    // name="checkout"
                                    onClick={()=>handleAddToWishList(item?.product_id)}
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="#fff" fillRule="evenodd" d="M8.25 6.015V5a3.75 3.75 0 1 1 7.5 0v1.015c1.287.039 2.075.177 2.676.676c.833.692 1.053 1.862 1.492 4.203l.75 4c.617 3.292.925 4.938.026 6.022C19.794 22 18.119 22 14.77 22H9.23c-3.35 0-5.024 0-5.924-1.084c-.9-1.084-.59-2.73.026-6.022l.75-4c.44-2.34.659-3.511 1.492-4.203c.601-.499 1.389-.637 2.676-.676M9.75 5a2.25 2.25 0 0 1 4.5 0v1h-4.5zM9 13.197c0 .984 1.165 2.024 2.043 2.669c.42.308.63.462.957.462c.328 0 .537-.154.957-.462c.878-.645 2.043-1.685 2.043-2.67c0-1.672-1.65-2.297-3-1.005c-1.35-1.292-3-.668-3 1.006" clipRule="evenodd"></path></svg>
                                  </button>

                                  {/* <a
                                    aria-label="Add To Wishlist"
                                    className="action-btn hover-up js-add-to-wishlist-button"
                                    onClick={()=>handleAddToWishList(productId)}
                                    href=""
                                  >
                                    <i className="fi-rs-heart">

                                      
                                    </i>
                                  </a> */}
                                  {/* <a
                                    aria-label="Add To Compare"
                                    href="#"
                                    className="action-btn hover-up js-add-to-compare-button"
                                  >
                                    <i className="fi-rs-shuffle"></i>
                                  </a> */}
                                </div>
                          </div>
                        </div>
                        <div className="pt-4 mx-3">
                          <div className="align-middle">
                            <h6 className="heading-2 border-bottom pb-1">
                              PRODUCT
                            </h6>
                            <h3 className="heading-4 product-price text-info">
                              ₹ {item?.mrp}
                            </h3>
                          </div>
                          <div className="align-middle">
                            <h6 className="heading-2 pb-1">
                              SHIPPING CHARGE : {item?.shipping_charge}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="col-lg-12 d-block d-lg-none">
                  {loading ? (
                    <LoaderMain />
                  ) :
                    data.map((item, i) => (
                      <div
                        key={i}
                        className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 col-sm-3"
                        style={{
                           height: '600px'
                        }}
                      >
                        <div
                          className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                          data-wow-delay="0.1s"
                          style={{
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                          }}
                        >
                          <div className="product-img-action-wrap">
                            <div className="product-img product-img-zoom">
                              <a
                                href={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                              >
                                <img
                                  className="default-img"
                                  style={{ height: "320px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                  src={producturl + item.thumbnail}
                                // src="../storage/products/11-400x400.jpg"
                                // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                />

                                <img
                                  className="hover-img"
                                  style={{ height: "320px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                  src={item.thumbnail2 ? producturl + item.thumbnail2 : producturl + item.thumbnail}
                                // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                />
                              </a>
                            </div>
                            <div className="product-action-1">
                              <a
                                aria-label="Quick View"
                                href={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                className="action-btn hover-up js-quick-view-button"
                              >
                                <i className="fi-rs-eye"></i>
                              </a>
                              <a
                                aria-label="Add To Wishlist"
                                onClick={() => handleAddToWishList(item.product_id)}
                                className="action-btn hover-up js-add-to-wishlist-button"
                              >
                                <i className="fi-rs-heart"></i>
                              </a>
                              {/* <a
                            aria-label="Add To Compare"
                            onClick={() => handleProductRoute(item.product_id)}
                            className="action-btn hover-up js-add-to-compare-button"
                          >
                            <i className="fi-rs-shuffle"></i>
                          </a> */}
                            </div>
                            <div className="product-badges product-badges-position product-badges-mrg"></div>
                          </div>
                          <div className="product-content-wrap">
                            <div className="product-category">
                              <a
                                href={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                              >
                                {item?.category_name}
                              </a>
                            </div>
                            <h2 className="text-truncate">
                              <a
                                href={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                title="Nestle Original Coffee-Mate Coffee Creamer"
                              >
                                {item?.product_name}
                              </a>
                            </h2>
                            <div className="product-rate-cover">
                              <div className="d-inline-block">
                                <div className="d-inline-block">
                                  <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                                </div>
                                <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'top', fontSize:'12px' }}>
                                  ( {item?.comments?.length ?? "0"} reviews)
                                </span>
                              </div>
                            </div>
                            <div className="text-truncate" style={{fontSize:'12px'}}>
                              <span className="font-small text-muted">
                                Sold By{" "}
                                <a
                                  href={`/product/${item.product_slug}`}
                                  onClick={() =>
                                    handleProductRoute(item.product_slug)
                                  }
                                >
                                  {item?.vendor_name ?? "KeralaSpecial"}
                                </a>
                              </span>
                            </div>
                            {item?.brand &&
                            <div className="text-truncate" style={{fontSize:'12px'}}>
                              <span className="font-small text-muted">
                                Brand{" "}
                                <a
                                  href={`/product/${item.product_slug}`}
                                  onClick={() =>
                                    handleProductRoute(item.product_slug)
                                  }
                                >
                                  {item?.brand}
                                </a>
                              </span>
                            </div>}
                            <div className="product-card-bottom">
                              <div className="product-price">
                                <span>₹ {item.mrp}</span>
                              </div>
                              <div className="add-cart">
                                <a
                                  aria-label="Add To Cart"
                                  className="action-btn add-to-cart-button add"
                                  data-id="11"
                                  onClick={() =>
                                    handleAddCart(item.product_id)
                                  }
                                >
                                  <i className="fi-rs-shopping-cart mr-5"></i>
                                  <span className="d-inline-block">Add</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              Celebrate the essence of Kerala's cultural heritage with our curated collection of artisanal creations. Every purchase supports local artisans and preserves centuries-old traditions              </a>
          </div></div>
      </main>
      <ToastContainer/>
    </>
  );
}

export default Page3;
