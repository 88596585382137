import React from "react";

const Pagination = ({ currentPage, pageSize, totalItems, onPageChange }) => {
  // Calculate total number of pages
  const totalPages = Math.ceil(totalItems / pageSize);

  // Generate an array of page numbers
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  return (
    <div className="d-flex justify-content-center mb-3">
    <nav>
      <ul className="pagination">
        {pages.map((page) => (
          <li
            key={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => onPageChange(page)}
            >
              {page}
            </button>
          </li>
        ))}
      </ul>
    </nav></div>
  );
};

export default Pagination;
