import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { backend } from "../../../../path";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "../../../components/whatsapp connect";
import styled from "styled-components";

function Login() {
  const [formData, setFormData] = useState([]);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = () => {
    if (!formData.email) {
      toast.error("Email is required.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Check if email is in valid email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      console.error("Invalid email format.");
      toast.error("Invalid email format.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Check if password is not null or empty
    if (!formData.password) {
      console.error("Password is required.");
      toast.error("Password is required.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    fetch(backend + "login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        response.json().then((data) => {
          console.log(data);
          if (data !== "invalid") {
            localStorage.setItem("userdata", JSON.stringify(data));
          }
        });

        if (!response.ok) {
          console.error(
            "Failed to Login:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Invalid Credentials");
            toast.error("Invalid Credentials", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (response.status === 410) {
            navigate("/user/reminder");
          } else {
            console.error("Password Does Not Match");
            toast.error("Password Does Not Match", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        // If login is successful, navigate to "/"
        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error during login:", error);
        toast.error("Login failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <Main className="main" id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Login </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="page-content pt-150 pb-150"
          //   style={{
          //   backgroundImage: `url('storage/general/loginpage.png')`
          // }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                <div className="row">
                  <div className="col-lg-6 pr-30 d-none d-lg-block">
                    <img
                      className="border-radius-15"
                      src="storage/general/login-1.png"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6 col-md-8">
                    <div className="login_wrap widget-taber-content background-white">
                      <div className="padding_eight_all bg-white">
                        <div className="heading_s1">
                          <h1 className="mb-5">Login</h1>
                          <p className="mb-30">
                            Don&#039;t have an account?{" "}
                            <a href="register">Create one</a>
                          </p>
                        </div>
                        <form>
                          {/* <input
                            type="hidden"
                            name="_token"
                            value="bVwKUehCmCMBBOuQToUzdDhTTdTBfg78RlsalVJQ"
                            autocomplete=" off"
                          /> */}
                          <div className=" ">
                            <input
                              // className=" "
                              name="email"
                              required
                              id="txt-email"
                              type="email"
                              value={formData.email || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  email: e.target.value,
                                })
                              }
                              placeholder="Email"
                            />
                          </div>
                          {/* <div className=" ">
                            <input
                              type="password"
                              required
                              name="password"
                              id="txt-password"
                              placeholder="Your password*"
                              value={formData.password || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  password: e.target.value,
                                })
                              }
                            />
                          </div> */}
                          <div className="position-relative ">
                            <input
                              // className=" "
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={formData.password || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  password: e.target.value,
                                })
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleSubmit();
                                }
                              }}
                              placeholder="Your password"
                            />
                            <div
                              className=" position-absolute password-toggle-icon"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={32}
                                  height={32}
                                  viewBox="0 0 512 512"
                                >
                                  <path
                                    fill="#3BB77E"
                                    d="m63.998 86.005l21.998-21.998L447.999 426.01l-21.998 21.998zM259.34 192.09l60.57 60.57a64.07 64.07 0 0 0-60.57-60.57m-6.68 127.82l-60.57-60.57a64.07 64.07 0 0 0 60.57 60.57"
                                  ></path>
                                  <path
                                    fill="#45B755"
                                    d="M256 352a96 96 0 0 1-92.6-121.34l-69.07-69.08C66.12 187.42 39.24 221.14 16 256c26.42 44 62.56 89.24 100.2 115.18C159.38 400.92 206.33 416 255.76 416A233.47 233.47 0 0 0 335 402.2l-53.61-53.6A95.84 95.84 0 0 1 256 352m0-192a96 96 0 0 1 92.6 121.34L419.26 352c29.15-26.25 56.07-61.56 76.74-96c-26.38-43.43-62.9-88.56-101.18-114.82C351.1 111.2 304.31 96 255.76 96a222.92 222.92 0 0 0-78.21 14.29l53.11 53.11A95.84 95.84 0 0 1 256 160"
                                  ></path>
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={32}
                                  height={32}
                                  viewBox="0 0 1024 1024"
                                >
                                  <path
                                    fill="#3BB77E"
                                    d="M396 512a112 112 0 1 0 224 0a112 112 0 1 0-224 0m546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3c7.7-16.2 7.7-35 0-51.5M508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176s176 78.8 176 176s-78.8 176-176 176"
                                  ></path>
                                </svg>
                              )}
                            </div>
                          </div>
                          <div className="login_footer  d-flex justify-content-end">
                            <a className="text-muted" href="/forget-password">
                              Forgot password ?
                            </a>
                          </div>
                          <div className=" ">
                            <button
                              type="button"
                              className="btn btn-heading btn-block hover-up"
                              onClick={handleSubmit}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleSubmit();
                                }
                              }}
                            >
                              Continue
                            </button>
                          </div>
                          <div className="text-left"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ScrollToTop />
          </div>
        </div>
        <ToastContainer />
      </Main>
    </>
  );
}

export default Login;
const Main = styled.main`
  form {
    display: grid;
    gap: 20px;
  }
  input {
    padding: 10px;
  }
`;
