import React, { useEffect, useRef, useState } from "react";
import UncontrolledExample from "./carousel";
import { backend, mediaurl, producturl } from "../../../../path";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "../../../components/whatsapp connect";
import { Rating } from "@mui/material";
import { Helmet } from "react-helmet";
import LoaderMain from "../../../layout/header/loader_sub";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi2";
import { PiShoppingCartThin } from "react-icons/pi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { CiHeart } from "react-icons/ci";
import { VscHeartFilled } from "react-icons/vsc";
import { RxPadding } from "react-icons/rx";
import StarIcon from "@mui/icons-material/Star";
import { trimDescriptionToTen } from "../../../../utils/trimDescription";
import TopSellingComponent from "../../topselling/Index";

// import { Rating } from "react-simple-star-rating";

function HomepageNew(props) {
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    fade: true,
    waitForAnimate: false,
  };
  const Topsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };
  // useEffect(()=>{
  //   let userdata = JSON.parse(localStorage.getItem('userdata'));
  //   fetch(backend + "tokentest", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization:`Bearer ${userdata.access_token}`,
  //       "Content-Type": "application/json",
  //     }
  //   })
  //     .then((response) => {
  //       response.json().then((data) => {
  //       console.log(data)

  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error during login:", error);
  //     });
  // },[])
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [subEmail, setSubEmail] = useState("");

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  const fetchData = () => {
    setLoading(true);

    fetch(backend + "getallspecialproduts", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Scroll to the top of the page whenever a new page is rendered
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddCart = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 1,
      quantity: 1,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtocartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setCartChange(!props.cartChange);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    // window.location.reload();
  };
  const handleAddToWishList = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 0,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtowishlistuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setWishChange(!props.wishChange);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    fetch(backend + "addsubscription", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: subEmail }),
    })
      .then((response) => {
        setSubEmail("");
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Email Already Exists");
            toast.error("Email Already Exists", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Registration failed");
            toast.error("Registration failed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          toast.success("Subscribed Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const heroImages = [
    {
      id: 1,
      src: "/storage/general/HANDLOOM.png",
      route: "",
    },
    {
      id: 2,
      src: "/storage/general/AYURVEDIC (1).png",
      route: "",
    },
    {
      id: 3,
      src: "/storage/general/HANDICRAFTS 2 (1).png",
      route: "",
    },
  ];
  function getFirstImageUrl(data) {
    const producturl = "https://keralaspecial.in/server/public/upload/products";

    // Check if data is a valid string
    if (typeof data !== "string" || !data.trim()) {
      console.error("Invalid data provided:", data);
      return null; // Return null or an appropriate default value
    }

    try {
      // Use a regular expression to extract all strings inside double quotes
      let matches = data
        .match(/"([^"]*)"/g)
        ?.map((item) => item.replace(/"/g, ""));

      // Check if matches exist and get the first value
      if (matches && matches.length > 0) {
        let firstImage = matches[0];
        // Join the base URL with the first image filename
        return `${producturl}/${firstImage}`;
      } else {
        console.error("No matches found in data:", data);
        return null;
      }
    } catch (error) {
      console.error("Error processing data:", error);
      return null;
    }
  }
  const scrollDown = () => {
    //function to scroll down 100px
    window.scrollBy(0, 1000);
  };
  return (
    <>
      {loading && <LoaderMain />}

      <Helmet>
        <title>Best online products in kerala</title>
        <meta
          name="description"
          content="Discover Kerala's finest products online! Handicrafts, spices, Ayurveda, handlooms & more. Fast & reliable shipping."
        />
        <meta
          name="keywords"
          content="online shopping,online shopping sites,online store"
        />
      </Helmet>
      <main>
        {/* ----------------------------------------------------- */}
        {/* Hero section start */}
        <Hero>
          <div className="slider-container">
            <Slider {...settings}>
              {heroImages.map((data, index) => {
                return (
                  <Slide key={data.id}>
                    <ImageContainer className="hero">
                      <img src={data.src} alt="Image not found" />
                    </ImageContainer>
                    <button onClick={scrollDown}>
                      <span>Shop Now</span>
                      <span>
                        <HiOutlineArrowRight />
                      </span>
                    </button>
                  </Slide>
                );
              })}
            </Slider>
          </div>
        </Hero>
        {/* Hero section end */}
        {/* ----------------------------------------------------- */}
        {/* Experience section start */}
        <Experience>
          <div className="wrapper">
            <Container className="experience">
              <Section className="experience">
                <ImageContainer className="experience">
                  <img src="/storage/general/delivery.svg" alt="delivery" />
                </ImageContainer>
                <h5>Home Delivery</h5>
              </Section>
              <Section className="experience">
                <ImageContainer className="experience">
                  <img src="/storage/general/experience.svg" alt="experience" />
                </ImageContainer>
                <h5>7+ Years Experience</h5>
              </Section>
              <Section className="experience">
                <ImageContainer className="experience">
                  <img src="/storage/general/people.svg" alt="people" />
                </ImageContainer>
                <h5>Customer Satisfaction</h5>
              </Section>
            </Container>
          </div>
        </Experience>
        {/* Experience section end */}
        {/* ----------------------------------------------------- */}
        {/* Deals section start */}
        <Deals>
          <div className="wrapper">
            <Container className="deals">
              <Left className="deals">
                <h4>Deals of the Day</h4>
                <Products>
                  {data &&
                    data.getDealOfTheDay &&
                    data.getDealOfTheDay.slice(1, 7).map((item) => {
                      return (
                        <Card key={item.product_id} className="deals">
                          <Top>
                            <LikeBtn
                              onClick={() =>
                                handleAddToWishList(item.product_id)
                              }
                            >
                              {true ? <CiHeart /> : <VscHeartFilled />}
                            </LikeBtn>
                            <ImageContainer
                              className="deals"
                              onClick={() =>
                                handleProductRoute(item.product_slug)
                              }
                            >
                              <img
                                src={producturl + item.thumbnail}
                                alt="Image not found"
                                className="cover"
                              />
                            </ImageContainer>
                          </Top>
                          <Bottom className="deals">
                            <div>
                              <p
                                onClick={() =>
                                  handleProductRoute(item.product_slug)
                                }
                              >
                                {item.product_name}
                              </p>
                              <Rating
                                name="hover-feedback"
                                value={item.average_stars}
                                precision={0.5}
                                // getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                  setValue(newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                  setHover(newHover);
                                }}
                                emptyIcon={
                                  <StarIcon
                                    style={{ opacity: 0.55 }}
                                    fontSize="inherit"
                                  />
                                }
                              />
                              {/* {value !== null && (
                          <Box sx={{ ml: 2 }}>
                            {labels[hover !== -1 ? hover : value]}
                          </Box>
                        )} */}
                              <DescriptionButton>
                                <h3>₹ {item.sale_price}</h3>
                                <div>
                                  <button
                                    onClick={() =>
                                      handleAddCart(item.product_id)
                                    }
                                  >
                                    <PiShoppingCartThin />
                                  </button>
                                </div>
                              </DescriptionButton>
                            </div>
                          </Bottom>
                        </Card>
                      );
                    })}
                </Products>
              </Left>
              <Right className="deals">
                <Card className="deals">
                  <Top>
                    <LikeBtn
                      onClick={() =>
                        handleAddToWishList(
                          data.getDealOfTheDay &&
                            data.getDealOfTheDay &&
                            data.getDealOfTheDay[0].product_id
                        )
                      }
                    >
                      {true ? <CiHeart /> : <VscHeartFilled />}
                    </LikeBtn>
                    <ImageContainer className="deals">
                      <img
                        src={getFirstImageUrl(
                          data.getDealOfTheDay && data.getDealOfTheDay[0].images
                        )}
                        className="cover"
                        alt="Image not found"
                      />
                    </ImageContainer>
                  </Top>
                  <Bottom className="deals-right">
                    <div>
                      <h3>
                        {data.getDealOfTheDay &&
                          data.getDealOfTheDay &&
                          data.getDealOfTheDay[0].product_name}
                      </h3>
                      <button
                        className="shop-button"
                        onClick={() =>
                          handleProductRoute(
                            data.getDealOfTheDay &&
                              data.getDealOfTheDay &&
                              data.getDealOfTheDay[0].product_slug
                          )
                        }
                      >
                        <span>Shop Now</span>
                        <span>
                          <HiOutlineArrowRight />
                        </span>
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          handleAddCart(
                            data.getDealOfTheDay &&
                              data.getDealOfTheDay &&
                              data.getDealOfTheDay[0].product_id
                          )
                        }
                      >
                        <PiShoppingCartThin />
                      </button>
                    </div>
                  </Bottom>
                </Card>
                {/* <Card className="deals">
                  <Top>
                    <LikeBtn
                              onClick={() =>
                                handleAddToWishList(item.product_id)
                              }
                            >{true ? <CiHeart /> : <VscHeartFilled />}</LikeBtn>
                    <ImageContainer className="deals">
                      <img src="" alt="Image not found" />
                    </ImageContainer>
                  </Top>
                  <Bottom className="deals-right">
                    <div>
                      <h3>Item Title </h3>
                      <button className="shop-button">
                        <span>Shop Now</span>
                        <span>
                          <HiOutlineArrowRight />
                        </span>
                      </button>
                    </div>
                    <div>
                      <button>
                        <PiShoppingCartThin />
                      </button>
                    </div>
                  </Bottom>
                </Card> */}
              </Right>
            </Container>
          </div>
        </Deals>
        {/* Deals section end */}
        {/* ----------------------------------------------------- */}
        {/* Popular section start */}
        <Popular>
          <div className="wrapper">
            <h2>Popular Products</h2>
            <Container className="popular">
              <Left className="popular">
                <Card className="deals">
                  <Top>
                    <LikeBtn
                      onClick={() =>
                        handleAddToWishList(
                          data.getPopularProduct &&
                            data.getPopularProduct &&
                            data.getPopularProduct[0].product_id
                        )
                      }
                    >
                      {true ? <CiHeart /> : <VscHeartFilled />}
                    </LikeBtn>
                    <ImageContainer className="popular-left">
                      <img
                        className="cover"
                        src={getFirstImageUrl(
                          data.getPopularProduct &&
                            data.getPopularProduct[0].images
                        )}
                        alt="Image not found"
                      />
                    </ImageContainer>
                  </Top>
                  <Bottom className="popular-left">
                    <div>
                      <h3>
                        {data &&
                          data.getPopularProduct &&
                          data.getPopularProduct[0].product_name}
                      </h3>
                      <Rating
                        name="hover-feedback"
                        value={
                          data &&
                          data.getPopularProduct &&
                          data.getPopularProduct[0].average_stars
                        }
                        precision={0.5}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />

                      <p>
                        {data &&
                          data.getPopularProduct &&
                          trimDescriptionToTen(
                            data.getPopularProduct[0].description
                          )}
                      </p>
                    </div>
                    <div
                      onClick={() =>
                        handleProductRoute(
                          data.getPopularProduct &&
                            data.getPopularProduct &&
                            data.getPopularProduct[0].product_slug
                        )
                      }
                    >
                      <button>Buy Now</button>
                    </div>
                  </Bottom>
                </Card>
              </Left>
              <Right className="popular">
                <Products className="popular">
                  {data &&
                    data.getPopularProduct &&
                    data.getPopularProduct.slice(1, 7).map((item) => {
                      return (
                        <Card key={item.product_id} className="deals">
                          <Top>
                            <LikeBtn
                              onClick={() =>
                                handleAddToWishList(item.product_id)
                              }
                            >
                              {true ? <CiHeart /> : <VscHeartFilled />}
                            </LikeBtn>
                            <ImageContainer
                              onClick={() =>
                                handleProductRoute(item.product_slug)
                              }
                              className="popular"
                            >
                              <img
                                src={producturl + item.thumbnail}
                                alt="Image Not Found"
                                className="cover"
                              />
                            </ImageContainer>
                          </Top>
                          <Bottom className="popular">
                            <div>
                              <p
                                onClick={() =>
                                  handleProductRoute(item.product_slug)
                                }
                              >
                                {item.product_name}
                              </p>
                              <Rating
                                name="hover-feedback"
                                value={item.average_stars}
                                precision={0.5}
                                // getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                  setValue(newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                  setHover(newHover);
                                }}
                                emptyIcon={
                                  <StarIcon
                                    style={{ opacity: 0.55 }}
                                    fontSize="inherit"
                                  />
                                }
                              />
                              {/* {value !== null && (
                          <Box sx={{ ml: 2 }}>
                            {labels[hover !== -1 ? hover : value]}
                          </Box>
                        )} */}
                              <DescriptionButton>
                                <h3>₹ {item.sale_price}</h3>
                                <div>
                                  <button
                                    onClick={() =>
                                      handleAddCart(item.product_id)
                                    }
                                  >
                                    <PiShoppingCartThin />
                                  </button>
                                </div>
                              </DescriptionButton>
                            </div>
                          </Bottom>
                        </Card>
                      );
                    })}
                </Products>
              </Right>
            </Container>
          </div>
        </Popular>
        {/* Popular section end */}
        {/* ----------------------------------------------------- */}
        {/* Unveiling section start */}
        <Unveiling>
          <ImageContainer className="unveiling-banner ">
            <img src="/storage/general/banner1-2.png" alt="Image not found" />
          </ImageContainer>
          <div className="wrapper">
            <Container className="unveiling">
              <Left className="unveiling">
                <h3>Unveiling Ayurvedic Delights for the Day </h3>
                <p>
                  Discover the essence of holistic wellness with our curated
                  selection of Ayurvedic treasures. From rejuvenating skin care
                  to nourishing herbal supplements, embark on a journey of
                  self-care and vitality.
                </p>
                <button>
                  <span>Shop Now</span>
                  <span>
                    <HiOutlineArrowRight />
                  </span>
                </button>
              </Left>
              <Right className="unveiling">
                <ImageContainer className="unveiling">
                  <img
                    src="/storage/general/banner1-3.png"
                    alt="Image not found"
                  />
                </ImageContainer>
              </Right>
            </Container>
          </div>
        </Unveiling>
        {/* Unveiling section end */}
        {/* ----------------------------------------------------- */}
        {/* Top Selling section start */}
        <TopSelling>
          <div className="wrapper">
            <TopSellingComponent />
          </div>
        </TopSelling>
        {/* Top Selling section end */}
        {/* ----------------------------------------------------- */}
        {/* Discover section start */}
        <Discover>
          <div className="wrapper">
            <Container className="discover">
              <Left className="discover">
                <h3>Discover the Soul of Kerala.</h3>
                <h5>
                  Experience Authentic Crafts, Handlooms & Ayurveda. Immerse
                  yourself in the rich heritage of Kerala.
                </h5>
                <p>Subscribe for our blogs...</p>
                <form onSubmit={handleSubscribe}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={subEmail}
                    onChange={(e) => setSubEmail(e.target.value)}
                  />
                  <button type="submit">Subscribe</button>
                </form>
              </Left>
              <Right className="discover">
                <ImageContainer className="discover">
                  <img
                    src="/storage/general/banner1.png"
                    alt="Image not found"
                  />
                </ImageContainer>
              </Right>
            </Container>
          </div>
        </Discover>
        {/* Discover section end */}
        {/* ----------------------------------------------------- */}
      </main>
    </>
  );
}
export default HomepageNew;
const Hero = styled.div`
  overflow: hidden;
  height: 800px;

  @media screen and (max-width: 1024px) {
    height: 600px;
  }
  @media screen and (max-width: 768px) {
    height: 500px;
  }
  @media screen and (max-width: 480px) {
    height: 250px;
  }
  @media screen and (max-width: 420px) {
    height: 200px;
  }
`;
const Slide = styled.div`
  position: relative;
  button {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    padding: 8px 10px;
    border: none;
    display: flex;
    align-items: center;
    gap: 20px;
    border: none;
    border-radius: 6px;
    background-color: #fc9800;
    color: #fff;
    font-size: 17px;
    @media screen and (max-width: 540px) {
      padding: 4px 7px;
      font-size: 14px;
    }
  }
`;
const Experience = styled.div`
  padding: 60px 0;
`;
const Deals = styled.div`
  padding: 60px 0;
`;
const Popular = styled.div`
  padding: 60px 0;
  background-color: #ffedd4;
  h2 {
    border-bottom: 1px solid #636363;
    display: inline-block;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 40px;
    @media screen and (max-width: 1024px) {
      margin-bottom: 0px;
    }
  }
`;

const Unveiling = styled.div`
  background-color: #084c27;
  padding: 60px 0;
  /* position: relative; */
`;
const TopSelling = styled.div`
  padding: 60px 0;
  h5 {
    border-bottom: 1px solid #636363;
    display: inline-block;
    padding-bottom: 10px;
  }
`;
const Card = styled.div`
  /* border: 1px solid #636363;
  box-shadow: 1px 1px 14px #636363; */
  &.popular {
    border: 1px solid black;
  }
`;
const Top = styled.div`
  position: relative;
  &.popular-left {
    position: unset;
  }
`;
const Bottom = styled.div`
  &.top-selling {
    gap: 20px;
    align-items: end;
    margin: 20px 0;
    padding: 5px 15px;
    h3 {
      font-size: 19px;
      font-weight: 600;

      min-height: 50px;
    }
    p {
      cursor: pointer;
      font-size: 15px;
    }
    button {
      border: none;
      background-color: #389763;
      border-radius: 6px;
      padding: 8px 10px;
      svg {
        font-size: 20px;
        color: #fff;
      }
    }
  }
  &.deals {
    background-color: #f0f0f0;
    gap: 20px;
    align-items: end;

    padding: 10px 15px;
    padding-top: 20px;
    p {
      min-height: 50px;
      cursor: pointer;
    }
    button {
      border: none;
      background-color: #389763;
      border-radius: 6px;
      padding: 8px 10px;
      svg {
        font-size: 20px;
        color: #fff;
      }
    }
  }

  &.deals-right {
    background-color: #ad4b0e;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: end;
    h3,
    p {
      color: #fff;
      font-weight: 500;
    }

    button {
      border: none;
      background-color: #fff;
      border-radius: 6px;
      padding: 8px 10px;
      svg {
        font-size: 20px;
        color: #ad4b0e;
      }
    }
    .shop-button {
      margin-top: 20px;
      border-radius: 10px;
      padding: 8px 10px;
      border: none;
      display: flex;
      align-items: center;
      gap: 20px;
      border: none;
      border-radius: 6px;
      background-color: #fc9800;
      color: #fff;
      font-size: 17px;
      svg {
        color: #fff;
      }
    }
  }
  &.popular-left {
    padding: 20px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 3fr 1fr;
    justify-content: space-between;
    gap: 20px;
    align-items: end;
    padding-top: 25px;
    p {
      color: #000;
    }
    h3 {
      color: #fff;
      font-size: 23px;
      font-weight: 500;
      color: #ad4b0e;
    }
    button {
      border: none;
      background-color: #fc9800;
      border-radius: 6px;
      padding: 8px 22px;
      color: #fff;
      font-weight: 500;
    }
  }
  &.popular {
    padding: 20px;
    background-color: #ad4b0e;

    gap: 20px;
    align-items: end;
    padding-top: 25px;
    p {
      color: #fff;
      min-height: 75px;
    }
    h3 {
      color: #fff;

      font-size: 17px;
      font-weight: 500;
    }
    button {
      border: none;
      background-color: #fff;
      border-radius: 6px;
      padding: 8px 10px;
      svg {
        font-size: 20px;
        color: #ad4b0e;
      }
    }
  }
`;
const Discover = styled.div`
  background-color: #ffedd4;
  padding: 60px 0;
`;
const Container = styled.div`
  &.deals {
    display: grid;
    grid-template-columns: 2fr 0.8fr;
    gap: 20px;
    @media screen and (max-width: 1080px) {
      grid-template-columns: 1fr;
    }
  }
  &.popular {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      gap: 10px; //might ned to channge
    }
  }
  &.experience {
    background-color: #fff5e8;
    border: 1px solid #636363;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  &.unveiling {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 20px;

    @media screen and (max-width: 980px) {
      grid-template-columns: 1fr;
    }
  }
  &.top-selling {
    border: 1px solid #636363;
    padding: 10px;
    margin: 20px 0;
    .slick-slide {
      padding-right: 15px;
      height: unset;
    }
  }
  &.discover {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 980px) {
      grid-template-columns: 1fr;
    }
  }
`;
const Left = styled.div`
  &.deals {
    border: 1px solid #636363;
    padding: 20px;
    h4 {
      border-bottom: 1px solid #636363;
      font-size: 23px;
      display: inline-block;
      font-weight: 500;
      padding-bottom: 5px;
      margin-top: 20px;
    }
    h3 {
      font-size: 18px;
      display: block;
      font-weight: 500;
    }
  }
  &.unveiling {
    h3,
    h5 {
      font-weight: 600;

      color: #fff;
    }
    p {
      color: #fff;
      margin: 30px 0;
    }
    h3 {
      text-transform: uppercase;
      font-size: 42px;
    }

    button {
      border-radius: 10px;
      padding: 8px 10px;
      border: none;
      display: flex;
      align-items: center;
      gap: 20px;
      border: none;
      border-radius: 6px;
      background-color: #fc9800;
      color: #fff;
      font-size: 17px;
    }
  }
  &.discover {
    h3,
    h5 {
      font-weight: 600;
      margin-bottom: 20px;
    }
    h3 {
      text-transform: uppercase;
      font-size: 42px;
    }
    form {
      display: flex;
      width: 70%;
      margin: 20px 0;
      input {
        border-radius: 10px 0 0 10px;
      }
      button {
        border-radius: 0 10px 10px 0;
        height: 100%;
        padding: 10px;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
  &.popular {
    @media screen and (max-width: 1024px) {
      justify-self: center;
      align-self: center;
    }
  }
`;
const Right = styled.div`
  &.deals {
    display: grid;
    gap: 40px;
    @media screen and (max-width: 1080px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 570px) {
      grid-template-columns: 1fr;
    }
  }
`;
const ImageContainer = styled.div`
  cursor: pointer;
  overflow: hidden;
  &.hero {
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      display: block;
    }
  }
  &.experience {
    background-color: #ad4b0e;
    border-radius: 2px;
    padding: 5px;
    height: 35px;
    width: 35px;
  }
  &.unveiling {
    height: 300px;
  }
  &.top-selling {
    height: 300px;
  }
  &.deals {
    height: 250px;
  }
  &.popular {
    height: 250px;
  }
  &.popular-left {
    height: 47%;
  }
  &.unveiling-banner {
    display: none;
    position: absolute;
    height: 80px;
    width: 110px;
    bottom: 0;
    left: 0;
    img {
      object-fit: cover;
    }
  }
  &.discover {
    height: 350px;
    width: 100%;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.05);
    }
  }

  img.cover {
    object-fit: cover;
  }
`;
const Section = styled.div`
  &.experience {
    display: grid;

    place-items: center;
    border-right: 1px solid #000;
    margin: 20px 0;
    padding: 20px 0;
    &:last-child {
      border: none;
    }
    h5 {
      margin-top: 20px;
      font-weight: 500;
    }
    @media screen and (max-width: 768px) {
      margin: 0;
      padding: 0;
      border-right: none;
      padding-bottom: 10px;
      border-bottom: 1px solid #000;
      margin: 0 20px;
      &:first-child {
        margin-top: 40px;
      }
      &:last-child {
        margin-bottom: 40px;
      }
    }
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 20px;
`;
const Button = styled.div`
  background-color: #084c27;
  padding: 10px;
  cursor: pointer;
  svg {
    font-size: 20px;
    color: #fff;
  }
`;
const LikeBtn = styled.div`
  position: absolute;
  z-index: 9;
  right: 20px;
  top: 20px;
  background-color: #f0f0f0;
  display: inline-block;
  padding: 10px;
  svg {
    font-size: 20px;
    color: #ff0000;
  }
`;
const Products = styled.div`
  margin-top: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 40px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 570px) {
    grid-template-columns: 1fr;
  }

  &.popular {
    gap: 13px;
    row-gap: 13px;
    margin-top: 0px;
    .css-1qqgbpl-MuiRating-root {
      font-size: 1.1rem;
    }
  }
`;
const PopularCard = styled.div`
  border: 1px solid black;
`;
const PCardTop = styled.div``;
const PCardBottom = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  h4 {
    color: #ad4b0e;
    font-weight: 600;
    margin-bottom: 8px;
  }
  button {
    background-color: #fc9800;
    border-radius: 7px;
    color: #fff;
    /* padding: 12px 22px; */
  }
`;
const DescriptionButton = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;
