import React, { useEffect, useState } from "react";
import { backend, blogurl, categoryurl, producturl } from "../../../../path";
import { useParams } from "react-router-dom";
import LoaderMain from "../../../layout/header/loader_sub";
import { Link } from "react-router-dom";
import styled from "styled-components";
function BlogUserDetail(props) {
  const [data, setData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const { id } = useParams();

  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + `getblog/${id}`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  console.log(data);

  const fetchCatData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategorywithsubcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCatData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    fetchCatData();
  }, []);
  const decodeHTML = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  return (
    //     <div className="">
    //       <div className="page-header breadcrumb-wrap">
    //         <div className="container">
    //           <div className="breadcrumb">
    //             <a href="/" className="breadcrumb-item d-inline-block" title="Home"> Home </a>
    //             <span></span>
    //             <a href="/blog" className="breadcrumb-item d-inline-block" title="Home"> Blog </a>
    //             <span></span>
    //             <div className="breadcrumb-item d-inline-block active"> {data?.title} </div>
    //           </div>
    //         </div>
    //       </div>
    //       {loading &&
    //         <LoaderMain />
    //       }

    //       <div className="container">
    //         <section className="banners py-5">
    //           <div className="">
    //           <div className="col-lg-12 col-md-12">
    //           <a className="btn btn-lg mb-2 w-100"
    //                 style={{ fontSize: '18px' }}
    //               >
    //                 {data?.title}
    //               </a>
    //           </div>
    //             <div className="row justify-content-start">
    //               <div className="col-lg-12 col-md-12">
    //               <div class="ecommerce-gallery" data-mdb-ecommerce-gallery-init data-mdb-zoom-effect="true" data-mdb-auto-height="true">
    //   <div class="row py-3 shadow-5">
    //     <div class="col-12 mb-1">
    //       <div class="lightbox w-100 d-flex justify-content-center" data-mdb-lightbox-init>
    //         <img
    //           src={blogurl + data?.thumbnail}
    //           alt={data?.title}
    //           class="ecommerce-gallery-main-img active w-50"
    //           style={{borderRadius:'10px', maxHeight:'300px', backdropFilter:'inherit', backgroundPosition:'cover', alignItems:'center'}}
    //         />
    //       </div>
    //     </div>
    //     <div className="row my-3">
    //               <div className="col-lg-12 col-md-12">
    //                 <p
    //                   dangerouslySetInnerHTML={{
    //                     __html: decodeHTML(data?.content),
    //                   }}
    //                 />
    //               </div>
    //             </div>
    //     <SubImgContainer>
    //       <img
    //           src={blogurl + data?.image_1}
    //           data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/Vertical/14a.webp"
    //         alt={data?.title}
    //         class="active w-100"
    //       />
    //     </SubImgContainer>
    //     <SubImgContainer>
    //       <img
    //           src={blogurl + data?.image_2}
    //           data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/Vertical/12a.webp"
    //         alt={data?.title}
    //         class="w-100"
    //       />
    //     </SubImgContainer>
    //     <SubImgContainer>
    //       <img
    //           src={blogurl + data?.image_3}
    //           data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/Vertical/13a.webp"
    //         alt={data?.title}
    //         class="w-100"
    //       />
    //     </SubImgContainer>
    //     <SubImgContainer>
    //       <img
    //           src={blogurl + data?.image_4}
    //           data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/Vertical/15a.webp"
    //         alt={data?.title}
    //         class="w-100"
    //       />
    //     </SubImgContainer>
    //   </div>
    // </div>
    //               </div>
    //             </div>

    //           </div>
    //         </section>
    //       </div>
    //     </div>

    <main class="main" id="main-section">
      <div className="wrapper">
        <Header>
          <H2>{data?.title}</H2>
        </Header>

        <Details>
          <Top>
            <Left>
              <MainImgContainer>
                <img src={blogurl + data?.thumbnail} alt={data.title} />
              </MainImgContainer>
            </Left>
            <Right>
              <SubImgContainer>
                <img
                  src={blogurl + data?.image_1}
                  data-mdb-img={blogurl + data?.image_1}
                  alt={data?.title}
                  class="active w-100"
                />
              </SubImgContainer>
              <SubImgContainer>
                <img
                  src={blogurl + data?.image_2}
                  data-mdb-img={blogurl + data?.image_2}
                  alt={data?.title}
                  class="w-100"
                />
              </SubImgContainer>
              <SubImgContainer>
                <img
                  src={blogurl + data?.image_3}
                  data-mdb-img={blogurl + data?.image_3}
                  alt={data?.title}
                  class="w-100"
                />
              </SubImgContainer>
              <SubImgContainer>
                <img
                  src={blogurl + data?.image_4}
                  data-mdb-img={blogurl + data?.image_4}
                  alt={data?.title}
                  class="w-100"
                />
              </SubImgContainer>
            </Right>
          </Top>
          <div>
            <div className="row justify-content-start">
              <div className="col-lg-12 col-md-12">
                <div
                  class="ecommerce-gallery"
                  data-mdb-ecommerce-gallery-init
                  data-mdb-zoom-effect="true"
                  data-mdb-auto-height="true"
                >
                  <div class="row py-3 shadow-5">
                    <div className="row my-3">
                      <div className="col-lg-12 col-md-12">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: decodeHTML(data?.content),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="col-lg-3 primary-sidebar sticky-sidebar">
                <div class="widget-area">
                  <div class="sidebar-widget-2"></div>
                  <div class="sidebar-widget widget-category-2 mb-30">
                    <h5 class="section-title style-1 mb-30">Categories</h5>
                    <ul>
                      {catData.map((item, i) => (
                        <li key={i}>
                          <Link to={`/category/${item.category_slug}`}>
                            <img
                              data-bb-lazy="true"
                              loading="lazy"
                              src={categoryurl + item.logo}
                              alt={item.category_name}
                            ></img>
                            {item.category_name}
                          </Link>
                          <span class="count">{item.subcategory.length}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div class="sidebar-widget widget-tags mb-50 pb-10">
                    <h5 class="section-title style-1 mb-30">Popular Tags</h5>
                    <ul class="tags-list">
                      <li class="hover-up">
                        <a onClick={() => props?.setSearch("Kannadi")}>
                          <i class="fi-rs-cross mr-10"></i>
                          Kannadi
                        </a>
                      </li>
                      <li class="hover-up">
                        <a onClick={() => props?.setSearch("Mundu")}>
                          <i class="fi-rs-cross mr-10"></i>
                          Mundu
                        </a>
                      </li>
                      <li class="hover-up">
                        <a onClick={() => props?.setSearch("Amadapetti")}>
                          <i class="fi-rs-cross mr-10"></i>
                          Amadapetti
                        </a>
                      </li>
                      <li class="hover-up">
                        <a onClick={() => props?.setSearch("Balaramapuram")}>
                          <i class="fi-rs-cross mr-10"></i>
                          Balaramapuram
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
          </div>
          {loading && <LoaderMain />}
        </Details>
      </div>
    </main>
  );
}

export default BlogUserDetail;
const Header = styled.div`
  padding-top: 30px;
`;

const H2 = styled.h2`
  font-weight: 500;
  padding: 20px 0;
  @media screen and (max-width: 640px) {
    font-size: 25px;
  }
`;
const Details = styled.div`
  h4 {
    padding: 20px 0;
    font-weight: 600;
  }
  p:empty {
    display: none;
  }
`;
const Top = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;
const Left = styled.div``;
const Right = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  column-gap: 50px;
`;
const SubImgContainer = styled.div`
  height: 200px;
  width: 100%;
  border: 1px solid #00000033;
  border-radius: 10px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
  }

  &:nth-child(3),
  &:nth-child(4) {
    align-self: end;
  }
`;
const MainImgContainer = styled.div`
  height: 480px;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    border-radius: 10px;
  }
`;
