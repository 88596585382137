import { useEffect, useState } from "react";
import { backend, producturl } from "../../../../path";
import useRazorpay from "react-razorpay";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Button, Collapse } from "react-bootstrap";
import ScrollToTop from "../../../components/whatsapp connect";
import LoaderMain from "../../../layout/header/loader_sub";
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { HiOutlinePlus } from "react-icons/hi2";
import { LuMinus } from "react-icons/lu";

function BuyNow(props) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [datas, setDatas] = useState([]);
  const [couponSuccess, setCouponSuccess] = useState("");
  const [Razorpay] = useRazorpay();
  const [formData, setFormData] = useState({
    country: "102",
  });
  const [address, setAddress] = useState([]);
  const [delAd, setDelAd] = useState(null);
  const [newAdd, setNewAdd] = useState({
    country: "102",
  });
  const [count, setCountry] = useState([]);
  const [open, setOpen] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [newDelivery, setNewDelivery] = useState(false);
  const [coupon, setCoupon] = useState(0);
  const [couponId, setCouponId] = useState("");
  const navigate = useNavigate();
  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    // window.location.reload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setDatas(props.data);
  }, []);

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  const fetchCountryData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcountry", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCountry(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchAddress = () => {
    setLoading(true);

    fetch(backend + "getaddress", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Modify the object to include is_primary: 0
        // const updatedData = {
        //   ...data,
        //   is_primary: 0
        // };
        // Set the form data with the updated object
        setAddress(data);
        setFormData(data[0]);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCountryData();
    fetchAddress();
  }, []);

  const handlePayment = async (id, total, details) => {
    const options = {
      key: "rzp_live_VmDGOVv2NGo2Yi",
      amount: total * 100,
      currency: "INR",
      name: "Kerala Special",
      description: "Order Payment",
      image: "https://keralaspecial.in/storage/general/ks-logo32.png",
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        handleSuccessOrder(id, response.razorpay_payment_id);
      },
      prefill: {
        name: `${details?.first_name} ${details?.last_name}`,
        email: details?.email,
        contact: details?.contact,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.on("payment.success", function (response) {});
    rzp1.open();
  };

  const handleSubmit = () => {
    if (!formData) {
      toast.error(`Form fields cannot be empty`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // List of required fields and their corresponding display names
      const requiredFields = [
        { key: "first_name", displayName: "First Name" },
        {
          key: "house_name",
          displayName: "House Name / House Number / Apartment",
        },
        { key: "country", displayName: "Country" },
        { key: "state", displayName: "State" },
        { key: "city", displayName: "City" },
        { key: "pincode", displayName: "Pincode" },
        { key: "contact", displayName: "Contact" },
      ];
      // Check if any required field is missing or blank
      const invalidField = requiredFields.find((field) => {
        const value = formData[field.key];
        return typeof value !== "string" || value.trim() === ""; // Check if value is not a string or is empty or consists of only whitespace
      });

      if (invalidField) {
        toast.error(`${invalidField.displayName} cannot be blank`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return; // Stop further execution
      }

      fetch(backend + "addaddress", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (!response.ok) {
            console.error(
              "Failed to register:",
              response.status,
              response.statusText
            );
            if (response.status === 409) {
              toast.error("Something Went Wrong", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
            }

            return;
          }

          response.json().then((data) => {
            handleOrderAddress(data);
            fetchAddress();
            setOpen(false);
            // Additional success handling code here
            toast.success("Primary Address Updated", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
          // navigate("/admin-users");
        })
        .catch((error) => {});
    }
  };
  const handleSubmitNewAddress = () => {
    // List of required fields and their corresponding display names
    const requiredFields = [
      { key: "first_name", displayName: "First Name" },
      {
        key: "house_name",
        displayName: "House Name / House Number / Apartment",
      },
      { key: "country", displayName: "Country" },
      { key: "state", displayName: "State" },
      { key: "city", displayName: "City" },
      { key: "pincode", displayName: "Pincode" },
      { key: "contact", displayName: "Contact" },
    ];

    // Check if any required field is missing or blank
    const invalidField = requiredFields.find((field) => {
      const value = newAdd[field.key];
      return typeof value !== "string" || value.trim() === ""; // Check if value is not a string or is empty or consists of only whitespace
    });

    if (invalidField) {
      toast.error(`${invalidField.displayName} cannot be blank`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    newAdd.is_primary = 1;
    fetch(backend + "addaddress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
      body: JSON.stringify(newAdd),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {
          handleOrderAddress(data);
          fetchAddress();
          setOpen(false);
          setNewDelivery(false);
          toast.success("Address Added", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {});
  };

  const handleOrder = () => {
    if (delAd === null) {
      toast.error("Please choose a delivery address", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let payload = {};
    payload.delivery_id = delAd;
    payload.product_id = datas.product_id;
    payload.coupon_id = couponSuccess?.coupon_id;
    payload.quantity = props?.count;
    payload.gift_message = formData?.gift_message;

    fetch(backend + "createsingleorder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {
          handlePayment(data.order_no, data.grandTotalPrice, data.user_details);
          // Additional success handling code here
          // toast.success("Order Generated", {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {});
  };
  const handleOrderAddress = (item) => {
    let payload = {};
    payload.delivery_id = item.delivery_address_id;
    payload.product_id = datas.product_id;
    payload.quantity = props?.count;

    fetch(backend + "createsingleorder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {
          handlePayment(data.order_no, data.grandTotalPrice, data.user_details);
          // Additional success handling code here
          // toast.success("Order Generated", {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {});
  };

  const handleSuccessOrder = (order_id, pay_id) => {
    setLoading(true);

    let payload = {};
    payload.razorpay_payment_id = pay_id;
    payload.order_no = order_id;
    payload.product_id = datas.product_id;

    fetch(backend + "successfullpayment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {
          setLoading(false);
          toast.success("Order Placed Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        navigate("/success");
      })
      .catch((error) => {});
  };
  const handleCoupon = () => {
    let payload = {};
    payload.amount = datas?.mrp;
    payload.code = couponId;

    fetch(backend + "checkcoupon", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 401) {
            setCouponId("");
            toast.error("Invalid Coupon", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (response.status === 402) {
            toast.error("Not Applicable For this Amount", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (response.status === 403) {
            toast.error("Coupon Already Used", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          return;
        }
        response.json().then((data) => {
          setCouponId("");
          setCoupon(2);
          setCouponSuccess(data);
          toast.success("Coupon Applied", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {});
  };

  const handleDeleteAddress = async (id) => {
    try {
      const response = await fetch(backend + `deleteaddress/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(),
      });

      if (response.status === 200) {
        fetchAddress();
        toast.success("Address Deleted", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 409) {
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <main className="main " id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Your Cart </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="mb-80  ">
            <div className="row ">
              <div className="col-lg-12 d-none d-md-block">
                <div className="d-flex justify-content-between">
                  <H2>Your Orders</H2>
                  {/* {authenticated &&
                    <div>
                      {coupon === 1 ?
                        <div className="d-flex gap-3">
                          <input
                            type="text"
                            value={couponId ?? ''}
                            onChange={(event) =>
                              setCouponId(
                                event.target.value
                              )
                            }
                            className=""
                          />
                          <button
                            type="button"
                            className="btn hover-up btn-light"
                            style={{ backgroundColor: '#FFA62F' }}
                            onClick={handleCoupon}
                          >
                            Apply
                          </button>
                        </div>
                        : coupon === 0 ?
                          <button
                            type="button"
                            className="btn hover-up btn-light"
                            style={{ backgroundColor: 'red' }}
                            onClick={() => setCoupon(1)}
                          >
                            Apply Coupon
                          </button>
                          :
                          <button
                            type="button"
                            className="btn hover-up btn-light"
                            style={{ backgroundColor: '#ACD793' }}
                            disabled
                          >
                            Coupon Applied
                          </button>
                      }
                    </div>
                  } */}
                </div>
              </div>
            </div>
            <div className="">
              <div className="row product-grid mx-1">
                <>
                  <CartBox
                    className=" wow animate__animated animate__fadeIn "
                    data-wow-delay="0.1s"
                  >
                    <Section className="one">
                      {" "}
                      <div>
                        <a
                          onClick={() => handleProductRoute(datas.product_slug)}
                        >
                          <ImageContainer>
                            <img
                              className="default-img"
                              // src={mediaurl + data.images}
                              src={producturl + datas?.thumbnail}
                              // alt="Nestle Original Coffee-Mate Coffee Creamer"
                            />
                          </ImageContainer>
                          {/* <img
                              className="hover-img"
                              style={{
                                height: "200px",
                                width: "100%",
                                objectFit: "contain",
                                objectPosition: "center",
                              }}
                              src={producturl + datas?.thumbnail2}
                              // alt="Nestle Original Coffee-Mate Coffee Creamer"
                            /> */}
                        </a>
                      </div>
                      <div>
                        <Link
                          href={`/product/${datas.product_slug}`}
                          onClick={() => handleProductRoute(datas.product_slug)}
                        >
                          {datas?.product_name}
                        </Link>
                        <Link
                          className="smallLink"
                          onClick={() => handleProductRoute(datas.product_slug)}
                        >
                          {datas?.category_name}
                        </Link>

                        <div className="product-rate-cover">
                          <div className="d-inline-block">
                            <Rating
                              name="read-only"
                              value={datas?.average_stars}
                              readOnly
                              size="small"
                            />
                          </div>
                          <span
                            className="font-small ml-5 text-muted"
                            style={{ verticalAlign: "top" }}
                          >
                            ({datas?.average_stars ?? "Not Yet Rated"})
                          </span>
                        </div>
                      </div>
                    </Section>
                    <Section className="two">
                      {" "}
                      <div className="product-content-wrap">
                        <span>₹ {datas?.mrp} </span>
                      </div>
                      <QtyBtn className="mobile">
                        <Btn onClick={() => props.handleDecrement()}>
                          <LuMinus />
                        </Btn>
                        <div>
                          <input
                            type="number"
                            min="1"
                            value={props?.count}
                            // onChange={(event) =>
                            //   handleQuantityChange(
                            //     data.cart_id,
                            //     event.target.value
                            //   )
                            // }
                          />
                        </div>

                        <Btn onClick={() => props.handleIncrement()}>
                          <HiOutlinePlus />
                        </Btn>
                      </QtyBtn>
                      {/* <div className="d-flex justify-content-between">
                        <div className="product-card-bottom">
                          <div className="product-price">
                            <span className="d-flex">
                              {" "}
                              <div className="text-secondary mt-5">
                                {" "}
                                Total number of items : &nbsp;
                              </div>{" "}
                              <div className="detail-qty border radius">
                                <a className="qty-down">
                                  <i
                                    className="fi-rs-angle-small-down"
                                    onClick={() => props.handleDecrement()}
                                  ></i>
                                </a>
                                <input
                                  type="number"
                                  min="1"
                                  value={props?.count}
                                  // onChange={(event) =>
                                  //   handleQuantityChange(
                                  //     data.cart_id,
                                  //     event.target.value
                                  //   )
                                  // }
                                  className="qty-val qty-input"
                                />
                                <a
                                  className="qty-up"
                                  // onClick={handleIncrement}
                                  onClick={() => props.handleIncrement()}
                                >
                                  <i className="fi-rs-angle-small-up"></i>
                                </a>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div> */}
                      {/* </div> */}
                    </Section>
                    <Section className="three">
                      <QtyBtn>
                        <Btn onClick={() => props.handleDecrement()}>
                          <LuMinus />
                        </Btn>
                        <div>
                            <input type="text" min="1" value={props?.count} />
                        </div>

                        <Btn onClick={() => props.handleIncrement()}>
                          <HiOutlinePlus />
                        </Btn>
                      </QtyBtn>
                    </Section>
                    <Section className="four">
                      <div>
                        <h3>
                          ₹ {datas?.mrp * props.count + datas?.shipping_charge}{" "}
                          {/* ({datas?.mrp * props.count}+{datas?.shipping_charge}) */}
                        </h3>
                      </div>
                    </Section>
                  </CartBox>
                </>
                <H2 htmlFor="name">
                  Send a heartfelt Onam message to your loved ones!
                </H2>{" "}
                <span className="label">
                  <button
                    type="button"
                    className="hover-up"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        gift_message:
                          "Though I'm miles away this Onam, my heart is with you at home. Happy Onam! Enjoy the sadhya, and know that I'll be thinking of you all.",
                      })
                    }
                    style={{
                      padding: "8px 12px",
                      backgroundColor: "#3bb77e",
                      color: "#fff",
                      borderRadius: "5px",
                      border: "none",
                      marginBottom: "5px",
                      marginRight: "10px",
                    }}
                  >
                    Though I'm miles away this Onam, my heart is with you at
                    home. Happy Onam! Enjoy the sadhya, and know that I'll be
                    thinking of you all.
                  </button>
                  <button
                    type="button"
                    className="hover-up"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        gift_message:
                          "Wishing my wonderful family a very Happy Onam!",
                      })
                    }
                    style={{
                      padding: "8px 12px",
                      backgroundColor: "#3bb77e",
                      color: "#fff",
                      borderRadius: "5px",
                      border: "none",
                      marginBottom: "5px",
                      marginRight: "10px",
                    }}
                  >
                    Wishing my wonderful family a very Happy Onam!
                  </button>
                  <button
                    type="button"
                    className="hover-up"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        gift_message:
                          "We will miss you Amma/Achan. Happy Onam to both of you!",
                      })
                    }
                    style={{
                      padding: "8px 12px",
                      backgroundColor: "#3bb77e",
                      color: "#fff",
                      borderRadius: "5px",
                      border: "none",
                      marginBottom: "5px",
                      marginRight: "10px",
                    }}
                  >
                    We will miss you Amma/Achan. Happy Onam to both of you!
                  </button>
                  <button
                    type="button"
                    className="hover-up"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        gift_message:
                          "Happy Onam to my dearest family! Here's to another beautiful Onam—together in spirit!",
                      })
                    }
                    style={{
                      padding: "8px 12px",
                      backgroundColor: "#3bb77e",
                      color: "#fff",
                      borderRadius: "5px",
                      border: "none",
                      marginBottom: "5px",
                      marginRight: "10px",
                    }}
                  >
                    Happy Onam to my dearest family! Here's to another beautiful
                    Onam—together in spirit!
                  </button>
                  <button
                    type="button"
                    className="hover-up"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        gift_message:
                          "Though I may not be there physically, I'm with you in spirit. May this Onam bring happiness and prosperity to our home. Wishing you all a very Happy Onam!",
                      })
                    }
                    style={{
                      padding: "8px 12px",
                      backgroundColor: "#3bb77e",
                      color: "#fff",
                      borderRadius: "5px",
                      border: "none",
                      marginBottom: "5px",
                      marginRight: "10px",
                    }}
                  >
                    Though I may not be there physically, I'm with you in
                    spirit. May this Onam bring happiness and prosperity to our
                    home. Wishing you all a very Happy Onam!
                  </button>
                </span>
                <input
                  name="product_name"
                  id="txt-name"
                  type="text"
                  style={{ border: "2px solid #3bb77e", padding: "10px" }}
                  // required
                  value={formData?.gift_message || ""}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      gift_message: e.target.value,
                    })
                  }
                  // placeholder="Share a special Onam message for your loved ones"
                  placeholder="Add a Special Message"
                />
                <>
                  {!delivery ? (
                    <>
                      <SubTotalContainer>
                        <SubTotal>
                          <h3>Cart Total </h3>
                          <Text>
                            <p>Product Total</p>
                            <p>: </p>
                            {couponSuccess ? (
                              <>
                                <s style={{ color: "#615EFC" }}>
                                  {" "}
                                  {datas?.mrp * props.count}
                                </s>{" "}
                                <div>
                                  ₹
                                  {datas?.mrp * props.count -
                                    couponSuccess?.amount}
                                </div>{" "}
                              </>
                            ) : (
                              <div> ₹{datas?.mrp * props.count}</div>
                            )}
                          </Text>
                          <Text>
                            <p>Shipping Charges</p>
                            <p>:</p>
                            <p>₹{datas?.shipping_charge}</p>
                          </Text>
                          <Text className="total">
                            <p>Sub Total</p>
                            <p>:</p>
                            <p>
                              {couponSuccess ? (
                                <>
                                  <s style={{ color: "#3bb77e" }}>
                                    ₹{" "}
                                    {datas?.mrp * props.count +
                                      datas?.shipping_charge}
                                  </s>{" "}
                                  <div>
                                    ₹{" "}
                                    {datas?.mrp * props.count -
                                      couponSuccess?.amount +
                                      datas?.shipping_charge}
                                  </div>{" "}
                                </>
                              ) : (
                                <div>
                                  {" "}
                                  ₹{" "}
                                  {datas?.mrp * props.count +
                                    datas?.shipping_charge}
                                </div>
                              )}
                            </p>
                          </Text>
                          <button
                            type="button"
                            className="btn btn-fill-out btn-block hover-up"
                            // onClick={handlePayment}
                            onClick={() => setDelivery(!delivery)}
                            aria-controls="delivery-full"
                            aria-expanded={delivery}
                          >
                            Continue
                          </button>{" "}
                        </SubTotal>
                      </SubTotalContainer>

                      {/* <div className="d-flex justify-content-end gap-5 mt-5">
                        <div className="d-flex justify-content-between gap-5 mt-5"></div>
                      </div> */}
                    </>
                  ) : (
                    <div className="d-flex justify-content-end gap-5 mt-5">
                      <div className="d-flex justify-content-between gap-5 mt-5">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up"
                          // onClick={handlePayment}
                          onClick={() => setDelivery(!delivery)}
                          aria-controls="delivery-full"
                          aria-expanded={delivery}
                        >
                          Back
                        </button>{" "}
                      </div>
                    </div>
                  )}
                </>
                <StyledCollapse in={delivery}>
                  <div className="mt-3" id="delivery-full">
                    <H2>Delivery Address</H2>

                    <div className="py-3">
                      <div>
                        {" "}
                        <>
                          {address[0]?.city ? (
                            <Button
                              onClick={() => {
                                setNewDelivery(!newDelivery);
                                setOpen(false);
                              }}
                              aria-controls="example-new-text"
                              aria-expanded={newDelivery}
                            >
                              {" "}
                              Add a New Delivery Address
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                setNewDelivery(false);
                                setOpen(!open);
                              }}
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                            >
                              Edit Primary Address
                            </Button>
                          )}

                          {/* <Button
                              onClick={() => {
                                setNewDelivery(false); setOpen(!open);
                              }}
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                            >Edit Primary Address
                            </Button> */}
                        </>
                        <div
                          className=""
                          style={{
                            textAlign: "start",
                            color: "#3bb77e",
                          }}
                        >
                          {address?.map((item, i) => (
                            <div
                              className="my-3 d-flex justify-content-between"
                              key={i}
                            >
                              <div>
                                <h3
                                  className="heading-4 product-price"
                                  style={{ color: "#3bb77e" }}
                                >
                                  <input
                                    className="form-check-input mt-2"
                                    type="radio"
                                    name="delivery_address"
                                    id={`delivery-address-${i}`}
                                    onChange={() =>
                                      setDelAd(item?.delivery_address_id)
                                    }
                                  />{" "}
                                  {item?.first_name} {item?.last_name}
                                </h3>
                                {item?.house_name &&
                                item?.city &&
                                item?.pincode ? (
                                  <h5
                                    className="heading-4 product-price"
                                    style={{ color: "#3bb77e" }}
                                  >
                                    {item?.house_name}, {item?.street_name},{" "}
                                    {item?.city}, {item?.state},{" "}
                                    {item?.country_name}, {item?.pincode}, PH NO
                                    : {item?.contact}
                                  </h5>
                                ) : (
                                  <>No more details</>
                                )}
                              </div>
                              <button
                                type="button"
                                className="hover-up"
                                style={{
                                  backgroundColor: "#3bb77e",
                                  color: "#fff",
                                  borderRadius: "5px",
                                  border: "none",
                                  height: "20px",
                                  width: "100px",
                                }}
                                onClick={() =>
                                  handleDeleteAddress(item?.delivery_address_id)
                                }
                              >
                                Delete
                              </button>{" "}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <StyledCollapse in={open}>
                      <div
                        className="pt-4"
                        data-wow-delay="0.1s"
                        id="example-collapse-text"
                      >
                        <div className="form__content">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="name">First Name</label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="product_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  value={formData?.first_name || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      first_name: e.target.value,
                                    })
                                  }
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="name">Last Name</label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="last_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  value={formData?.last_name || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      last_name: e.target.value,
                                    })
                                  }
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                              <div className="  ">
                                <label htmlFor="name">
                                  House Name / House Number / Apartment
                                </label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="house_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  value={formData?.house_name || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      house_name: e.target.value,
                                    })
                                  }
                                  placeholder="House Name / House Number / Apartment"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="name">Street Name</label>{" "}
                                {/* <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup> */}
                                <input
                                  name="street_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  value={formData?.street_name || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      street_name: e.target.value,
                                    })
                                  }
                                  placeholder="Street Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="city">City</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="city"
                                  required
                                  value={formData?.city || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      city: e.target.value,
                                    })
                                  }
                                  placeholder="City"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="state">State</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="state"
                                  required
                                  value={formData?.state || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      state: e.target.value,
                                    })
                                  }
                                  placeholder="State"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="country">Country</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <select
                                  name="category_name"
                                  id="txt-name"
                                  required
                                  value={formData?.country || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      country: e.target.value,
                                    })
                                  }
                                >
                                  <option>- select -</option>
                                  {count.map((val) => {
                                    return (
                                      <option value={val.country_id}>
                                        {val.countryname}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="pincode">Pin Code</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="pincode"
                                  required
                                  value={formData?.pincode || ""}
                                  maxLength={6}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      pincode: e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      ),
                                    })
                                  }
                                  placeholder="Pincode"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="street_name">
                                  Contact Number
                                </label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="phone"
                                  minLength={6}
                                  maxLength={12}
                                  required
                                  value={formData?.contact || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      contact: e.target.value.replace(
                                        /[^0-9+]/g,
                                        ""
                                      ),
                                    })
                                  }
                                  placeholder="Primary contact number"
                                />
                              </div>
                            </div>
                            <div className="login_footer   ">
                              <div className="chek-form">
                                <div className="custome-checkbox">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="agree_terms_and_policy"
                                    id="agree-terms-and-policy"
                                    checked={formData?.is_primary === 0} // Check if is_primary is 1
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        is_primary: e.target.checked ? 0 : 1, // Set to 1 if checked, 0 otherwise
                                      })
                                    }
                                  />

                                  <label
                                    className="form-check-label"
                                    for="agree-terms-and-policy"
                                  >
                                    <span>
                                      <a>Set this as my primary address.</a>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <SubTotalContainer>
                              <SubTotal>
                                <h3>Cart Total </h3>
                                <Text>
                                  <p>Product Total</p>
                                  <p>: </p>
                                  {couponSuccess ? (
                                    <>
                                      <s style={{ color: "#615EFC" }}>
                                        {" "}
                                        {datas?.mrp * props.count}
                                      </s>{" "}
                                      <div>
                                        ₹
                                        {datas?.mrp * props.count -
                                          couponSuccess?.amount}
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <div> ₹{datas?.mrp * props.count}</div>
                                  )}
                                </Text>
                                <Text>
                                  <p>Shipping Charges</p>
                                  <p>:</p>
                                  <p>₹{datas?.shipping_charge}</p>
                                </Text>
                                <Text className="total">
                                  <p>Sub Total</p>
                                  <p>:</p>
                                  <p>
                                    {couponSuccess ? (
                                      <>
                                        <s style={{ color: "#3bb77e" }}>
                                          ₹{" "}
                                          {datas?.mrp * props.count +
                                            datas?.shipping_charge}
                                        </s>{" "}
                                        <div>
                                          ₹{" "}
                                          {datas?.mrp * props.count -
                                            couponSuccess?.amount +
                                            datas?.shipping_charge}
                                        </div>{" "}
                                      </>
                                    ) : (
                                      <div>
                                        {" "}
                                        ₹{" "}
                                        {datas?.mrp * props.count +
                                          datas?.shipping_charge}
                                      </div>
                                    )}
                                  </p>
                                </Text>
                                <button
                                  type="button"
                                  className="btn hover-up"
                                  style={{ backgroundColor: "red" }}
                                  onClick={() => handleSubmit()}
                                >
                                  Submit
                                </button>
                              </SubTotal>
                            </SubTotalContainer>
                          </div>
                        </div>
                      </div>
                    </StyledCollapse>
                    <Collapse in={newDelivery}>
                      <div
                        className="pt-4"
                        data-wow-delay="0.1s"
                        id="example-new-text"
                      >
                        <div className="form__content">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="name">First Name</label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="product_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd?.first_name || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      first_name: e.target.value,
                                    })
                                  }
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="name">Last Name</label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="last_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd?.last_name || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      last_name: e.target.value,
                                    })
                                  }
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                              <div className="  ">
                                <label htmlFor="name">
                                  House Name / House Number / Apartment
                                </label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="house_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd.house_name || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      house_name: e.target.value,
                                    })
                                  }
                                  placeholder="House Name / House Number / Apartment"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="name">Street Name</label>{" "}
                                <input
                                  name="street_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd.street_name || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      street_name: e.target.value,
                                    })
                                  }
                                  placeholder="Street Name"
                                />
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="city">City</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="city"
                                  required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd.city || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      city: e.target.value,
                                    })
                                  }
                                  placeholder="City"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="state">State</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="state"
                                  required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd.state || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      state: e.target.value,
                                    })
                                  }
                                  placeholder="State"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="country">Country</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <select
                                  name="category_name"
                                  id="txt-name"
                                  required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd.country || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      country: e.target.value,
                                    })
                                  }
                                >
                                  <option>- select -</option>
                                  {count.map((val) => {
                                    return (
                                      <option value={val.country_id}>
                                        {val.countryname}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="pincode">Pin Code</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="pincode"
                                  required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd.pincode || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      pincode: e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      ),
                                    })
                                  }
                                  placeholder="Pincode"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="street_name">
                                  Contact Number
                                </label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="phone"
                                  minLength={6}
                                  maxLength={12}
                                  required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd.contact || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      contact: e.target.value.replace(
                                        /[^0-9+]/g,
                                        ""
                                      ),
                                    })
                                  }
                                  placeholder="Primary contact number"
                                />
                              </div>
                            </div>
                            {/* <div className="login_footer   ">
                              <div className="chek-form">
                                <div className="custome-checkbox">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="agree_terms_and_policy"
                                    id="agree-terms-and-policy"
                                    checked={newAdd.is_primary === 0}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        is_primary: e.target.checked ? 0 : 1,                                      })
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for="agree-terms-and-policy"
                                  >
                                    <span>
                                      <a>
                                        Set this as my primary address.</a>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div> */}
                            <div className="d-flex justify-content-end gap-5 ">
                              <div className="d-flex justify-content-between gap-5">
                                <h3 className="heading-4 product-price text-white">
                                  SUBTOTAL{" "}
                                </h3>
                                <h3 className="heading-4 product-price text-white">
                                  {couponSuccess ? (
                                    <>
                                      <s style={{ color: "#B06161" }}>
                                        ₹{" "}
                                        {datas?.mrp * props.count +
                                          datas?.shipping_charge}
                                      </s>{" "}
                                      <div>
                                        ₹{" "}
                                        {datas?.mrp * props.count -
                                          couponSuccess?.amount +
                                          datas?.shipping_charge}
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <div>
                                      {" "}
                                      ₹{" "}
                                      {datas?.mrp * props.count +
                                        datas?.shipping_charge}
                                    </div>
                                  )}
                                </h3>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end ">
                              <div className="d-flex justify-content-end ">
                                <h5 className="heading-4 product-price text-secondary">
                                  (PRODUCT TOTAL ₹{" "}
                                </h5>
                                <h5 className="heading-4 product-price text-secondary">
                                  {couponSuccess ? (
                                    <>
                                      <s style={{ color: "#B06161" }}>
                                        {" "}
                                        {datas?.mrp * props.count}
                                      </s>{" "}
                                      <div>
                                        {" "}
                                        (
                                        {datas?.mrp * props.count -
                                          couponSuccess?.amount}
                                        )
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <div> ({datas?.mrp * props.count})</div>
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end ">
                              <div className="d-flex justify-content-start ">
                                <h5 className="heading-4 product-price text-secondary">
                                  (SHIPPING CHARGE ₹{" "}
                                </h5>
                                <h5 className="heading-4 product-price text-secondary">
                                  {datas?.shipping_charge})
                                </h5>
                              </div>
                            </div>
                            <div className="  ">
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block hover-up btn-secondary"
                                onClick={() => handleSubmitNewAddress()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                    {!newDelivery && !open && (
                      <>
                        <SubTotalContainer>
                          <SubTotal>
                            <h3>Cart Total </h3>
                            <Text>
                              <p>Product Total</p>
                              <p>: </p>
                              {couponSuccess ? (
                                <>
                                  <s style={{ color: "#615EFC" }}>
                                    {" "}
                                    {datas?.mrp * props.count}
                                  </s>{" "}
                                  <div>
                                    ₹
                                    {datas?.mrp * props.count -
                                      couponSuccess?.amount}
                                  </div>{" "}
                                </>
                              ) : (
                                <div> ₹{datas?.mrp * props.count}</div>
                              )}
                            </Text>
                            <Text>
                              <p>Shipping Charges</p>
                              <p>:</p>
                              <p>₹{datas?.shipping_charge}</p>
                            </Text>
                            <Text className="total">
                              <p>Sub Total</p>
                              <p>:</p>
                              <p>
                                {couponSuccess ? (
                                  <>
                                    <s style={{ color: "#3bb77e" }}>
                                      ₹{" "}
                                      {datas?.mrp * props.count +
                                        datas?.shipping_charge}
                                    </s>{" "}
                                    <div>
                                      ₹{" "}
                                      {datas?.mrp * props.count -
                                        couponSuccess?.amount +
                                        datas?.shipping_charge}
                                    </div>{" "}
                                  </>
                                ) : (
                                  <div>
                                    {" "}
                                    ₹{" "}
                                    {datas?.mrp * props.count +
                                      datas?.shipping_charge}
                                  </div>
                                )}
                              </p>
                            </Text>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block btn-secondary hover-up"
                              // onClick={handlePayment}
                              onClick={handleOrder}
                            >
                              Check Out
                            </button>
                          </SubTotal>
                        </SubTotalContainer>
                      </>
                    )}
                  </div>
                </StyledCollapse>
              </div>
            </div>
            <div className="d-block d-md-none d-lg-none">
              <div className="row product-grid">
                {!loading && datas.length > 0 && (
                  <>
                    <SubTotalContainer>
                      <SubTotal>
                        <h3>Cart Total </h3>
                        <Text>
                          <p>Product Total</p>
                          <p>: </p>
                          {couponSuccess ? (
                            <>
                              <s style={{ color: "#615EFC" }}>
                                {" "}
                                {datas?.mrp * props.count}
                              </s>{" "}
                              <div>
                                ₹
                                {datas?.mrp * props.count -
                                  couponSuccess?.amount}
                              </div>{" "}
                            </>
                          ) : (
                            <div> ₹{datas?.mrp * props.count}</div>
                          )}
                        </Text>
                        <Text>
                          <p>Shipping Charges</p>
                          <p>:</p>
                          <p>₹{datas?.shipping_charge}</p>
                        </Text>
                        <Text className="total">
                          <p>Sub Total</p>
                          <p>:</p>
                          <p>
                            {couponSuccess ? (
                              <>
                                <s style={{ color: "#3bb77e" }}>
                                  ₹{" "}
                                  {datas?.mrp * props.count +
                                    datas?.shipping_charge}
                                </s>{" "}
                                <div>
                                  ₹{" "}
                                  {datas?.mrp * props.count -
                                    couponSuccess?.amount +
                                    datas?.shipping_charge}
                                </div>{" "}
                              </>
                            ) : (
                              <div>
                                {" "}
                                ₹{" "}
                                {datas?.mrp * props.count +
                                  datas?.shipping_charge}
                              </div>
                            )}
                          </p>
                        </Text>
                        {!delivery ? (
                          <div className="d-flex justify-content-end gap-5 mt-5">
                            <div className="d-flex justify-content-between gap-5 mt-5">
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block hover-up"
                                // onClick={handlePayment}
                                onClick={() => setDelivery(!delivery)}
                                aria-controls="delivery-full"
                                aria-expanded={delivery}
                              >
                                Continue
                              </button>{" "}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-end gap-5 mt-5">
                            <div className="d-flex justify-content-between gap-5 mt-5">
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block hover-up"
                                // onClick={handlePayment}
                                onClick={() => setDelivery(!delivery)}
                                aria-controls="delivery-full"
                                aria-expanded={delivery}
                              >
                                Back
                              </button>{" "}
                            </div>
                          </div>
                        )}
                      </SubTotal>
                    </SubTotalContainer>
                    {/* <div className="d-flex justify-content-end gap-5 ">
                      <div className="d-flex justify-content-between gap-5">
                        <h3 className="heading-4 product-price text-primary">
                          SUBTOTAL
                        </h3>
                        <h3 className="heading-4 product-price text-primary">
                          {couponSuccess ? (
                            <>
                              <s style={{ color: "#3bb77e" }}>
                                ₹{" "}
                                {datas?.mrp * props.count +
                                  datas?.shipping_charge}
                              </s>{" "}
                              <div>
                                ₹{" "}
                                {datas?.mrp * props.count -
                                  couponSuccess?.amount +
                                  datas?.shipping_charge}
                              </div>{" "}
                            </>
                          ) : (
                            <div>
                              {" "}
                              ₹{" "}
                              {datas?.mrp * props.count +
                                datas?.shipping_charge}
                            </div>
                          )}
                        </h3>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end ">
                      <div className="d-flex justify-content-end ">
                        <h5 className="heading-4 product-price text-secondary">
                          (PRODUCT TOTAL ₹{" "}
                        </h5>
                        <h5 className="heading-4 product-price text-secondary">
                          {couponSuccess ? (
                            <>
                              <s style={{ color: "#615EFC" }}>
                                {" "}
                                {datas?.mrp * props.count}
                              </s>{" "}
                              <div>
                                {" "}
                                {datas?.mrp * props.count -
                                  couponSuccess?.amount}
                              </div>{" "}
                            </>
                          ) : (
                            <div> {datas?.mrp * props.count}</div>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end ">
                      <div className="d-flex justify-content-start ">
                        <h5 className="heading-4 product-price text-secondary">
                          (SHIPPING CHARGE ₹{" "}
                        </h5>
                        <h5 className="heading-4 product-price text-secondary">
                          {datas?.shipping_charge})
                        </h5>
                      </div>
                    </div>
                    {!delivery ? (
                      <div className="d-flex justify-content-end gap-5 mt-5">
                        <div className="d-flex justify-content-between gap-5 mt-5">
                          <button
                            type="button"
                            className="btn btn-fill-out btn-block hover-up"
                            // onClick={handlePayment}
                            onClick={() => setDelivery(!delivery)}
                            aria-controls="delivery-full"
                            aria-expanded={delivery}
                          >
                            Continue
                          </button>{" "}
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end gap-5 mt-5">
                        <div className="d-flex justify-content-between gap-5 mt-5">
                          <button
                            type="button"
                            className="btn btn-fill-out btn-block hover-up"
                            // onClick={handlePayment}
                            onClick={() => setDelivery(!delivery)}
                            aria-controls="delivery-full"
                            aria-expanded={delivery}
                          >
                            Back
                          </button>{" "}
                        </div>
                      </div>
                    )} */}
                  </>
                )}
                <StyledCollapse in={delivery}>
                  <div className="mt-3" id="delivery-full">
                    <div className="col-lg-12">
                      <div className="d-flex justify-content-start">
                        <H2>Delivery Address</H2>
                      </div>
                    </div>
                    <div className="pt-4">
                      <div
                        className=" p-3 rounded border border-warning"
                        style={{
                          backgroundColor: "#fff",
                          alignContent: "start",
                        }}
                      >
                        <h6 className="heading-2 border-bottom pb-1 text-info d-flex justify-content-between">
                          {" "}
                          <>
                            <Button
                              onClick={() => {
                                setNewDelivery(!newDelivery);
                                setOpen(false);
                              }}
                              aria-controls="example-new-text"
                              aria-expanded={newDelivery}
                            >
                              {" "}
                              Add a New Delivery Address
                            </Button>
                            {/* <Button
                              onClick={() => {
                                setNewDelivery(false); setOpen(!open);
                              }}
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                            >Edit Primary Address
                            </Button> */}
                          </>
                        </h6>
                        <div
                          className=""
                          style={{
                            textAlign: "start",
                            color: "#3bb77e",
                          }}
                        >
                          {address?.map((item, i) => (
                            <div className="my-3" key={i}>
                              <div>
                                <h3
                                  className="heading-4 product-price"
                                  style={{ color: "#3bb77e" }}
                                >
                                  <input
                                    className="form-check-input mt-2"
                                    type="radio"
                                    name="delivery_address"
                                    id={`delivery-address-${i}`}
                                    onChange={() =>
                                      setDelAd(item?.delivery_address_id)
                                    }
                                  />{" "}
                                  {item?.first_name} {item?.last_name}
                                  &nbsp;
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() =>
                                      handleDeleteAddress(
                                        item?.delivery_address_id
                                      )
                                    }
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 48 48"
                                  >
                                    <path
                                      fill="#3bb77e"
                                      d="M20 10.5v.5h8v-.5a4 4 0 0 0-8 0m-2.5.5v-.5a6.5 6.5 0 1 1 13 0v.5h11.25a1.25 1.25 0 1 1 0 2.5h-2.917l-2 23.856A7.25 7.25 0 0 1 29.608 44H18.392a7.25 7.25 0 0 1-7.224-6.644l-2-23.856H6.25a1.25 1.25 0 1 1 0-2.5zm4 9.25a1.25 1.25 0 1 0-2.5 0v14.5a1.25 1.25 0 1 0 2.5 0zM27.75 19c-.69 0-1.25.56-1.25 1.25v14.5a1.25 1.25 0 1 0 2.5 0v-14.5c0-.69-.56-1.25-1.25-1.25"
                                    />
                                  </svg>
                                </h3>
                                {item?.house_name &&
                                item?.city &&
                                item?.pincode ? (
                                  <h5
                                    className="heading-4 product-price"
                                    style={{ color: "#3bb77e" }}
                                  >
                                    {item?.house_name}, {item?.street_name},{" "}
                                    {item?.city}, {item?.state},{" "}
                                    {item?.country_name}, {item?.pincode}, PH NO
                                    : {item?.contact}
                                  </h5>
                                ) : (
                                  <>No more details</>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <StyledCollapse in={open}>
                      <div
                        className="pt-4"
                        data-wow-delay="0.1s"
                        id="example-collapse-text"
                      >
                        <div className="form__content">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="name">First Name</label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="product_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  value={formData?.first_name || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      first_name: e.target.value,
                                    })
                                  }
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="name">Last Name</label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="last_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  value={formData?.last_name || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      last_name: e.target.value,
                                    })
                                  }
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                              <div className="  ">
                                <label htmlFor="name">
                                  House Name / House Number / Apartment
                                </label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="house_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  value={formData?.house_name || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      house_name: e.target.value,
                                    })
                                  }
                                  placeholder="House Name / House Number / Apartment"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="name">Street Name</label>{" "}
                                {/* <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup> */}
                                <input
                                  name="street_name"
                                  id="txt-name"
                                  type="text"
                                  // required
                                  value={formData?.street_name || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      street_name: e.target.value,
                                    })
                                  }
                                  placeholder="Street Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="city">City</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="city"
                                  required
                                  value={formData?.city || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      city: e.target.value,
                                    })
                                  }
                                  placeholder="City"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="state">State</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="state"
                                  required
                                  value={formData?.state || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      state: e.target.value,
                                    })
                                  }
                                  placeholder="State"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="country">Country</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <select
                                  name="category_name"
                                  id="txt-name"
                                  required
                                  value={formData?.country || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      country: e.target.value,
                                    })
                                  }
                                >
                                  <option>- select -</option>
                                  {count.map((val) => {
                                    return (
                                      <option value={val.country_id}>
                                        {val.countryname}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="pincode">Pin Code</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="pincode"
                                  required
                                  value={formData?.pincode || ""}
                                  maxLength={6}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      pincode: e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      ),
                                    })
                                  }
                                  placeholder="Pincode"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-6">
                              <div className="  ">
                                <label htmlFor="street_name">
                                  Contact Number
                                </label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="phone"
                                  minLength={6}
                                  maxLength={12}
                                  required
                                  value={formData?.contact || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      contact: e.target.value.replace(
                                        /[^0-9+]/g,
                                        ""
                                      ),
                                    })
                                  }
                                  placeholder="Primary contact number"
                                />
                              </div>
                            </div>
                            <div className="login_footer   ">
                              <div className="chek-form">
                                <div className="custome-checkbox">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="agree_terms_and_policy"
                                    id="agree-terms-and-policy"
                                    checked={formData?.is_primary === 0} // Check if is_primary is 1
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        is_primary: e.target.checked ? 0 : 1, // Set to 1 if checked, 0 otherwise
                                      })
                                    }
                                  />

                                  <label
                                    className="form-check-label"
                                    for="agree-terms-and-policy"
                                  >
                                    <span>
                                      <a>Set this as my primary address.</a>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end gap-5 ">
                              <div className="d-flex justify-content-between gap-5">
                                <h3 className="heading-4 product-price text-success">
                                  SUBTOTAL
                                </h3>
                                <h3 className="heading-4 product-price text-success">
                                  {couponSuccess ? (
                                    <>
                                      <s style={{ color: "#3bb77e" }}>
                                        ₹{" "}
                                        {datas?.mrp * props.count +
                                          datas?.shipping_charge}
                                      </s>{" "}
                                      <div>
                                        ₹{" "}
                                        {datas?.mrp * props.count -
                                          couponSuccess?.amount +
                                          datas?.shipping_charge}
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <div>
                                      {" "}
                                      ₹{" "}
                                      {datas?.mrp * props.count +
                                        datas?.shipping_charge}
                                    </div>
                                  )}
                                </h3>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end ">
                              <div className="d-flex justify-content-end ">
                                <h5 className="heading-4 product-price text-secondary">
                                  (PRODUCT TOTAL ₹{" "}
                                </h5>
                                <h5 className="heading-4 product-price text-secondary">
                                  {couponSuccess ? (
                                    <>
                                      <s style={{ color: "#615EFC" }}>
                                        {" "}
                                        {datas?.mrp * props.count}
                                      </s>{" "}
                                      <div>
                                        {" "}
                                        {datas?.mrp * props.count -
                                          couponSuccess?.amount}
                                        )
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <div> {datas?.mrp * props.count})</div>
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end ">
                              <div className="d-flex justify-content-start ">
                                <h5 className="heading-4 product-price text-secondary">
                                  (SHIPPING CHARGE ₹{" "}
                                </h5>
                                <h5 className="heading-4 product-price text-secondary">
                                  {datas?.shipping_charge})
                                </h5>
                              </div>
                            </div>
                            <div className="  ">
                              <button
                                type="button"
                                className="btn hover-up"
                                style={{ backgroundColor: "red" }}
                                onClick={() => handleSubmit()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </StyledCollapse>
                    <StyledCollapse in={newDelivery}>
                      <div
                        className="pt-4"
                        data-wow-delay="0.1s"
                        id="example-new-text"
                      >
                        <div className="form__content">
                          <div className="row">
                            <div className=" col-12">
                              <div className="  ">
                                <label htmlFor="name">First Name</label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="product_name"
                                  id="txt-name"
                                  type="text"
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd?.first_name || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      first_name: e.target.value,
                                    })
                                  }
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                            <div className=" col-12">
                              <div className="  ">
                                <label htmlFor="name">Last Name</label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="last_name"
                                  id="txt-name"
                                  type="text"
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd?.last_name || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      last_name: e.target.value,
                                    })
                                  }
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                            <div className=" col-12">
                              <div className="  ">
                                <label htmlFor="name">
                                  House Name / House Number / Apartment
                                </label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="house_name"
                                  id="txt-name"
                                  type="text"
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd?.house_name || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      house_name: e.target.value,
                                    })
                                  }
                                  placeholder="House Name / House Number / Apartment"
                                />
                              </div>
                            </div>
                            <div className=" col-12">
                              <div className="  ">
                                <label htmlFor="name">Street Name</label>{" "}
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  name="street_name"
                                  id="txt-name"
                                  type="text"
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd?.street_name || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      street_name: e.target.value,
                                    })
                                  }
                                  placeholder="Street Name"
                                />
                              </div>
                            </div>

                            <div className=" col-12">
                              <div className="  ">
                                <label htmlFor="city">City</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="city"
                                  required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd?.city || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      city: e.target.value,
                                    })
                                  }
                                  placeholder="City"
                                />
                              </div>
                            </div>
                            <div className=" col-12">
                              <div className="  ">
                                <label htmlFor="state">State</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="state"
                                  required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd?.state || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      state: e.target.value,
                                    })
                                  }
                                  placeholder="State"
                                />
                              </div>
                            </div>
                            <div className=" col-12">
                              <div className="  ">
                                <label htmlFor="country">Country</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <select
                                  name="category_name"
                                  id="txt-name"
                                  required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd?.country || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      country: e.target.value,
                                    })
                                  }
                                >
                                  <option>- select -</option>
                                  {count.map((val) => {
                                    return (
                                      <option value={val.country_id}>
                                        {val.countryname}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div className=" col-12">
                              <div className="  ">
                                <label htmlFor="pincode">Pin Code</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="pincode"
                                  required
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  value={newAdd?.pincode || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      pincode: e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      ),
                                    })
                                  }
                                  placeholder="Pincode"
                                />
                              </div>
                            </div>
                            <div className=" col-12">
                              <div className="  ">
                                <label htmlFor="street_name">
                                  Contact Number
                                </label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  type="text"
                                  name="phone"
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    border: "1px solid #000",
                                  }}
                                  minLength={6}
                                  maxLength={12}
                                  required
                                  value={newAdd?.contact || ""}
                                  onChange={(e) =>
                                    setNewAdd({
                                      ...newAdd,
                                      contact: e.target.value.replace(
                                        /[^0-9+]/g,
                                        ""
                                      ),
                                    })
                                  }
                                  placeholder="Primary contact number"
                                />
                              </div>
                            </div>
                            {/* <div className="login_footer   ">
                              <div className="chek-form">
                                <div className="custome-checkbox">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="agree_terms_and_policy"
                                    id="agree-terms-and-policy"
                                    checked={newAdd.is_primary === 0}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        is_primary: e.target.checked ? 0 : 1,                                      })
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for="agree-terms-and-policy"
                                  >
                                    <span>
                                      <a>
                                        Set this as my primary address.</a>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div> */}

                            <div className="d-flex justify-content-end gap-5 ">
                              <div className="d-flex justify-content-between gap-5">
                                <h5 className="heading-4 product-price text-white">
                                  SUBTOTAL{" "}
                                </h5>
                                <h5 className="heading-4 product-price text-white">
                                  {couponSuccess ? (
                                    <>
                                      <s style={{ color: "#B06161" }}>
                                        ₹{" "}
                                        {datas?.mrp * props.count +
                                          datas?.shipping_charge}
                                      </s>{" "}
                                      <div>
                                        ₹{" "}
                                        {datas?.mrp * props.count -
                                          couponSuccess?.amount +
                                          datas?.shipping_charge}
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <div>
                                      {" "}
                                      ₹{" "}
                                      {datas?.mrp * props.count +
                                        datas?.shipping_charge}
                                    </div>
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end ">
                              <div className="d-flex justify-content-end ">
                                <h6 className="heading-4 product-price text-secondary">
                                  (PRODUCT TOTAL ₹{" "}
                                </h6>
                                <h6 className="heading-4 product-price text-secondary">
                                  {couponSuccess ? (
                                    <>
                                      <s style={{ color: "#B06161" }}>
                                        {" "}
                                        {datas?.mrp * props.count}
                                      </s>{" "}
                                      <div>
                                        {" "}
                                        {datas?.mrp * props.count -
                                          couponSuccess?.amount}
                                        )
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <div> {datas?.mrp * props.count})</div>
                                  )}
                                </h6>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end ">
                              <div className="d-flex justify-content-start ">
                                <h6 className="heading-4 product-price text-secondary">
                                  (SHIPPING CHARGE ₹{" "}
                                </h6>
                                <h6 className="heading-4 product-price text-secondary">
                                  {datas?.shipping_charge})
                                </h6>
                              </div>
                            </div>
                            <div className="   mt-20">
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block hover-up btn-secondary"
                                onClick={() => handleSubmitNewAddress()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </StyledCollapse>
                    {!newDelivery && !open && (
                      <>
                        <div className="d-flex justify-content-end gap-5 ">
                          <div className="d-flex justify-content-between gap-5">
                            <h5 className="heading-4 product-price text-white">
                              SUBTOTAL
                            </h5>
                            <h5 className="heading-4 product-price text-white">
                              {couponSuccess ? (
                                <>
                                  <s style={{ color: "#B06161" }}>
                                    ₹{" "}
                                    {datas?.mrp * props.count +
                                      datas?.shipping_charge}
                                  </s>{" "}
                                  <div>
                                    ₹{" "}
                                    {datas?.mrp * props.count -
                                      couponSuccess?.amount +
                                      datas?.shipping_charge}
                                  </div>{" "}
                                </>
                              ) : (
                                <div>
                                  {" "}
                                  ₹{" "}
                                  {datas?.mrp * props.count +
                                    datas?.shipping_charge}
                                </div>
                              )}
                            </h5>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end ">
                          <div className="d-flex justify-content-end ">
                            <h6 className="heading-4 product-price text-secondary">
                              (PRODUCT TOTAL ₹{" "}
                            </h6>
                            <h6 className="heading-4 product-price text-secondary">
                              {couponSuccess ? (
                                <>
                                  <s style={{ color: "#B06161" }}>
                                    ₹ {datas?.mrp * props.count}
                                  </s>{" "}
                                  <div>
                                    {" "}
                                    {datas?.mrp * props.count -
                                      couponSuccess?.amount}
                                    )
                                  </div>{" "}
                                </>
                              ) : (
                                <div> {datas?.mrp * props.count})</div>
                              )}
                            </h6>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end ">
                          <div className="d-flex justify-content-start ">
                            <h6 className="heading-4 product-price text-secondary">
                              (SHIPPING CHARGE ₹{" "}
                            </h6>
                            <h6 className="heading-4 product-price text-secondary">
                              {datas?.shipping_charge})
                            </h6>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end gap-5 mt-5">
                          <div className="d-flex justify-content-between gap-5 mt-5">
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block btn-secondary hover-up"
                              // onClick={handlePayment}
                              onClick={handleOrder}
                            >
                              Check Out
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </StyledCollapse>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <ScrollToTop />
      </main>
    </>
  );
}
export default BuyNow;
const H2 = styled.h2`
  font-weight: 500;
  padding: 20px 0;
  @media screen and (max-width: 640px) {
    font-size: 25px;
  }
`;
const CardBoxContainer = styled.div`
  display: grid;
  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
    column-gap: 20px;
  }
`;
const CartBox = styled.div`
  box-shadow: 0px 0px 14px #00000034;
  border-radius: 10px;
  margin: 20px 0;

  display: grid;
  grid-template-columns: 2fr 0.8fr 0.8fr 0.8fr;

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`;
const CartBoxTop = styled.div`
  display: grid;
  grid-template-columns: 2fr 0.8fr 0.8fr 0.8fr;
  margin-bottom: 0;
  box-shadow: unset;
  border-radius: 0;
  margin-top: 40px;
  p {
    text-align: center;
    &:first-child {
      text-align: left;
    }
  }

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const Section = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  &.one {
    justify-self: start;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    align-items: center;
    img {
      border-radius: 10px;
    }
    @media screen and (max-width: 1080px) {
      grid-template-columns: 1fr;
      place-items: center;
      width: 100%;
    }
  }
  &.two {
    text-align: center;
    span {
      font-size: 28px;
      font-weight: 600;
      color: #253d4e;
    }
    @media screen and (max-width: 1080px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-items: center;
      width: 100%;
      margin-bottom: 20px;
    }
  }
  &.three {
    @media screen and (max-width: 1080px) {
      display: none;
    }
  }
  &.four {
    h3 {
      text-align: center;
      margin: 20px 0;
    }
    span {
      font-size: 13px;
    }
  }
`;
const ImageContainer = styled.div`
  height: 200px;
  width: 200px;

  img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
  }
`;
const Link = styled.a`
  display: block;
  color: #363739;
  font-weight: 600;
  font-size: 25px;
  line-height: 1;
  text-transform: capitalize;
  &.smallLink {
    font-size: 16px;
    font-weight: 400;
  }
`;
const QtyBtn = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  input {
    border: none;
    padding: 0;
    display: inline-block;
    max-width: 30px;
    text-align: center;
    -moz-appearance: textfield; /* Firefox */
    -webkit-appearance: none; /* Safari/Chrome */
    appearance: none;
    font-size: 20px;
    font-weight: 600;
    color: #253d4e;
  }
  svg {
    font-size: 20px;
    color: #253d4e;
  }

  &.mobile {
    display: none;
    @media screen and (max-width: 1080px) {
      display: flex;
    }
  }
`;
const Btn = styled.div`
  border: 1px solid #6b6b6b;
  display: inline-block;
  padding: 3px;
  border-radius: 50px;
`;
const SubTotalContainer = styled.div`
  display: grid;
  width: 100%;
  justify-items: end;
  margin: 30px 0;
`;
const SubTotal = styled.div`
  box-shadow: 0px 0px 14px #00000034;
  h3 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  button {
    margin-top: 20px;
  }

  display: inline-block;
  padding: 20px;
  border-radius: 10px;
`;
const Text = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr;
  &.total {
    p {
      font-size: 23px;
      font-weight: 600;
    }
  }
`;
const BackBtn = styled.div`
  margin: 0;
  background-color: #094c27;
  padding: 10px 25px;
  font-weight: 600;
  color: #fff;
  border-radius: 6px;
  font-size: 17px;
`;
const StyledCollapse = styled(Collapse)`
  input,
  select {
    border: 1px solid #6b6b6b;
    border-radius: 10px;
    padding: 10px 0;
    margin-bottom: 20px;
    font-family: "Montserrat";
    padding-left: 20px;
    &::placeholder {
      font-family: "Montserrat";
    }
  }
`;
