import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { backend, producturl } from "../../../../path";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../layout/header/loader";
import { ToastContainer, toast } from "react-toastify";
import LoaderMain from "../../../layout/header/loader_sub";

function SearchList(props) {
  const navigate = useNavigate();
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const [imgLoading, setImgLoading] = useState();

  const handleImageLoad = () => {
    setImgLoading(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;

  const handleAddToWishList = async (id) => {
    if (authenticated === null) {
      props?.SearchFetch();
      navigate(`/login`);
      return;
    }

    const formData = {
      status: 0,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtowishlistuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setWishChange(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleFilter = () => {
    setIsFilter((prevIsFilter) => !prevIsFilter);
  };
  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    props.setSearch("");
  };
  const [priceRange, setPriceRange] = useState([0, 10000]);

  const handlePriceChange = (value) => {
    setPriceRange(value);
  };

  const handleAddCart = async (id) => {
    if (authenticated === null) {
      props?.SearchFetch();
      navigate(`/login`);
      return;
    }

    const formData = {
      status: 1,
      quantity: 1,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtocartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setCatChange(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ToastContainer />
      <main className="main" id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <Link to="/" title="Home" onClick={() => props.setSearch("")}>
                  {" "}
                  Home{" "}
                </Link>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemProp="item">{props.search}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-30">
          <div className="row">
            <div className="col-lg-12 m-auto mt-4">
              {/* <Link className="shop-filter-toggle mb-0" onClick={handleFilter}>
                <span className="fi-rs-filter mr-5"></span>
                <span className="title">Filters</span>
                <i className="fi-rs-angle-small-up angle-up"></i>
                <i className="fi-rs-angle-small-down angle-down"></i>
              </Link> */}
              {/* {isFilter === true && (
                <div>
                  <input
                    type="hidden"
                    name="layout"
                    value="product-full-width"
                  />
                  <div className="shop-product-filter-header my-3 page_speed_1723541923">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 mb-lg-0 mb-md-5 mb-sm-5 widget-filter-item product-categories-filter-widget">
                        <h5
                          className="mb-20 widget__title"
                          data-title="Category"
                        >
                          By categories
                        </h5>
                        <div className="custome-checkbox ps-custom-scrollbar">
                          <ul className="ps-list--categories">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="categories"
                                value="1"
                                checked
                                id="category-filter-1"
                              />
                              <label
                                className="form-check-label"
                                for="category-filter-1"
                              >
                                {data[0]?.category_name}
                              </label>
                            </div>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 mb-lg-0 mb-md-5 mb-sm-5 widget-filter-item">
                        <h5 className="mb-20 widget__title" data-title="Tag">
                          By tags
                        </h5>
                        <div className="custome-checkbox ps-custom-scrollbar">
                          <input
                            className="form-check-input"
                            name="tags"
                            type="checkbox"
                            id="tag-filter-4"
                            value="4"
                          />
                          <label
                            className="form-check-label"
                            for="tag-filter-4"
                          >
                            <span className="d-inline-block">Clothes</span>
                            <span className="d-inline-block">(3)</span>
                          </label>
                          <br></br>
                          <input
                            className="form-check-input"
                            name="tags"
                            type="checkbox"
                            id="tag-filter-5"
                            value="5"
                          />
                          <label
                            className="form-check-label"
                            for="tag-filter-5"
                          >
                            <span className="d-inline-block">Hand bag</span>
                            <span className="d-inline-block">(2)</span>
                          </label>
                          <br></br>
                          <input
                            className="form-check-input"
                            name="tags"
                            type="checkbox"
                            id="tag-filter-1"
                            value="1"
                          />
                          <label
                            className="form-check-label"
                            for="tag-filter-1"
                          >
                            <span className="d-inline-block">Wallet</span>
                            <span className="d-inline-block">(1)</span>
                          </label>
                          <br></br>
                          <input
                            className="form-check-input"
                            name="tags"
                            type="checkbox"
                            id="tag-filter-3"
                            value="3"
                          />
                          <label
                            className="form-check-label"
                            for="tag-filter-3"
                          >
                            <span className="d-inline-block">Shoes</span>
                            <span className="d-inline-block">(1)</span>
                          </label>
                          <br></br>
                        </div>
                      </div>
                      <div
                        className="col-lg-3 col-md-4 mb-lg-0 mb-md-5 mb-sm-5 widget-filter-item"
                        data-type="price"
                      >
                        <h5 className="mb-20 widget__title" data-title="Price">
                          By Price
                        </h5>
                        <div className="price-filter range">
                          <div className="price-filter-inner">
                            <Slider
                              range
                              min={0}
                              max={10000}
                              value={priceRange}
                              onChange={handlePriceChange}
                            />
                            <input
                              type="hidden"
                              className="min_price min-range"
                              name="min_price"
                              value={priceRange[0]}
                              data-label="Min price"
                            />
                            <input
                              type="hidden"
                              className="min_price max-range"
                              name="max_price"
                              value={priceRange[1]}
                              data-label="Max price"
                            />
                            <div className="price_slider_amount">
                              <div className="label-input">
                                <span className="d-inline-block">Range:</span>
                                <span className="from d-inline-block">
                                  {priceRange[0]}
                                </span>
                                <span className="to d-inline-block">
                                  {priceRange[1]}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="widget">
                    <button
                      type="submit"
                      className="btn btn-sm btn-default"
                      onClick={handleFilter}
                    >
                      <i className="fi-rs-filter mr-5 ml-0"></i> Filter
                    </button>
                    <Link className="clear_filter dib clear_all_filter mx-4 btn btn-danger btn-sm">
                      <i className="fi-rs-refresh mr-5 ml-0"></i> Clear All
                      Filters
                    </Link>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div className="mt-4">
            <div className="products-listing position-relative">
              <div className="list-content-loading">
                <div className="half-circle-spinner">
                  <div className="circle circle-1"></div>
                  <div className="circle circle-2"></div>
                </div>
              </div>
              <div className="shop-product-filter">
                <div className="total-product">
                  <p>
                    We found{" "}
                    <strong className="text-brand">
                      {props?.data?.data?.length}
                    </strong>{" "}
                    items for you!
                  </p>
                </div>
              </div>
              <input type="hidden" name="page" data-value="1" />
              <input type="hidden" value="" />
              <input type="hidden" name="num" value="" />
              <input type="hidden" name="q" value="" />

              <div className="row product-grid">
                {loading && (
                  <div>
                    <LoaderMain />
                  </div>
                )}
                {!loading &&
                  props?.data?.data?.map((item, i) => (
                    <div
                      key={i}
                      className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 col-sm-6"
                    >
                      <div
                        className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                        data-wow-delay="0.1s"
                        style={{
                          boxShadow:
                            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                        }}
                      >
                        <div className="product-img-action-wrap">
                          <div className="product-img product-img-zoom">
                            <Link
                              to={`/product/${item.product_slug}`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleProductRoute(item.product_slug);
                              }}
                            >
                              {imgLoading && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                >
                                  <LoaderMain />
                                </div>
                              )}
                              <img
                                className="default-img"
                                style={{
                                  height: "320px",
                                  width: "100%",
                                  objectFit: "contain",
                                  objectPosition: "center",
                                  display: imgLoading ? "none" : "block",
                                }}
                                src={producturl + item.thumbnail}
                                alt={item.product_name}
                                onLoad={handleImageLoad}
                              />
                              <img
                                className="hover-img"
                                style={{
                                  height: "320px",
                                  width: "100%",
                                  objectFit: "contain",
                                  objectPosition: "center",
                                  display: imgLoading ? "none" : "block",
                                }}
                                src={
                                  item.thumbnail2
                                    ? producturl + item.thumbnail2
                                    : producturl + item.thumbnail
                                }
                                alt={item.product_name}
                                onLoad={handleImageLoad}
                              />
                            </Link>
                          </div>
                          <div className="product-action-1">
                            <Link
                              to={`/product/${item.product_slug}`}
                              aria-label="Quick View"
                              onClick={(e) => {
                                e.preventDefault();
                                handleProductRoute(item.product_slug);
                              }}
                              className="action-btn hover-up js-quick-view-button"
                            >
                              <i className="fi-rs-eye"></i>
                            </Link>
                            <Link
                              aria-label="Add To Wishlist"
                              onClick={() =>
                                handleAddToWishList(item.product_id)
                              }
                              className="action-btn hover-up js-add-to-wishlist-button"
                            >
                              <i className="fi-rs-heart"></i>
                            </Link>
                          </div>
                          <div className="product-badges product-badges-position product-badges-mrg"></div>
                        </div>
                        <div className="product-content-wrap">
                          <div className="product-category">
                            <Link
                              to={`/product/${item.product_slug}`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleProductRoute(item.product_slug);
                              }}
                            >
                              {item?.category_name}
                            </Link>
                          </div>
                          <h2 className="text-truncate">
                            <Link
                              to={`/product/${item.product_slug}`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleProductRoute(item.product_slug);
                              }}
                              title={item.product_name}
                            >
                              {item?.product_name}
                            </Link>
                          </h2>
                          <div className="product-rate-cover">
                            <div className="product-rate d-inline-block">
                              <div className="product-rating page_speed_1540370144"></div>
                            </div>
                            <span className="font-small ml-5 text-muted">
                              ({item?.rating ?? "Not Yet Rated"})
                            </span>
                          </div>
                          <div className="text-truncate">
                            <span className="font-small text-muted">
                              Sold By{" "}
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleProductRoute(item.product_slug);
                                }}
                              >
                                {item?.vendor_name ?? "KeralaSpecial"}
                              </Link>
                            </span>
                          </div>
                          <div className="product-card-bottom">
                            <div className="product-price">
                              <span>₹ {item.mrp}</span>
                            </div>
                            <div className="add-cart">
                              <Link
                                aria-label="Add To Cart"
                                className="action-btn add-to-cart-button add"
                                data-id="11"
                                onClick={() => handleAddCart(item.product_id)}
                              >
                                <i className="fi-rs-shopping-cart mr-5"></i>
                                <span className="d-inline-block">Add</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {/* Pagination controls */}
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-fill-out btn-secondary btn-block hover-up"
                    onClick={() =>
                      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                    }
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span style={{ margin: "0 10px" }}>Page {currentPage}</span>
                  <button
                    type="button"
                    className="btn btn-fill-out btn-block hover-up"
                    onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                    disabled={data.length < productsPerPage} // Assuming data.length indicates total number of products fetched
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default SearchList;
